import React, { useState, useEffect } from 'react' 
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment-timezone'
import KuroLink from '../components/common/KuroLink'
import note from '../assets/img/button.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

moment.tz.setDefault('Asia/Kolkata')

const Estimates = ({ user: { token }, admin: { accesslevels } }) => {

    const [estimatesData, setestimatesData] = useState(null)
    const [fltestdata, setfltestdata] = useState(null)
    const [searchtxt, setsearchtxt] = useState("")
    const [limit, setlimit] = useState(30)
    const history = useHistory();
    const access = accesslevels.find(item => item.estimates !== "NA");

    useEffect(() => {
        if (access.length<=0) {
            history.push("/unauthorized")
            return;
        }
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?limit=' + limit, config).then(res => {
            setestimatesData(res.data)
            setfltestdata(res.data)
            if (res.data.length < limit) {
                setlimit(0)
            }
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [limit])

    useEffect(() => {
        if (searchtxt === "") {
            setfltestdata(estimatesData)
        } else {
            setlimit(0)
            setfltestdata(estimatesData.filter((estimate) => estimate.estimate_no.toLowerCase().includes(searchtxt) || estimate.billadd.name.toLowerCase().includes(searchtxt) || ((estimate.user.phone === undefined) ? "" : estimate.user.phone.includes(searchtxt))))
        }
    }, [searchtxt, estimatesData])

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 30)
        }
    }

    const duplicateHandler = (estimate_no, version, entity) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.post(process.env.REACT_APP_KC_API_URL + `kuroadmin/estimates?estimate_no=${estimate_no}&version=${version}&entity=${entity}&duplicate=true&limit=30`, null, config).then(res => {
            setestimatesData(res.data)
            setfltestdata(res.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }

    var fileDownload = require('js-file-download')

    const downloadestimate = (estimate_no, version) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no + "&version=" + version + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, estimate_no + '.pdf')
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const LoadingEstimates = () => {
        return (
            <div className='loading-wrapper'>
                <div className='loading-animation'></div>
            </div>
        )
    }

    return (
        <div className='txt-light-grey'>
            <h2>Estimates</h2>
            <div className='notes'>
                <div className="instructions">
                    <p>Instructions</p>
                    <div className='instr'>
                        <img src={note} alt='instruction' className='note_img' />:
                        <span className="list">
                            <b>View and Edit:</b> Click the estimate no. to view details and edit estimates. <br />
                            <b>Search: </b>Enter the number or character of the estimate to search.
                        </span>
                    </div>
                </div>
                <div className="note">
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            </div>
            <div className='search_container'>
                <input type='text' name='search' onChange={(e) => setsearchtxt(e.target.value.toLowerCase())} placeholder="Search......" />
            </div>
            {(accesslevels.find(item => item.estimates === "write" || item.estimates === "edit")) && <ul className='btns'>
                <li><KuroLink to="/create-estimate"><button>Create Estimate</button></KuroLink></li>
            </ul>}

            {fltestdata !== null ? (
                <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th>Estimate No</th>
                            <th>Estimate Date</th>
                            <th>Customer Name</th>
                            <th>Mobile Number</th>
                            <th>Estimate Total</th>
                            <th>Order Confirmation</th>
                            <th>Duplicate Estimate</th>
                            <th>Download Estimate</th>
                        </tr>
                        {fltestdata.map((estimate, i) =>
                            <tr key={i}>
                                <td>
                                    {estimate.type && estimate.type === "preset" ? (
                                        estimate.version === 1 ? (
                                            <KuroLink to={'/estimates/' + estimate.estimate_no}>{estimate.estimate_no}</KuroLink>
                                        ) : (
                                            <KuroLink to={'/estimates/' + estimate.estimate_no + '/' + estimate.version}>{estimate.estimate_no + '/' + estimate.version}</KuroLink>
                                        )
                                    ) : (
                                        estimate.version ? (
                                            estimate.version === 1 ? (
                                                <KuroLink to={'/nps/estimates/' + estimate.estimate_no + '/' + estimate.version + '/' + estimate.entity}>{estimate.estimate_no}</KuroLink>
                                            ) : (
                                                <KuroLink to={'/nps/estimates/' + estimate.estimate_no + '/' + estimate.version+ '/' + estimate.entity}>{estimate.estimate_no + '/' + estimate.version}</KuroLink>
                                            )
                                        ) : (
                                            <KuroLink to={'/nps/estimates/' + estimate.estimate_no+ '/' + estimate.entity}>{estimate.estimate_no}</KuroLink>
                                        )
                                    )}
                                </td>
                                <td>{moment(estimate.estimate_date).format('DD-MM-YYYY')}</td>
                                <td>{estimate.user.name}{estimate.billadd.company === "" ? "" : " [" + estimate.billadd.company + "]"}</td>
                                <td>{estimate.user.phone}</td>
                                <td>{estimate.totalprice}</td>
                                {(accesslevels.find(item => item.offline_orders !== "NA" && item.entity == estimate.entity)) ? (
                                    <>{estimate.order_confirmed === true ? (
                                    <td className='txt-center' style={{ background: '#30246d' }}><b>Order Confirmed</b></td>
                                ) : (
                                    <td><KuroLink to={'estimate-order/' + estimate.estimate_no + '/' + estimate.version}><button>Confirm Order</button></KuroLink></td>
                                )}

                                </>):(<td>You Don`t Have Access</td>)
                                }
                                {(accesslevels.find(item => item.estimates === "edit" && item.entity == estimate.entity)) ? (<td><button onClick={() => duplicateHandler(estimate.estimate_no, estimate.version, estimate.entity)}>Duplicate Estimate</button></td>):(<td>You Don`t Have Access</td>)}



                                <td><button onClick={() => downloadestimate(estimate.estimate_no, estimate.version)}>Download Estimate</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            ) : <LoadingEstimates />
            }
            {limit !== 0 && searchtxt === "" && fltestdata !== null &&
                <ul className='btns'>
                    <li><button className='btn' onClick={() => limitHandler(false)}>Show More</button></li>
                    <li><button className='btn' onClick={() => limitHandler(true)}>Show All</button></li>
                </ul>
            }
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(Estimates)