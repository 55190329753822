import axios from "axios";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from 'react-select';
import '../styles/createorder.css';

const Updateorder = ({ user: { token }, admin: { accesslevels, KcProdData } }) => {
    const [orderData, setOrderData] = useState(null);
    const [EditFlag, setEditFlag] = useState(false);
    const { orderid } = useParams();
    const [packagesData, setPackagesData] = useState(null);
    const [invData, setInvData] = useState(null)
    const [visible, setVisible] = useState(false);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
    };


    useEffect(() => {
        axios(process.env.REACT_APP_KC_API_URL + 'rebellion/reborders?orderid=' + orderid, config).then(
            (response) => {
                setOrderData(response.data[0]);
            }
        ).catch((error) => {
            console.error(error);
        });
        axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/presets', config).then(
            response => {
                setPackagesData(response.data);
                }).catch(error => {    

            });

    }, [token, orderid]);
    const renderData = orderData ? [orderData.service, orderData.food] : [token]
    useEffect(() => {
        if (orderData) {
            const orderItemsTotal = orderData.service.reduce((sum, item) => sum + item.price, 0);
            const foodItemsTotal = orderData.food.reduce((sum, item) => sum + item.price, 0);
            const total = orderItemsTotal + foodItemsTotal;
            setOrderData(prevState => ({
                ...prevState,
                totalprice: total
            }));
        }
    }, renderData);

    useEffect(() => {
        if (orderData) {
            axios.get(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?entity=' + orderData.entity, config)
                .then(response => {
                    setInvData(response.data);
                })
                .catch(error => {
                    console.error("Error fetching inventory data:", error);
                });
        }
    }, [orderData?.entity]); // Fixing dependency array to avoid infinite loop


    const handleEdit = () => {
        setEditFlag(true);
    };

    const handleCancel = () => {
        setEditFlag(false);
    };

    const handleSubmit = (out = null) => {
        let url = `rebellion/reborders?${out ? "out=out&" : ""}orderid=${orderid}`;
        axios.post(`${process.env.REACT_APP_KC_API_URL}/${url}`, orderData, config)
            .then(response => {
                setEditFlag(false);
                setOrderData(response.data[0]);
            })
            .catch(error => {
                console.error(error);
                alert('Failed to update order.');
            });
    };
    

    const handleChange = (e, index, type) => {
        const { name, value } = e.target;
        const newOrderData = { ...orderData };

        if (type === 'service') {
            newOrderData.service[index][name] = value;
        } else if (type === 'food') {
            newOrderData.food[index][name] = value;
        } else {
            newOrderData[name] = value;
        }

        setOrderData(newOrderData);
    };

    const addingFoodItem = (index, type) => {
        const newOrderData = { ...orderData };
        const newItem = type === 'service' ? { type: '', presetid: '', title: '', quantity: 1, price: 0 } : { name: '', quantity: 1, price: 0 };

        if (type === 'service') {
            newOrderData.service.splice(index + 1, 0, newItem);
        } else if (type === 'food') {
            newOrderData.food.splice(index + 1, 0, newItem);
        }

        setOrderData(newOrderData);
    };

    const removingFoodItem = (index, type) => {
        const newOrderData = { ...orderData };

        if (type === 'service') {
            newOrderData.service.splice(index, 1);
        } else if (type === 'food') {
            newOrderData.food.splice(index, 1);
        }

        setOrderData(newOrderData);
    };

    const updatefoodtype = (index, e) =>{
        const { name, value } = e.target;   
        const tempData = {...orderData}
        tempData.food[index][name] = value
        setOrderData(tempData)
    }

    const foodOptions = (index) => {
        if (!KcProdData) return [];
    
        return invData.filter(item => orderData.food[index].type === item.collection && orderData.food[index].foodtype === item.type)
            .map(item => (
                {
                
                value: item.productid,
                label: KcProdData[orderData.food?.[index]?.type]?.find(prod => prod.productid === item.productid)?.title || 'Unknown'
            }));
    };

    const updateOrderitems = (e, index) => {
        const { name, value } = e.target;
        setOrderData(prevState => {
            const newOrderItems = [...prevState.service];
            newOrderItems[index][name] = name ==='quantity' ? (isNaN(value) ? 1 : Math.abs(value)) : value;
            let temppackageData = null
            let temptype = ""
            newOrderItems.map((item, i) => {
                if(item.type !== "" && item.presetid !== ""){
                    let tempquantity = item.quantity === "" || item.quantity === 0 ? 1 : Math.abs(item.quantity)
                    tempquantity = isNaN(tempquantity) ? 1 : tempquantity
                    let package_list = packagesData.find(pac => pac.type===item.type)?.list
                    let obj = package_list.find(pre => pre.presetid === (!temppackageData || temptype !== item.type ? item.presetid : temppackageData))
                    let tempprice = 0
                    if(obj){
                        if(!temppackageData && obj.presetid === item.presetid){
                            tempprice += obj.price * Number(tempquantity)
                            temppackageData = item.presetid
                            temptype = item.type
                        }
                        else if(item.type === temptype){
                            let noofhours = packagesData.find(pack => pack.type === item.type)?.list.find(pre => pre.presetid === item.presetid)?.duration
                            if(noofhours){
                            tempprice += obj.add_on * Number(noofhours) * Number(tempquantity)
                        }
                        }
                        else{
                            tempprice += obj.price * Number(tempquantity)
                            temppackageData = item.presetid
                            temptype = item.type
                        }
                        newOrderItems[i].validity = obj.validity
                    }
                        newOrderItems[i].price = tempprice
                    }
                })
            return {
                ...prevState,
                service: newOrderItems
                };
            })
    }

    const updateFoodItems = (selectedOption, index) => {
        setOrderData(prevState => {
            const newFoodItems = [...prevState.food];
            let tempquantity = newFoodItems[index].quantity === "" || newFoodItems[index].quantity === 0 ? 1 : (isNaN(newFoodItems[index].quantity) ? 1 : Math.abs(newFoodItems[index].quantity))
            tempquantity = isNaN(tempquantity) ? 1 : tempquantity
            newFoodItems[index].name = selectedOption.value;
            let price = invData.find(item => item.productid === selectedOption.value)
            newFoodItems[index].price = (Number(price.totalprice)/ Number(price.quantity)) * Number(tempquantity);
            return {
                ...prevState,
                food: newFoodItems
            };
        });
    };

    const updateFoodprice = (e, index) => {
        setOrderData(prevState => {
            const newFoodItems = [...prevState.food];
            const inputValue = e.target.value;
            const tempquantity = inputValue === "" || Number(inputValue) === 0 ? 1 : (isNaN(inputValue) ? 1 : inputValue)
            const parsedQuantity =  tempquantity;
            if(inputValue!=='food' && inputValue!=='beverage' ){
                newFoodItems[index].quantity = isNaN(e.target.value) ? 1 : e.target.value
                let price = invData.find(item => item.productid === newFoodItems[index].name)
                newFoodItems[index].price = Number(parsedQuantity) * (Number(price.totalprice) / Number(price.quantity))
            }
            else{
                newFoodItems[index].type = inputValue
            }
            return {
                ...prevState,
                food: newFoodItems
            };
        });
    };

    const valueoffood = (o, index) =>{
        if(KcProdData){
            const temp = KcProdData[orderData.food[index]?.type]?.find(item => item.productid === o)
            if(temp){
                return {
                    label: temp.title,
                    value: temp.productid
                }
            }
        }
    }

    const closeHandlerfunc = () => {
        setVisible(true)
    }

    const alertfunc = ( status=false ) =>{
        if(status === true){
            handleSubmit(true)
            setVisible(false)
        }
        else{
            setVisible(false);
        }
    }


    return (
        <div className="orders txt-light-grey">
            <h1>Offline Order</h1>
            {
                EditFlag && orderData ? (
                    <>
                    <div>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{orderData.orderid}</td>
                                    <td><input type="text" name="name" value={orderData.user.name} onChange={(e) => handleChange(e)} /></td>
                                    <td><input type="text" name="phone" value={orderData.user.phone} onChange={(e) => handleChange(e)} /></td>
                                    <td>{orderData.totalprice}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h2>Order Items</h2>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Package</th>
                                    <th>Validity</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Add</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.service.map((comp, index) =>
                                    <tr key={index}>
                                        <td>
                                            <select value={comp.type} onChange={e => updateOrderitems(e, index)} name="type">
                                                <option value="">Select Type</option>
                                                {packagesData && packagesData.map(
                                                    (packageItem, index) => (
                                                        <option key={index} value={packageItem.type}>{packageItem.title}</option>
                                                    )
                                                )
                                                }
                                            </select>
                                        </td>
                                        <td>{
                                            packagesData && packagesData.map((pac, i) => {
                                                if (pac.type === comp.type) {
                                                    return (
                                                        <select
                                                            key={i}
                                                            value={comp.presetid}
                                                            onChange={e => updateOrderitems(e, index)}
                                                            name='presetid'
                                                        >
                                                            <option value="">Select Package</option>
                                                            {
                                                                  pac.list.map((item, index) => (
                                                                    <option key={index} value={item.presetid}>
                                                                        {item.title} 
                                                                    </option>
                                                                ) )}
                                                        </select>
                                                    );
                                                }
                                                return null;
                                            })
                                        }</td>
                                        <td>
                                            {comp.validity}
                                        </td>
                                        <td><input value={comp.quantity} onChange={e => updateOrderitems(e, index)} name='quantity' /></td>
                                        <td>{comp.price}</td>
                                        <td><span className="prod-span" onClick={() => addingFoodItem(index, 'service')}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                        <td><span className="prod-span" onClick={() => removingFoodItem(index, 'service')}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <h2>Food Items <span className="prod-span" onClick={() => addingFoodItem(0, "food")}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></h2>
                        {orderData.food.length > 0 && (
                        <>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Food/Beverage</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Validity</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>ADD</th>
                                    <th>REMOVE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.food.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={item.type} onChange={(e) => updateFoodprice(e, index)} name='type'>
                                                <option value="">Select Type</option>
                                                <option value="food">Food</option>
                                                <option value="beverage">Beverage</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={item.foodtype} onChange={(e) => updatefoodtype(index, e)} name='foodtype'>
                                                <option value="">Select Food Type</option>
                                            {
                                                    item.type === "food" && (
                                                        <>
                                                            <option value="French Fries">French Fries</option>
                                                            <option value="Samosa">Samosa</option>
                                                            <option value="Cheesy Shots">Cheesy Shots</option>
                                                            <option value="Potato Shots">Potato Shots</option>
                                                            <option value="Onion Rings">Onion Rings</option>
                                                            <option value="Paneer Roll">Paneer Roll</option>
                                                            <option value="Noodles">Noodles</option>
                                                            <option value="Chips">Chips</option>
                                                            <option value="Potato Wedges">Potato Wedges</option>
                                                            <option value="Nachos">Nachos</option>
                                                            <option value="Chocolates">Chocolates</option></>)}
                                                {
                                                    item.type === "beverage" && (<>
                                                        <option value="Small (Loose)">Small (Loose)</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="Large">Large</option>
                                                        <option value="Tin">Tin</option>
                                                            </>)

                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <Select 
                                                classNamePrefix="kuro-search-select" 
                                                options={foodOptions(index)} 
                                                value={valueoffood(item.name, index)} 
                                                onChange={(selectedOption) => updateFoodItems(selectedOption, index)} 
                                                placeholder="Select Food Item" 
                                                className="react-select-container" 
                                            />
                                        </td>
                                        <td>
                                            {item.validity}
                                        </td>
                                        <td>
                                            <input
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={(e) => updateFoodprice(e, index)}
                                            />
                                        </td>
                                        <td>{item.price}</td>
                                        <td><span className="prod-span" onClick={() => addingFoodItem(index, 'food')}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                        <td><span className="prod-span" onClick={() => removingFoodItem(index, 'food')}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table></>)}

                        <ul className='btns'>
                            <li><button onClick={() => handleSubmit()}>submit</button></li>
                            <li><button onClick={() =>  closeHandlerfunc()}>Close</button></li>
                            <li><button onClick={handleCancel}>Cancel</button></li>
                        </ul>
                    </div>
                    <div className="alert" style={{ display: visible ? "block" : "none" }}>
                        <h3>Are you sure you want to close the order?</h3>
                        <ul className='card_btns'>
                            <li><button onClick={() => alertfunc()}>No</button></li>
                            <li><button onClick={() => alertfunc(true)}>Yes</button></li>
                        </ul>
                    </div>
                    </>
                ) : (orderData &&
                    <>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{orderData.orderid}</td>
                                    <td>{orderData.user.name}</td>
                                    <td>{orderData.user.phone}</td>
                                    <td>{orderData.totalprice}</td>
                                </tr>
                            </tbody>
                        </table>

                        <h2>Order Items</h2>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Package</th>
                                    <th>Validity</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.service.map((comp, index) =>
                                    <tr key={index}>
                                        <td>{packagesData && packagesData.find( pack => pack.type === comp.type).title}</td>
                                        <td>{comp.validity}</td>
                                        <td>{comp.presetid}</td>
                                        <td>{comp.quantity}</td>
                                        <td>{comp.price}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <h2>Food Items</h2>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Food/Beverage</th>
                                    <th>type</th>
                                    <th>Food Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.food.map((foodData, index) =>
                                    <tr key={index}>
                                        <td>{foodData.type}</td>
                                        <td>{foodData.foodtype}</td>
                                        <td>{KcProdData && KcProdData[orderData.food?.[index]?.type].find(i => i.productid===foodData.name).title}</td>
                                        <td>{foodData.quantity}</td>
                                        <td>{foodData.price}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <ul className='btns'>
                            <li><button onClick={handleEdit}>Edit</button></li>
                        </ul>
                    </>
                )
            }
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
});

export default connect(mapStateToProps)(Updateorder);