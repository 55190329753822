import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { connect } from "react-redux"
import '../styles/business-group.css'

const Businessgroup = ({ user: { token } }) => {
    const [ordersData, setOrdersData] = useState([])
    const [loading, setLoading] = useState(true)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isCreateMode, setIsCreateMode] = useState(false) // For creating new business group
    const [currentBusinessGroup, setCurrentBusinessGroup] = useState({
        bg_code: '',
        bg_name: '',
        db_name: '',
        entities: [],
        is_active: true,
    })
    const history = useHistory()

    // Fetch data on component mount
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                },
            }
            const res = await axios.get(`${process.env.REACT_APP_KC_API_URL}bggroup`, config)
            setOrdersData(res.data)
            setLoading(false)
        } catch (err) {
            if (err.response?.status === 401) {
                history.push("/unauthorized")
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [token, history])

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setCurrentBusinessGroup({
            ...currentBusinessGroup,
            [name]: value,
        })
    }

    // Handle form submission (create or update)
    const handleFormSubmit = async (e) => {
        e.preventDefault()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }

        const url = isEditMode
        ? `${process.env.REACT_APP_KC_API_URL}bggroup?bgcode=${currentBusinessGroup.bg_code}`
        : `${process.env.REACT_APP_KC_API_URL}bggroup`

        try {
            await axios.post(url, currentBusinessGroup, config)
            setIsEditMode(false) // Exit edit mode after submission
            setIsCreateMode(false) // Exit create mode after submission
            setCurrentBusinessGroup({ bg_code: '', bg_name: '', db_name: '', entities: [], is_active: true })
            // Re-fetch the data after submit to show updated list
            fetchData()
        } catch (err) {
            console.error("Error:", err)
        }
    }

    // Handle edit mode
    const handleEdit = (bg) => {
        setIsEditMode(true)
        setIsCreateMode(false) // Exit create mode
        setCurrentBusinessGroup(bg)
    }

    // Handle create new mode
    const handleCreateNew = () => {
        setIsCreateMode(true)
        setIsEditMode(false) // Exit edit mode
        setCurrentBusinessGroup({ bg_code: '', bg_name: '', db_name: '', entities: [], is_active: true })
    }

    // Handle cancel edit or create
    const handleCancel = () => {
        setIsEditMode(false)
        setIsCreateMode(false)
        setCurrentBusinessGroup({ bg_code: '', bg_name: '', db_name: '', entities: [], is_active: true })
    }

    const addRemoveEntity = (index, action) => {
        if (action === "add") {
            const newEntities = [...currentBusinessGroup.entities, { name: "", title: "" }]
            setCurrentBusinessGroup({ ...currentBusinessGroup, entities: newEntities })
        } else if (action === "remove") {
            const newEntities = currentBusinessGroup.entities.filter((_, i) => i !== index)
            setCurrentBusinessGroup({ ...currentBusinessGroup, entities: newEntities })
        }
    }

    const handleEntityChange = (index, field, value) => {
        const updatedEntities = [...currentBusinessGroup.entities]
        updatedEntities[index][field] = value
        setCurrentBusinessGroup({ ...currentBusinessGroup, entities: updatedEntities })
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <div className="business-group-container">
            <h2>Business Groups</h2>
            {!isEditMode && !isCreateMode && (
            <>
            <button onClick={handleCreateNew}>Create New Business Group</button>
            <table className="table border">
                <thead>
                    <tr>
                        <th>Group Code</th>
                        <th>Group Name</th>
                        <th>Database Name</th>
                        <th>Entities</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {ordersData.length > 0 ? (
                    ordersData.map((group) => (
                    <tr key={group.bg_code}>
                        <td>{group.bg_code}</td>
                        <td>{group.bg_name}</td>
                        <td>{group.db_name}</td>
                        <td>
                        {group.entities.map((entity, i) => (
                            <div key={i}>{entity.title}</div>
                        ))}
                        </td>
                        <td>{group.is_active ? 'Active' : 'Inactive'}</td>
                        <td>
                            <button onClick={() => handleEdit(group)}>Edit</button>
                        </td>
                    </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="6">No business groups found.</td>
                    </tr>
                )}
                </tbody>
            </table>
            </>
        )}
        {(isEditMode || isCreateMode) &&
            <>
            <h3>{isEditMode ? "Edit Business Group" : "Create New Business Group"}</h3>
            <form className="bussiness-group-form" onSubmit={handleFormSubmit}>
                <table className="border even_odd table_mob" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>Group Code</th>
                            <td>
                                <input type="text" name="bg_code" value={currentBusinessGroup.bg_code} onChange={handleInputChange} required disabled={isEditMode}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Group Name</th>
                            <td>
                                <input type="text" name="bg_name" value={currentBusinessGroup.bg_name} onChange={handleInputChange} required/>
                            </td>
                        </tr>
                        <tr>
                            <th>Database Name</th>
                            <td>
                                <input type="text" name="db_name" value={currentBusinessGroup.db_name} onChange={handleInputChange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Active</th>
                            <td>
                                <input type="checkbox" name="is_active" checked={currentBusinessGroup.is_active} onChange={(e) => setCurrentBusinessGroup({...currentBusinessGroup, is_active: e.target.checked})}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <label>Entities</label>
                <table className="border even_odd table_mob" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Title</th>
                            <th>Add</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentBusinessGroup.entities.map((entity, i) =>
                        <tr key={i}>
                            <td>
                                <input type="text" value={entity.name} onChange={(e) => handleEntityChange(i, "name", e.target.value)}/>
                            </td>
                            <td>
                                <input type="text" value={entity.title} onChange={(e) => handleEntityChange(i, "title", e.target.value)}/>
                            </td>
                            <td>
                                <button type="button" onClick={() => addRemoveEntity(i, "add")}>Add</button>
                            </td>
                            <td>
                                <button type="button" onClick={() => addRemoveEntity(i, "remove")}>Remove</button>
                            </td>
                        </tr>
                        )}
                        <tr>
                            <td colSpan="4">
                                <button type="button" onClick={() => addRemoveEntity(null, "add")} className="add-entity-btn">Add New Entity</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="form-actions">
                    <button type="submit" className="save-btn">{isEditMode ? "Save Changes" : "Create Business Group"}</button>
                    <button type="button" onClick={handleCancel} className="cancel-btn">Cancel</button>
                </div>
            </form>
            </>
        }
        </div>
    )
}

// Map Redux state to props
const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(Businessgroup)