import { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import KuroLink from '../../components/common/KuroLink'
import '../../styles/presets.css'
import { psspecs } from '../../data/Presets'
import { useHistory } from "react-router-dom"
import Switch from 'react-switch';


const Presets = ({ user: { userDetails, token }, admin: { accesslevels, bgDetails  } }) => {

    const location = useLocation()
    const [pstype, setpstype] = useState("")
    const [presetdata, setpresetdata] = useState([])
    const [kteampresetData, setKteamPresetData] = useState(null)
    const [editPrice, seteditPrice] = useState(null)
    const [selectedentity, setSelectedentity] = useState(0)
    const [isEntitySwitchOn, setIsEntitySwitchOn] = useState(false);
    const history = useHistory();
    const access = accesslevels.find(item => item.presets !== "NA")

    useEffect(() => {
        if (!access) {
            history.push('/unauthorized')
            return;
        }
        if (location.state && location.state.pstype) {
            const presettype = location.state.pstype
            setpstype(presettype)
        }
    }, [location.state])

    useEffect(() => {
        getpresets(pstype)
        getKteampreset()
    }, [pstype])

    const disableScroll = (e) => {
        e.target.blur()
    }

    const getpresets = () => {
        if (pstype !== "" && accesslevels[selectedentity].entity === 'kurogaming') {
            const config = {}
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype, config).then(res => {
                setpresetdata(res.data)
            })
        }
    }

    const getKteampreset = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/presets', config).then(res => {
            setKteamPresetData(res.data)
        })
    }

    const pstypeHandler = (e) => {
        setpstype(e.target.value)
    }

    const handlePriceChange = (index) => {
        if (editPrice === index) {
            const config = {}

            let newpsData = presetdata[index]
            let presetid = newpsData.presetid

            for (let key of ["title", "kuro_title", "portal_title", "short_title"]) {
                newpsData[key] = newpsData[key].trim()
            }
            for (let spec in newpsData.specs) {
                let pskey = psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]
                if (pskey) {
                    if (pskey.type === "dec") {
                        newpsData.specs[spec] = parseFloat(newpsData.specs[spec])
                    } else if (pskey.type === "txt") {
                        newpsData.specs[spec] = newpsData.specs[spec].trim()
                    }
                }
            }
            axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype + '&presetid=' + presetid, newpsData, config)
                .then((res) => {
                    seteditPrice(null)
                })
        }
        else {
            seteditPrice(index)
        }
    }
    const changePrice = (e, index, key) => {
        let tempdata = [...presetdata]
        tempdata[index][key] = Number(e.target.value)
        setpresetdata(tempdata)
    }

    const handleSwitchChange = (checked) => {
        setIsEntitySwitchOn(checked);
        setSelectedentity(checked ? 1 : 0);
    };


    return (
        <div className='presets txt-light-grey'>
            <h2>Presets</h2>
            <ul className='btns'>
                {userDetails.access === "Super" ? <li><KuroLink to={'add-preset'}><button>Add Preset</button></KuroLink></li> : null}
            </ul>
            {accesslevels.filter(item => item.presets !== 'NA').length > 1 && (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[0].entity)?.title.toUpperCase()}</td>
                                <td>
                                    <Switch
                                        checked={isEntitySwitchOn}
                                        onChange={handleSwitchChange}
                                        onColor="#8cbae8"
                                        onHandleColor="#1976d2"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={13}
                                        width={35}
                                        className="react-switch"
                                        id="entity-switch"
                                    />
                                </td>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[1].entity)?.title.toUpperCase()}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
            {accesslevels[selectedentity].entity === 'kurogaming' ? (<>
            <div className='preset_head'>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <select className='inputsel' value={pstype} onChange={pstypeHandler}>
                                    <option value="">Select Type</option>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="cooler">Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fans">Fans</option>
                                    <option value="wifi">WiFi</option>
                                    <option value="os">Operating System</option>
                                    <option value="shp_fees">Shipping Fees</option>
                                    <option value="build_fees">Build Charges</option>
                                    <option value="warranty">Warranty</option>
                                    <option value="margin">KM</option>
                                </select>
                            </td>
                            <td><button onClick={getpresets}>Get Details</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {pstype && presetdata.length > 0 &&
                <div className='preset_body'>
                    <table className='border table_mob'>
                        <tbody>
                            <tr>
                                <th>Preset Id</th>
                                <th>Customer Title</th>
                                <th>Kuro Title</th>
                                <th>Stock Availability</th>
                                <th>Vendor Pricing</th>
                                <th>Price</th>
                                <th>MRP</th>
                                <th></th>
                            </tr>
                            {presetdata.map((pr, i) =>
                                pstype === "margin" ? (
                                    <tr key={i}>
                                        <td>{pr.kmar}</td>
                                    </tr>
                                ) : (
                                    <tr key={i}>
                                        <td><KuroLink to={'/presets/' + pstype + '/' + pr.presetid + '/' + accesslevels[selectedentity].entity}>{pr.presetid}</KuroLink></td>
                                        <td>{pr.title}</td>
                                        <td>{pr.kuro_title}</td>
                                        <td>{pr.status}</td>
                                        <td>
                                            {pr.vendor_price && pr.vendor_price.length > 0 ? (
                                                pr.vendor_price.map((vendor, index) => (
                                                    <span key={index}>{vendor.vendor} : {vendor.price}</span>
                                                ))
                                            ) : (
                                                "NA"
                                            )}
                                        </td>
                                        <td>{editPrice === i ? <input onWheel={disableScroll} type="number" value={pr.price} onChange={(e) => changePrice(e, i, 'price')} /> : pr.price}</td>
                                        <td>{pr.mrp}</td>
                                        <td><button onClick={() => handlePriceChange(i)}>{editPrice === i ? 'Save' : 'Edit'}</button></td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <p>&nbsp;</p>
            </>) : (
                <>
                  <div className='preset_head'>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <select className='inputsel' value={pstype} onChange={pstypeHandler}>
                                        <option value="">Select Type</option>
                                        {
                                           kteampresetData && kteampresetData.filter(teamtype => teamtype.entity === accesslevels[selectedentity].entity).map((item, kteamindex) =>{
                                                return(
                                                    <option key={kteamindex} value={item.type}>{item.type}</option>
                                                )
                                            })
                                        }
                                        
                                    </select>
                                </td>
                                <td><button onClick={getpresets}>Get Details</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='preset_body'>
                    <table className='border table_mob'>
                        <thead>
                            <tr>
                                <th>Preset Id</th>
                                <th>Title</th>
                                <th>Price</th>
                                <th>Add On</th>
                                <th>Duration</th>
                                <th>Quantity</th>
                                <th>Validity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {kteampresetData && (
                                kteampresetData.filter(teamtype => teamtype.entity === accesslevels[selectedentity].entity)?.find(presettype => presettype.type === pstype)?.list.map(item => {
                                    return (
                                        <tr key={item.presetid}>
                                            <td><KuroLink to={'/presets/' + pstype + '/' + item.presetid + '/' + accesslevels[selectedentity].entity}>{item.presetid}</KuroLink></td>
                                            <td>{item.title}</td>
                                            <td>{item.price}</td>
                                            <td>{item.add_on}</td>
                                            <td>{item.duration}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.validity}</td>
                                        </tr>
                                    )
                                }))
                            }
                        </tbody>
                    </table>
                </div>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin,
})

export default connect(mapStateToProps)(Presets)