import React, { useEffect, useState } from 'react'
import KuroLink from '../components/common/KuroLink'
import { connect } from "react-redux"
import axios from "axios"

const PcComponents = ({ user: { token } }) => {
    const [componentstotal, setComponentstotal] = useState(null)
    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };
         axios.get(`${process.env.REACT_APP_KC_API_URL}kurostaff/invCalculations`, config)
        .then(response => {
            setComponentstotal(response.data)
        })
        .catch(error => {
            console.error("Error fetching packages:", error);
        });
        }, [token])

    return (
        <div className="pc-comps mx-width txt-light-grey">
            <div className="page-title">
                <table className='border' cellPadding="0" cellSpacing="0"> { componentstotal && componentstotal.map((item, index) => 
                <thead>
                <tr key={index}><th>{item.entity} Total</th><td>{item.totalprice}</td></tr>
                </thead>
                )
                    
                    }
                </table>
                <h1>Stock Register</h1>
            </div>
            <div className='col_2'>
                <div className="pc-comp">
                    <div className="cnt">
                        <h2> Core Components</h2>
                        <ul className="no_bullets">
                            <li><KuroLink to='/stock-register/components/cpu'>Processors</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/mob'>Motherboards</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/ram'>RAMs</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/gpu'>Graphics Cards</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/psu'>Power Supplys</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/ssd'>Solid State Drives</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/hdd'>Hard Drives</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/tower'>Cases</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/cooler'>CPU Coolers</KuroLink></li>
                            <li><KuroLink to='/stock-register/components/fan'>Fans</KuroLink></li>
                        </ul>
                    </div>
                </div>
                <div className='stock'>
                    <div className="cnt">
                        <h3><KuroLink to="/stock-register/monitors">Monitors</KuroLink></h3>
                        <h3><KuroLink to="/stock-register/networking">Networking Gear</KuroLink></h3>
                        <h3><KuroLink to="/stock-register/external">External Devices</KuroLink></h3>
                    </div>
                    <lable>&nbsp;</lable>
                    <h2>Accessories</h2>
                    <ul className="no_bullets">
                        <li><KuroLink to='/stock-register/accessories/keyboard'>Keyboards</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/keyboard-accessories'>Keyboard Accessories</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/mouse'>Mouse</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/mouse-accessories'>Mouse Accessories</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/kbdmouse'>Keyboard Mouse Combo</KuroLink></li>
                        <li><KuroLink to='/stock-register/accessories/streaming-gear'>Streaming Gear</KuroLink></li>
                    </ul>
                    <h2>Food</h2>
                    <ul className="no_bullets">
                        <li><KuroLink to='/stock-register/products/food'>Food</KuroLink></li>
                        <li><KuroLink to='/stock-register/products/beverage'>Beverage</KuroLink></li>
                    </ul>
                </div>
            </div>
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
});

export default connect(mapStateToProps)(PcComponents)