import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux'
import "../styles/service.css";
import KuroLink from '../components/common/KuroLink'
import note from '../assets/img/button.png';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Service = ({ user: { token, userDetails }, admin: { accesslevels, bgDetails } }) => {

    const [servicerequest, setServiceRequest] = useState({
        name: "",
        phone: "",
        type: "",
        entity: accesslevels.find(item => item.service_request === "edit" || item.service_request === "write")?.entity || "",
        logs: [
            { assigned_to: "", }
        ],
        assigned_to: "",
    });
    const [servicerequestsData, setServiceRequestsData] = useState([]);
    const [createService, setCreateService] = useState(false);
    const [submitted, setsubmitted] = useState(false)
    const [limit, setlimit] = useState(30)
    const [tabIndex, setTabIndex] = useState(0)
    const [employees, setEmployees] = useState([])

    const history = useHistory();

    useEffect(() => {
        fetchData()
        fetchEmployees()
    }, [limit, createService])

    const fetchData = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        }
        axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/servicerequest?limit=' + limit, config).then(res => {
            setServiceRequestsData(res.data)
        })
    }

    const fetchEmployees = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_KC_API_URL}employeesdata`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                }
            )
            setEmployees(data)
        } catch (err) {
            console.error(err)
        }
    }

    const createServiceRequest = () => {
        setCreateService(true)
    }

    const backHandler = () => {
        setCreateService(false)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        const body = JSON.stringify(servicerequest)
        axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/servicerequest', body, config)
        .then(() => {
            if (servicerequest.servicetype === "Estimates") {
                history.push({
                    pathname: '/create-estimate',
                    state: servicerequest
                })
            } else {
                setsubmitted(true)
                setCreateService(false)
                setTimeout(() => {
                    setsubmitted(false)
                }, 1000)
            }
        })
        setServiceRequest({
            name: "",
            phone: "",
            type: "",
            entity: accesslevels.find(item => item.service_request === "edit" || item.service_request === "write")?.entity || "",
            logs: [
                { assigned_to: "", }
            ],
            assigned_to: "",
        });
        setsubmitted(true)
    }

    const handleChange = (e, subkey) => {
        e.preventDefault()
        let key = e.target.name
        let value = e.target.value
        let temp = { ...servicerequest }
        if (key === "phone") {
            value = value.replace(/\D/g, "")
        }
        if (key === "assigned_to") {
            temp[key] = value
            temp["logs"][0] = ({ ...servicerequest["logs"][0], "assigned_to": value })
        } else if (subkey) {
            temp[key][0] = ({ ...servicerequest[key][0], [subkey]: value })
        } else {
            temp[key] = value
        }
        setServiceRequest(temp)
    }

    return (
        <div className="kuro-service txt-light">
            <h2 className="txt-light-grey">Service Request Form</h2>
            <div className='notes'>
                <div className="instructions">
                    <p>Instructions</p>
                    <div className='instr'>
                        <img src={note} alt='instruction' className='note_img' />
                        <span className="list">
                            <b>Customer Name:</b> Full name of the customer requesting the service.<br />
                            <b>Customer Phone:</b> Contact number of the customer.<br />
                            <b>Service Type:</b> Select the type of service requested (e.g., Estimates, Warranty Service).<br />
                            <b>Customer Query:</b> Brief description of the customer's issue or request.<br />
                            <b>Admin Action:</b> Action taken by the admin to address the service request.<br />
                        </span>
                    </div>
                </div>
                <div>
                    <p className='txt-right'>Note: Lorem text</p><br />
                </div>
            </div>
        {submitted &&
            <div className="alert alert-success" role="alert">
            Created Service Request successfully
            <span></span>
            </div>
        }
        {createService ? (
            <div className="service">
                <div className="entity">
                    <span>Service Request For:</span>
                    <select className="type" name="entity" value={servicerequest.entity} onChange={(e) => handleChange(e)} >
                        {accesslevels.map((item, index) => item.service_request !== "NA"?(<><option value={item.entity}>{ bgDetails && bgDetails.entities.map( ent => ent.name === item.entity ? ent.title : null )}</option></>):(null))}
                    </select>
                </div>
                <table className="home border" cellSpacing="0" cellPadding="0">
                    <tbody>
                        <tr>
                            <th>Customer Name</th>
                            <td><input type="text" value={servicerequest.name} name="name" onChange={(e) => handleChange(e)} /></td>
                        </tr>
                        <tr>
                            <th>Customer Phone</th>
                            <td><input type="text" value={servicerequest.phone} name="phone" onChange={(e) => handleChange(e)} /></td>
                        </tr>
                        <tr>
                            <th>Service Type</th>
                            <td>
                                <select value={servicerequest.servicetype} name="servicetype" onChange={(e) => handleChange(e)}>
                                    <option value="">Select Service Type</option>
                                    <option value="Estimates">Estimates</option>
                                    <option value="IN-Kuro Warranty Service">IN-Kuro Warranty Service</option>
                                    <option value="Third Party Warranty Service">Third Party Warranty Service</option>
                                    <option value="Out of Warranty Service">Out of Warranty Service</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <th>Customer Query</th>
                            <td><textarea className="large" name="logs" onChange={(e) => handleChange(e, 'customer_query')} /> </td>
                        </tr>
                        <tr>
                            <th>Admin Action</th>
                            <td><textarea className="large" name="logs" onChange={(e) => handleChange(e, 'admin_action')} /> </td>
                        </tr>
                    {userDetails && userDetails.accesslevel.service_request === "edit" && (
                        <tr>
                            <th>Assigned To</th>
                            <td>
                                <select value={servicerequest.assignedto} name="assigned_to" onChange={(e) => handleChange(e)}>
                                    <option value="">Select</option>
                                {employees.map((emp) => (
                                    <option key={emp.userid} value={emp.userid}>{emp.userid__name}</option>
                                ))}
                                </select>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
                <ul className="btns">
                    <li>
                        <button onClick={backHandler}>Back</button>
                    </li>
                    <li>
                        <button id="submit" disabled={servicerequest.name === ""} onClick={submitHandler}>Submit</button>
                    </li>
                </ul>
            </div>
        ) : (
        <>
            <ul className="btns">
                <li>
                {accesslevels.find(item => item.service_request === "write" || item.service_request === "edit") && <button onClick={createServiceRequest}>Create Service Request</button>}
                </li>
            </ul>
            <div className="tabs">
              <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Pending Requests</button>
              <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Resolved Requests</button>
              <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>All Requests</button>
            </div>
            <div className="tab-panels">
                <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                {servicerequestsData.filter((sr) => sr.status !== "resolved").length > 0 ? (
                    <>
                    <table className="home border">
                        <tbody>
                            <tr>
                                <th>SR Id</th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Status</th>
                                <th>Updated Date</th>
                            </tr>
                        {servicerequestsData.filter((sr) => sr.status !== "resolved").map((sr) =>
                            <tr key={sr.srid}>
                                <td>
                                    <KuroLink to={'/servicerequest/' + sr.srid}>
                                    {sr.srid}
                                    </KuroLink>
                                </td>
                                <td>{sr.name}</td>
                                <td>{sr.phone}</td>
                                <td>{sr.status}</td>
                                <td>
                                    {`${new Date(sr.updated_date).getDate()}-${new Date(sr.updated_date).getMonth() + 1
                                    }-${new Date(sr.updated_date).getFullYear()}, ${new Date(sr.updated_date).getHours()
                                    }:${new Date(sr.updated_date).getMinutes()}:${new Date(sr.updated_date).getSeconds()
                                    }`}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <ul className='btns'>
                        <li><button className='btn' onClick={() => setlimit(limit + 30)}>Show More</button></li>
                        <li><button className='btn' onClick={() => setlimit(30)}>Show Less</button></li>
                    </ul>
                    </>
                ) : (
                    <div className="no-data">No Pending Requests</div>
                )}
                </div>
                <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                {servicerequestsData.filter((sr) => sr.status === "resolved").length > 0 ? (
                    <>
                    <table className="home border">
                        <tbody>
                            <tr>
                                <th>SR Id</th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Status</th>
                                <th>Updated Date</th>
                            </tr>
                        {servicerequestsData.filter((sr) => sr.status === "resolved").map((sr) =>
                            <tr key={sr.srid}>
                                <td>
                                    <KuroLink to={'/servicerequest/' + sr.srid}>{sr.srid}</KuroLink>
                                </td>
                                <td>{sr.name}</td>
                                <td>{sr.phone}</td>
                                <td>{sr.status}</td>
                                <td>
                                    {`${new Date(sr.updated_date).getDate()}-${new Date(sr.updated_date).getMonth() + 1
                                    }-${new Date(sr.updated_date).getFullYear()}, ${new Date(sr.updated_date).getHours()
                                    }:${new Date(sr.updated_date).getMinutes()}:${new Date(sr.updated_date).getSeconds()
                                    }`}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <ul className='btns'>
                        <li><button className='btn' onClick={() => setlimit(limit + 30)}>Show More</button></li>
                        <li><button className='btn' onClick={() => setlimit(30)}>Show Less</button></li>
                    </ul>
                    </>
                ) : (
                    <div className="no-data">No Resolved Requests</div>
                )}
                </div>
                <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                {servicerequestsData.length > 0 ? (
                    <>
                    <table className="home border">
                        <tbody>
                            <tr>
                                <th>SR Id</th>
                                <th>Customer Name</th>
                                <th>Customer Phone</th>
                                <th>Status</th>
                                <th>Updated Date</th>
                            </tr>
                        {servicerequestsData.map((sr) =>
                            <tr key={sr.srid}>
                                <td>
                                    <KuroLink to={'/servicerequest/' + sr.srid}>{sr.srid}</KuroLink>
                                </td>
                                <td>{sr.name}</td>
                                <td>{sr.phone}</td>
                                <td>{sr.status}</td>
                                <td>
                                    {`${new Date(sr.updated_date).getDate()}-${new Date(sr.updated_date).getMonth() + 1
                                    }-${new Date(sr.updated_date).getFullYear()}, ${new Date(sr.updated_date).getHours()
                                    }:${new Date(sr.updated_date).getMinutes()}:${new Date(sr.updated_date).getSeconds()
                                    }`}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <ul className='btns'>
                        <li><button className='btn' onClick={() => setlimit(limit + 30)}>Show More</button></li>
                        <li><button className='btn' onClick={() => setlimit(30)}>Show Less</button></li>
                        <li><button className='btn' onClick={() => setlimit(0)}>Show All</button></li>
                    </ul>
                    </>
                ) : (
                    <div className="no-data">No Requests Found</div>
                )}
                </div>
            </div>
        </>
        )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(Service)