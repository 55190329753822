import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import ResponseBox from '../../components/ResponseBox'
import Switch from 'react-switch'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import '../../styles/tabs.css'

const EmployeeAccessLevelUpdate = ({ user: { token, userDetails }, match, admin: { accesslevels, bgDetails, employees } }) => {

    const { params: { empid } } = match
    const [empaccessdata, setempaccessdata] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const [resstatus, setresstatus] = useState("")
    const history = useHistory()
    const access = accesslevels.find(item => item.employee_accesslevel === "edit")
    const accesstypes = ["NA", "read", "write", "edit"]

    const accessKeyNames = {
        inward_invoices: 'Inward Invoices',
        inward_creditnotes: 'Inward Creditnotes',
        inward_debitnotes: 'Inward Debitnotes',
        outward_invoices: 'Outward Invoices',
        outward_creditnotes: 'Outward Creditnotes',
        outward_debitnotes: 'Outward Debitnotes',
        purchase_orders: 'Purchase Orders',
        paymentvouchers: 'Payment Vouchers',
        counters: 'Counters',
        vendors: 'Vendors',
        financials: 'Financials',
        export_data: 'Export Data',
        service_request: 'Service Request',
        user_list: 'Userlist',
        estimates: 'Estimates',
        inward_payments: 'Inward Payments',
        outward_payments: 'Outward Payments',
        outward: 'Outward',
        orders: 'TP Orders',
        offline_orders: 'Offline Orders',
        online_orders: 'Online Orders',
        indent: 'Indent',
        tp_builds: 'TP Builds',
        inventory: 'Inventory',
        stock: 'Stock',
        audit: 'Audit',
        products: 'Products',
        presets: 'Presets',
        replace_presets: 'Replace Presets',
        product_finder: 'Product Finder',
        pre_builts_finder: 'Pre-Built Finder',
        peripherals: 'Peripherals',
        portal_editor: 'Portal Editor',
        employees: 'Employees',
        employee_accesslevel: 'Employee Accesslevel',
        emp_attendance: 'Employee Attendance',
        profile: 'Employee Profile',
        employees_salary: 'Employees Salary',
        bg_group: 'Business Group',
        job_application: 'Job Application',
    }

    useEffect(() => {
        if (access.length === 0) {
            history.push('/unauthorized')
        }
    }, [userDetails, history])

    useEffect(() => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }
            axios(`${process.env.REACT_APP_KC_API_URL}accesslevel?empid=${empid}`, config)
            .then((res) => {
                setempaccessdata(res.data)
            })
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }, [token, empid])

    const submitHandler = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }
            axios.post(`${process.env.REACT_APP_KC_API_URL}accesslevel`, empaccessdata, config)
            .then((res) => {
                setresstatus(res.status)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            })
        } catch (error) {
            console.error('Error submitting data:', error)
        }
    }

    const okHandler = () => {
        setresstatus("")
    }

    const handleSwitchChange = (index, key, value) => {
        let tempaccess = [...empaccessdata]
        tempaccess[index][key] = value
        setempaccessdata(tempaccess)
    }

    return (
        <div className='employee txt-light-grey'>
            <p><b>Employee Name: {employees && employees.find(_ => _.userid === empid).name}</b></p>
            <p><b>Employee ID: {empid}</b></p>
            {empaccessdata.length > 1 &&
            <div className="tabs">
                {empaccessdata.map((item, i) => (
                    <button key={item.entity} onClick={() => setTabIndex(i)} className={"tab txt-grey" + (tabIndex === i ? " active" : "")}>{ bgDetails.entities.map(obj => obj.name===item.entity ? obj.title : null)}</button>
                ))}
            </div>
            }
            <div className="tab-panels">
                {empaccessdata.map((item, i) =>
                <div key={i} className={"tab-panel" + (tabIndex === i ? " active" : "")}>
                    <h2>Access for {bgDetails && bgDetails.entities.map(obj => obj.name===item.entity ? obj.title.toUpperCase() : null )}</h2>
                    <table className='border'>
                        <thead>
                            <tr>
                                <th>Access Item</th>
                                <th>NA</th>
                                <th>Read Only</th>
                                <th>Read/Write</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(item).filter(key => ["NA", "read", "write", "edit"].includes(item[key])).map((key) =>
                            <tr key={key}>
                                <td>{accessKeyNames[key] || key}</td>
                            {accesstypes.map((at, j) =>
                                <td key={j}>
                                    <Switch
                                        checked={item[key] === at}
                                        onChange={() => handleSwitchChange(i, key, at)}
                                        onColor="#8cbae8"
                                        onHandleColor="#1976d2"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={13}
                                        width={35}
                                        className="react-switch"
                                        id={`${key}-na`}
                                    />
                                </td>
                            )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                    <ul className="btns">
                        <li>
                            <button onClick={submitHandler}>Submit</button>
                        </li>
                    </ul>
                </div>
                )}
            </div>
            <div>
                {resstatus === 200 ? (
                    <ResponseBox msg="Accesslevel update has been Submitted Succesfully." okhandler={okHandler} />
                ) : (
                    resstatus !== "" && <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(EmployeeAccessLevelUpdate)
