import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import '../../styles/employee.css'
import Select from 'react-select'

const CreateEmp = ({ user: { token }, admin: { accesslevels } }) => {
    const defaultEmp = {
        name: '',
        phone: '',
        password: '',
        email: '',
        role: '',
        access: '',
        businessgroups: [],
    }

    const [emp, setEmp] = useState(defaultEmp)
    const [errorMsg, setErrorMsg] = useState('')
    const [bgData, setBgData] = useState([])

    const bgOptions = () => {
        let tempData = []
        let seenValues = new Set()

        accesslevels.forEach((item) => {
            if (!seenValues.has(item.bg_code)) {
                tempData.push({ label: bgData.filter(_ => _.bg_code === item.bg_code)[0].bg_name, value: item.bg_code })
                seenValues.add(item.bg_code)
            }
        })
        return tempData
    }

    // Handle input changes and normalize email
    const handleInputChange = (e, key) => {
        setErrorMsg('')
        setEmp((prev) => ({
            ...prev,
            [key]: key === 'email' ? e.target.value.toLowerCase() : e.target.value,
        }))
    }

    // Fetch business group data
    useEffect(() => {
        const fetchBgData = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${token}`,
                    },
                }
                const response = await axios.get(`${process.env.REACT_APP_KC_API_URL}bggroup`, config)
                setBgData(response.data)
            } catch (error) {
                setErrorMsg('Failed to fetch business group data')
            }
        }
        fetchBgData()
    }, [token])

    // Form validation and submission
    const submitHandler = async () => {
        if (!emp.name || !emp.role || !emp.access) {
            setErrorMsg('Name, Role, and Access Level are required')
            return
        }

        if (!emp.phone || emp.phone.length < 10) {
            setErrorMsg('Phone number must be at least 10 digits')
            return
        }

        if (!/(?!.*\.\.)(^[^.][^@\s]+@[^@\s]+\.[^@\s.]+$)/.test(emp.email)) {
            setErrorMsg('Invalid email address')
            return
        }

        // Set default password as phone number
        const employeeData = { ...emp, password: emp.phone, businessgroups: emp.businessgroups }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            }
            const response = await axios.post(`${process.env.REACT_APP_KC_API_URL}auth/kuroregister`, employeeData, config)

            if (response.data.status === 'SUCCESS') {
                setEmp(defaultEmp)
                setErrorMsg('Employee details have been submitted successfully')
            } else {
                setErrorMsg(response.data.msg)
            }
        } catch (error) {
            setErrorMsg('Failed to submit employee details')
        }
    }

    const updateBG = (e) => {
        let tempObj = []
        e.forEach((item) => { tempObj.push(item.value) })

        setEmp((prev) => ({
            ...prev,
            businessgroups: tempObj,
        }))
    }

    return (
        <div className="employee txt-light-grey mx-width">
            <h2>Enroll New Employee</h2>
            <div className="form_wrapper">
                <div className="app_form">
                    <div className="field_wrapper">
                        <label className="label">Full Name (As Per PAN)</label>
                        <div className="field">
                            <input placeholder="Full Name" onChange={(e) => handleInputChange(e, 'name')} value={emp.name}/>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <label className="label">Mobile No.</label>
                        <div className="field">
                            <input placeholder="Mobile Number" onChange={(e) => handleInputChange(e, 'phone')} value={emp.phone}/>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <label className="label">Email Address</label>
                        <div className="field">
                            <input placeholder="Email Address" onChange={(e) => handleInputChange(e, 'email')} value={emp.email}/>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <label className="label">Role</label>
                        <div className="field">
                            <select onChange={(e) => handleInputChange(e, 'role')} value={emp.role}>
                                <option value="">Select Role</option>
                                <option value="KC Admin">Kuro Cadence Admin</option>
                                <option value="KC Staff">Kuro Cadence Staff</option>
                                <option value="KG Staff">Kuro Gaming Staff</option>
                                <option value="RE Staff">Rebellion eSports Staff</option>
                            </select>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <label className="label">Access Level</label>
                        <div className="field">
                            <select onChange={(e) => handleInputChange(e, 'access')} value={emp.access}>
                                <option value="">Select Data Access Level</option>
                                <option value="Basic">Basic</option>
                                <option value="Super">Super</option>
                                <option value="Read-Only">Read-Only</option>
                            </select>
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <label className="label">Business Group</label>
                        <div className="field">
                            <Select classNamePrefix="kuro-search-select" defaultValue={defaultEmp.businessgroups} options={bgOptions()} isMulti onChange={(e) => updateBG(e)} placeholder="Select Business Group" className="react-select-container" />
                        </div>
                    </div>
                    <div className="field_wrapper">
                        <div className="field">
                            <button onClick={submitHandler}>Submit</button>
                            {errorMsg && <span className="error_msg">{errorMsg}</span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(CreateEmp)