import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import '../styles/table.css'
import NewOrder from '../components/NewOrder'
import KuroLink from '../components/common/KuroLink'
import '../styles/tabs.css'
import note from '../assets/img/button.png'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Switch from 'react-switch';

moment.tz.setDefault('Asia/Kolkata')

const OfflineOrders = ({ user: { token, userDetails }, admin: { accesslevels, bgDetails } }) => {
    const [orderflag, setorderflag] = useState("")
    const [ordersData, setOrdersData] = useState(null)
    const [tabIndex, setTabIndex] = useState(0)
    const [selectedentity, setSelectedentity] = useState(0)
    const [limit, setlimit] = useState(100)
    const history = useHistory();
    const orderaccess = accesslevels.find(item => item.offline_orders !== "NA");
    const [isEntitySwitchOn, setIsEntitySwitchOn] = useState(false);
    const [visible, setVisible] = useState(false);
    const [temporderid, setTempOrderid] = useState(null)

    useEffect(() => {
        if (orderaccess === undefined || orderaccess.length <= 0) {
            history.push("/unauthorized")
        }
    }, [])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        if(accesslevels[selectedentity].entity !== 'kurogaming'){
            setOrdersData(null)
            axios(process.env.REACT_APP_KC_API_URL + 'rebellion/reborders?entity='+ accesslevels[selectedentity].entity, config).then(
                res => {
                    setOrdersData(res.data)

                    }).catch((err) => {
                        if (err.response.status === 401) {
                            history.push("/unauthorized")
                        }
                    })
        }else{
            setOrdersData(null)
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?limit=' + limit, config).then(res => {
            setOrdersData(res.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })}
    }, [limit, orderflag, selectedentity])

    const limitHandler = (all) => {
        if (all) {
            setlimit(0)
        } else {
            setlimit(limit + 100)
        }
    }

    var fileDownload = require('js-file-download')

    const orderDownload = (orderid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?orderid=' + orderid + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, orderid + '.pdf')
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const handleSwitchChange = (checked) => {
        setIsEntitySwitchOn(checked);
        setSelectedentity(checked ? 1 : 0);
        setOrdersData(null)
        setTabIndex(0)
    };


    const closeHandlerfunc = ( orderid, status ) => {
        setVisible(true)
        setTempOrderid({'orderid':orderid, 'status':status})
    }

    const alertfunc = ( status=false ) =>{
        if(status === true){
            handleSubmit(temporderid.orderid, temporderid.status)
            setVisible(false)
        }
        else{
            setVisible(false);
            setTempOrderid(null)
        }
    }


    const orderChecklist = (orderid) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.get(process.env.REACT_APP_KC_API_URL + 'kurostaff/check_list?orderid=' + orderid, config)
            .then((res) => {
                fileDownload(res.data, 'checklist_' + orderid + '.pdf')
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const updateorder = (orderid, key, value) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let data = { "orderid": orderid }
        data[key] = value

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/kgorders?limit=' + limit, data, config)
            .then((res) => {
                setOrdersData(res.data)
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const handleSubmit = (orderid, status) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let url = `rebellion/reborders?out=${status}&orderid=${orderid}&update=true`;
        axios.post(`${process.env.REACT_APP_KC_API_URL}/${url}`, {}, config)
        .then(response => {
            setTempOrderid(null)
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
        <div className="orders txt-light-grey">
            <h2>Offline Orders</h2>
            <div className='notes'>
                <div className="instructions">
                    <p>Instructions</p>
                    <div className='instr'>
                        <img src={note} alt='instruction' className='note_img' />:
                        <span className="list">lorem text</span>
                    </div>
                </div>
                <div className="note">
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            </div>
            {accesslevels.filter(item => item.offline_orders !== 'NA').length > 1 && (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[0].entity)?.title.toUpperCase()}</td>
                                <td>
                                    <Switch
                                        checked={isEntitySwitchOn}
                                        onChange={handleSwitchChange}
                                        onColor="#8cbae8"
                                        onHandleColor="#1976d2"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={13}
                                        width={35}
                                        className="react-switch"
                                        id="entity-switch"
                                    />
                                </td>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[1].entity)?.title.toUpperCase()}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
            {accesslevels[selectedentity].entity === 'kurogaming' ? (
            <>
            <div className="note"><p>Instructions </p>
                <img src={note} alt='instruction' className='note_img' />:
                <span className="note_text">lorem text</span>
            </div>
            <div>
                <p className='txt-right'>Note:lorem text</p><br />
            </div>
            {orderflag === "create" ? (
                <NewOrder updateorderflag={setorderflag} />
            ) : (
                <div className="order-list txt-light-grey">
                    <div className="tabs">
                        <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>New Orders</button>
                        <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Authorized</button>
                        <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>Being Processed</button>
                        <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Shipped</button>
                        <button onClick={() => setTabIndex(4)} className={"tab txt-grey" + (tabIndex === 4 ? " active" : "")}>Delivered</button>
                        <button onClick={() => setTabIndex(5)} className={"tab txt-grey" + (tabIndex === 5 ? " active" : "")}>Cancelled/Returned</button>
                    </div>
                    <div className="tab-panels">
                        <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                            {ordersData !== null && ordersData.filter(order => order.order_status === "Created").length > 0 ? (
                                <table className="border table_mob" cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Order Details</th>
                                            <th>Customer Details</th>
                                            <th colSpan="2">Billing Address</th>
                                            <th colSpan="2">Shipping Address</th>
                                            <th>Order Confirmation</th>
                                            <th>Authorization</th>
                                            <th>Cancellation</th>
                                        </tr>
                                        {ordersData && ordersData.filter(order => order.order_status === "Created").map((order, i) =>
                                            <tr key={i}>
                                                <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                <td>
                                                    <p className='txt-light'>PO/Ref: <KuroLink to={'estimates/' + order.estimate_no + '/1'}>{order.estimate_no}</KuroLink></p>
                                                    <p className='txt-light'>Order Date: {moment(order.order_date).format('DD-MM-YYYY')}</p>
                                                    <p className='txt-light'>Order Total: {order.totalprice}</p>
                                                    {order.amount_due ? (
                                                        (accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                                            <p className='txt-light'>Amount Due: <KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink></p>
                                                        ) : (
                                                            <p className='txt-light'>Amount Due: {order.amount_due}</p>
                                                        )
                                                    ) : (
                                                        <p className='txt-light'>Payment Done</p>
                                                    )}
                                                    <p className='txt-light'>Dispatch Due Date: {order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}</p>
                                                </td>
                                                <td>
                                                    <p className='txt-light'>Name: {order.user.name}</p>
                                                    <p className='txt-light'>Mobile: {order.user.phone}</p>
                                                    {/* <p className='txt-light'>Email: {order.user.email}</p> */}
                                                </td>
                                                <td colSpan="2">
                                                    <p className='txt-light'>
                                                        {order.billadd.name && <>
                                                            {order.billadd.name}
                                                        </>}
                                                        {order.billadd.phone && <>
                                                            <br />{order.billadd.phone}
                                                        </>}
                                                        {order.billadd.addressline1 && <>
                                                            <br />{order.billadd.addressline1}
                                                        </>}
                                                        {order.billadd.addressline2 && <>
                                                            ,&nbsp;{order.billadd.addressline2}
                                                        </>}
                                                        {order.billadd.city && <>
                                                            <br />{order.billadd.city}
                                                        </>}
                                                        {order.billadd.state && <>
                                                            <br />{order.billadd.state}
                                                        </>}
                                                        {order.billadd.pincode && <>
                                                            &nbsp;- {order.billadd.pincode}
                                                        </>}
                                                        {order.billadd.gstin && <>
                                                            <br />GSTIN: {order.billadd.gstin}
                                                        </>}
                                                        {order.billadd.pan && <>
                                                            <br />PAN: {order.billadd.pan}
                                                        </>}
                                                    </p>
                                                </td>
                                                {order.addressflag ? (
                                                    <td colSpan="2">
                                                        <p className='txt-light'>
                                                            {order.billadd.name && <>
                                                                {order.billadd.name}
                                                            </>}
                                                            {order.billadd.phone && <>
                                                                <br />{order.billadd.phone}
                                                            </>}
                                                            {order.billadd.addressline1 && <>
                                                                <br />{order.billadd.addressline1}
                                                            </>}
                                                            {order.billadd.addressline2 && <>
                                                                ,&nbsp;{order.billadd.addressline2}
                                                            </>}
                                                            {order.billadd.city && <>
                                                                <br />{order.billadd.city}
                                                            </>}
                                                            {order.billadd.state && <>
                                                                <br />{order.billadd.state}
                                                            </>}
                                                            {order.billadd.pincode && <>
                                                                &nbsp;- {order.billadd.pincode}
                                                            </>}
                                                            {order.billadd.gstin && <>
                                                                <br />GSTIN: {order.billadd.gstin}
                                                            </>}
                                                            {order.billadd.pan && <>
                                                                <br />PAN: {order.billadd.pan}
                                                            </>}
                                                        </p>
                                                    </td>
                                                ) : (
                                                    <td colSpan="2">
                                                        <p className='txt-light'>
                                                            {order.shpadd.name && <>
                                                                {order.shpadd.name}
                                                            </>}
                                                            {order.shpadd.phone && <>
                                                                <br />{order.shpadd.phone}
                                                            </>}
                                                            {order.shpadd.addressline1 && <>
                                                                {order.shpadd.addressline1}
                                                            </>}
                                                            {order.shpadd.addressline2 && <>
                                                                ,&nbsp;{order.shpadd.addressline2}
                                                            </>}
                                                            {order.shpadd.city && <>
                                                                <br />{order.shpadd.city}
                                                            </>}
                                                            {order.shpadd.state && <>
                                                                <br />{order.shpadd.state}
                                                            </>}
                                                            {order.shpadd.pincode && <>
                                                                &nbsp;- {order.shpadd.pincode}
                                                            </>}
                                                            {order.shpadd.gstin && <>
                                                                <br />GSTIN: {order.shpadd.gstin}
                                                            </>}
                                                            {order.shpadd.pan && <>
                                                                <br />PAN: {order.shpadd.pan}
                                                            </>}
                                                        </p>
                                                    </td>
                                                )}
                                                <td>
                                                    <ul className='btns'>
                                                        <li><button onClick={() => orderDownload(order.orderid)}>Order Confirmation</button></li>
                                                    </ul>
                                                </td>
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ?
                                                    (<td>
                                                        <ul className='btns'>
                                                            <li><button onClick={() => updateorder(order.orderid, "order_status", "Authorized")}>Authorize</button></li>
                                                        </ul>
                                                    </td>):(<td>You Don`t Have Access</td>)
                                                }
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ?
                                                    (<td>
                                                        <ul className='btns'>
                                                            <li><button onClick={() => updateorder(order.orderid, "order_status", "Cancelled")}>Cancel Order</button></li>
                                                        </ul>
                                                    </td>):(<td>You Don`t Have Access</td>)
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='txt-light'>No New Order is Being Processed</p>
                            )}
                        </div>
                        <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                            {ordersData && ordersData.filter(order => order.order_status === "Authorized").length > 0 ? (
                                <table cellPadding="0" cellSpacing="0" className="border">
                                    <thead>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>Customer Name</th>
                                            <th>Mobile</th>
                                            <th>Order Total</th>
                                            <th>Dispatch Due Date</th>
                                            <th>Amount Due</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ordersData && ordersData.filter(order => order.order_status === "Authorized").map((order, i) =>
                                            <tr key={i}>
                                                <td><KuroLink to={'/offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                <td>{order.order_date ? moment(order.order_date).format('DD-MM-YYYY') : ""}</td>
                                                <td>{order.user.name}</td>
                                                <td>{order.user.phone}</td>
                                                <td>{order.totalprice}</td>
                                                <td>{order.dispatchby_date ? moment(order.dispatchby_date).format('DD-MM-YYYY') : ""}</td>
                                                <td>
                                                    {order.amount_due ? (
                                                        (accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                                            <p className='txt-light'><KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink></p>
                                                        ) : (
                                                            <p className='txt-light'> {order.amount_due}</p>
                                                        )
                                                    ) : (
                                                        <p className='txt-light'>Payment Done</p>
                                                    )}
                                                </td>
                                                {(accesslevels.find(item => (item.offline_orders === "edit" || item.offline_orders === "write") && item.entity === order.entity)) ? (
                                                    <td>
                                                        <KuroLink to={'/offlineorders/add-inventory/' + order.orderid}>
                                                            <button>Add Inventory</button>
                                                        </KuroLink>
                                                    </td>
                                                ):(<td>You Don`t Have Access</td>)}
                                                {accesslevels.find(item => item.offline_orders==="edit" && item.entity===order.entity) ?(
                                                    <td>
                                                        <button onClick={() => updateorder(order.orderid, "Cancelled")}>Cancel Order</button>
                                                    </td>
                                                ):(<td>You Don`t Have Access</td>)}
                                                <td>
                                                    <button onClick={() => orderChecklist(order.orderid)}>Check List</button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No Authorized Orders</p>
                            )}
                        </div>
                        <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                            {ordersData !== null && ordersData.filter(order => order.order_status === "Inventory Added" || order.order_status === "Build Started" || order.order_status === "Build Completed" || order.order_status === "Testing Started" || order.order_status === "Testing Completed" || order.order_status === "Packed").length > 0 ? (
                                <table cellPadding="0" cellSpacing="0" className="border table_mob">
                                    <tbody>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>Customer Name</th>
                                            <th>Mobile</th>
                                            <th>Order Total</th>
                                            <th>Order Status</th>
                                            <th>Dispatch Due Date</th>
                                            <th>Amount Due</th>
                                            <th>Generate Invoice</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        {ordersData !== null && ordersData.map((order, i) =>
                                            (order.order_status === "Inventory Added" || order.order_status === "Build Started" || order.order_status === "Build Completed" || order.order_status === "Testing Started" || order.order_status === "Testing Completed" || order.order_status === "Packed") &&
                                            <tr key={i} >
                                                <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                                <td>{order.user.name}</td>
                                                <td>{order.user.phone}</td>
                                                <td>{order.totalprice}</td>
                                                <td>{order.order_status}</td>
                                                <td>{order.dispatchby_date === "" ? "" : moment(order.dispatchby_date).format('DD-MM-YYYY')}</td>
                                                <td>
                                                    {order.amount_due ? (
                                                        (accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                                            <KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink>
                                                        ) : (
                                                            order.amount_due
                                                        )
                                                    ) : (
                                                        "Payment Done"
                                                    )}
                                                </td>
                                                {accesslevels.find(item => item.offline_orders=== "edit" && item.entity === order.entity)  ? (
                                                    order.invoice_generated ? <td>Invoice Generated</td> : (
                                                        <td><KuroLink classList="btn pri0 solid" to={'offlineorder-invoice/' + order.orderid}>Generate Invoice</KuroLink></td>
                                                    )):(<td>You Don`t Have Access</td>)}
                                                <td>
                                                    {order.builds !== 0 ? (
                                                        <button onClick={() => orderChecklist(order.orderid)}>Check List</button>
                                                    ) : (
                                                        <span>Not a Build</span>
                                                    )}
                                                </td>
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ? (<td><KuroLink to={'offlineorder-status/' + order.orderid}><button>Update Status</button></KuroLink></td>):(<td>You Don`t Have Access</td>)}
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ? (<td><button onClick={() => updateorder(order.orderid, "order_status", "Shipped")}>Shipped</button></td>):(<td>You Don`t Have Access</td>)}
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ? (<td><button onClick={() => updateorder(order.orderid, "order_status", "Cancelled")}>Cancel Order</button></td>):(<td>You Don`t Have Access</td>)}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='txt-light'>No Order is Being Processed</p>
                            )}
                        </div>
                        <div className={"tab-panel" + (tabIndex === 3 ? " active" : "")}>
                            {ordersData !== null && ordersData.filter(order => order.order_status === "Shipped").length > 0 ? (
                                <table cellPadding="0" cellSpacing="0" className="border">
                                    <tbody>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>Customer Name</th>
                                            <th>Mobile</th>
                                            <th>Order Total</th>
                                            <th>Amount Due</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        {ordersData && ordersData.filter(order => order.order_status === "Shipped").map((order, i) =>
                                            <tr key={i}>
                                                <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                                <td>{order.user.name}</td>
                                                <td>{order.user.phone}</td>
                                                <td>{order.totalprice}</td>
                                                <td>
                                                    {order.amount_due ? (
                                                        (accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                                            <KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink>
                                                        ) : (
                                                            order.amount_due
                                                        )
                                                    ) : (
                                                        "Payment Done"
                                                    )}
                                                </td>
                                                {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) ? (
                                                    order.invoice_generated ? (
                                                        <td>Invoice Generated</td>
                                                    ) : (
                                                        <td><KuroLink classList="btn pri0 solid" to={'offlineorder-invoice/' + order.orderid}>Generate Invoice</KuroLink></td>
                                                    )
                                                ):(<td>You Don`t Have Access</td>)}
                                                {accesslevels.find(item => item.offline_orders==="edit" && item.entity === order.entity) ? (<td><button onClick={() => updateorder(order.orderid, "order_status", "Delivered")}>Order Delivered</button></td>):(<td>You Don`t Have Access</td>)}
                                                <td><button onClick={() => orderChecklist(order.orderid)}>Check List</button></td>
                                                {accesslevels.find(item => item.offline_orders  === "edit" && item.entity === order.entity) ? (<td><button onClick={() => updateorder(order.orderid, "order_status", "Cancelled")}>Cancel Order</button></td>):(<td>You Don`t Have Access</td>)}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='txt-light'>No Order is in Transit</p>
                            )}
                        </div>
                        <div className={"tab-panel" + (tabIndex === 4 ? " active" : "")}>
                            {ordersData !== null && ordersData.filter(order => order.order_status === "Delivered").length > 0 ? (
                                <div className="order">
                                    <table cellPadding="0" cellSpacing="0" className="border txt-light table_mob">
                                        <tbody>
                                            <tr>
                                                <th>Order Id</th>
                                                <th>Order Date</th>
                                                <th>Customer Name</th>
                                                <th>Mobile</th>
                                                <th>Order Total</th>
                                                <th>Amount Due</th>
                                                <th>Generate Invoice</th>
                                                <th></th>
                                                {userDetails.access === "Super" && <th></th>}
                                            </tr>
                                            {ordersData && ordersData.filter(order => order.order_status === "Delivered").map((order, i) =>
                                                <tr key={i}>
                                                    <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                    <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                                    <td>{order.user.name}</td>
                                                    <td>{order.user.phone}</td>
                                                    <td>{order.totalprice}</td>
                                                    <td>
                                                        {order.amount_due ? (
                                                            (accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                                                <KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink>
                                                            ) : (
                                                                order.amount_due
                                                            )
                                                        ) : (
                                                            "Payment Done"
                                                        )}
                                                    </td>
                                                    {accesslevels.find(item => item.offline_orders === "edit" && item.entity === order.entity) && (
                                                        order.invoice_generated ? (
                                                            <td>Invoice Generated</td>
                                                        ) : (
                                                            <td><KuroLink classList="btn pri0 solid" to={'offlineorder-invoice/' + order.orderid}>Generate Invoice</KuroLink></td>
                                                        )
                                                    )}
                                                    <td><button onClick={() => orderChecklist(order.orderid)}>Check List</button></td>
                                                    {userDetails.access === "Super" && <td><button onClick={() => updateorder(order.orderid, "order_status", "Cancelled")}>Cancel Order</button></td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {limit !== 0 &&
                                        <div className='limit_handler'>
                                            <ul className='btns'>
                                                <li>
                                                    <button className='btn' onClick={() => limitHandler(false)}>Show More</button>
                                                </li>
                                                <li>
                                                    <button className='btn' onClick={() => limitHandler(true)}>Show All</button>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            ) : (
                                <p className='txt-light'>No Orders Delivered Recently</p>
                            )}
                        </div>
                        <div className={"tab-panel" + (tabIndex === 5 ? " active" : "")}>
                            {ordersData !== null && ordersData.filter(order => order.order_status === "Cancelled").length > 0 ? (
                                <table cellPadding="0" cellSpacing="0" className="border table_mob">
                                    <tbody>
                                        <tr>
                                            <th>Order Id</th>
                                            <th>Order Date</th>
                                            <th>Customer Name</th>
                                            <th>Mobile</th>
                                            <th>Order Total</th>
                                            {userDetails.access === "Super" && <th>Order Status</th>}
                                        </tr>
                                        {ordersData !== null && ordersData.map((order, i) =>
                                            order.order_status === "Cancelled" &&
                                            <tr key={i} >
                                                <td><KuroLink to={'offlineorders/' + order.orderid}>{order.orderid.toUpperCase()}</KuroLink></td>
                                                <td>{order.order_date === "" ? "" : moment(order.order_date).format('DD-MM-YYYY')}</td>
                                                <td>{order.user.name}</td>
                                                <td>{order.user.phone}</td>
                                                <td>{order.totalprice}</td>
                                                {userDetails.access === "Super" && <td>{order.order_status}</td>}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <p className='txt-light'>No Order Cancelled Recently</p>
                            )}
                        </div>
                    </div>
                </div>
            )}</>) : (<div>
                {accesslevels[selectedentity].offline_orders !== "NA" && accesslevels[selectedentity].offline_orders !== "read" && <ul className='btns'>
                  <li>
                    <KuroLink to={`/create-order/${accesslevels[selectedentity].entity}`}>
                      <button>Create Order</button>
                    </KuroLink>
                  </li>
                </ul>}
                <div className="tabs">
                    <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Opened Orders</button>
                    <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Closed Orders</button>
                </div>
              
                {ordersData && (
                <>
                <div className={tabIndex === 0 ? ("tab-panel" + (tabIndex === 0 ? " active" : "")):("tab-panel" + (tabIndex === 1 ? " active" : ""))}>
                  <table className='border' cellPadding="0" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Total Price</th>
                        <th>Close</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(tabIndex === 0 ? ordersData && ordersData.reborders : ordersData.closed_orders).map((order, i) => (
                          <tr key={i}>
                            <td><KuroLink to={`/reborder/${order.orderid}`}>{order.orderid}</KuroLink></td>
                            <td>{order.user?.name}</td>
                            <td>{order.user?.phone}</td>
                            <td>{order.amount_due ? (
                                    (
                                        accesslevels.find(item => ((item.inward_payments === "edit" || item.inward_payments === "write") && item.entity === order.entity))) ? (
                                        <p className='txt-light'>Amount Due: <KuroLink to={'inward-payments/' + order.orderid}>{order.amount_due}</KuroLink></p>
                                    ) : (
                                        <p className='txt-light'>Amount Due: {order.amount_due}</p>
                                    )
                                ) : (
                                    <p className='txt-light'>Payment Done</p>
                                )}
                            </td>
                            <td>
                            <li>
                                {tabIndex === 0 ? <button onClick={() =>  closeHandlerfunc(order.orderid, "out")}>Close</button> : <button onClick={() =>  closeHandlerfunc(order.orderid, "open")}>UnClose</button>}
                            </li>
                            </td>
                          </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                    <div className="alert" style={{ display: visible ? "block" : "none" }}>
                        <h3>Are you sure you want to {tabIndex === 0 ? 'close' : 'open'} the order?</h3>
                        <ul className='card_btns'>
                            <li><button onClick={() => alertfunc()}>No</button></li>
                            <li><button onClick={() => alertfunc(true)}>Yes</button></li>
                        </ul>
                    </div>
               </>
                )}
              </div>
              )
        }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(OfflineOrders)