import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Switch from 'react-switch';

const StockProd = ({ match, user: { token, userDetails }, products: { prodData }, admin: { accesslevels, bgDetails, KcProdData } }) => {
    const { params: { prodColl, prodType } } = match;
    const [stockdata, setstockdata] = useState(null);
    const [productsData, setProductsData] = useState(null)
    const [selectedEntity, setSelectedentity] = useState(0);
    const [prodtotal, setProdtotal] = useState(null);
    const [isEntitySwitchOn, setIsEntitySwitchOn] = useState(false);
    const history = useHistory();

    useEffect(()=>{
        if(prodData && KcProdData){
            setProductsData({...prodData, ...KcProdData})
        }
    }, [KcProdData, prodData])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        
        axios.get(`${process.env.REACT_APP_KC_API_URL}kurostaff/inventory?collection=${prodColl}&type=${prodType}`, config)
            .then(res => setstockdata(res.data)  
        )
            .catch(err => {
                if (err.response?.status === 401) {
                    history.push("/unauthorized");
                }
            });
        
        axios.get(`${process.env.REACT_APP_KC_API_URL}kurostaff/invCalculations?type=${prodType}&collection=${prodColl}`, config)
            .then(res => setProdtotal(res.data))
            .catch(err => console.error("Error fetching inventory calculations:", err));
    }, [prodColl, prodType, token, history]);

    const handleSwitchChange = (checked) => {
        setIsEntitySwitchOn(checked);
        setSelectedentity(checked ? 1 : 0);
    };

    console.log(stockdata)
    return (
        <div className="core-comp mx-width txt-light-grey">
            <p className="breadcrumb txt-light-grey">
                <KuroLink to="/stock-register">Stock Register</KuroLink> &gt; {prodType}
            </p>
            {accesslevels?.filter(accitem => accitem.inventory !== 'NA').length > 1 && (
                <table>
                    <tbody>
                        <tr>
                            <td>{bgDetails.entities?.find(obj => obj.name === accesslevels[0]?.entity)?.title?.toUpperCase()}</td>
                            <td>
                                <Switch
                                    checked={isEntitySwitchOn}
                                    onChange={handleSwitchChange}
                                    onColor="#8cbae8"
                                    onHandleColor="#1976d2"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={13}
                                    width={35}
                                    className="react-switch"
                                    id="entity-switch"
                                />
                            </td>
                            <td>{bgDetails.entities?.find(obj => obj.name === accesslevels[1]?.entity)?.title?.toUpperCase()}</td>
                        </tr>
                    </tbody>
                </table>
            )}
            {stockdata?.length && prodtotal ? (
                <>
                    <table>
                        <thead>
                            {prodtotal.map((total, index) => (
                                total.entity === accesslevels[selectedEntity].entity ? (
                                <tr key={index}>
                                    <th>{total.entity?.toUpperCase() || 'UNKNOWN'}</th>
                                    <th>{total.totalprice || 0} /-</th>
                                </tr>):(
                                <tr key={index}>
                                    <th>{accesslevels[selectedEntity].entity?.toUpperCase() || 'UNKNOWN'}</th>
                                    <th>{0} /-</th>
                                </tr>
                                )
                            ))}
                        </thead>
                    </table>
                    <table className="border" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                {userDetails.access === "Super" && <th>Product ID</th>}
                                <th>Title</th>
                                <th>Quantity</th>
                                <th>Avg Price</th>
                                <th>Min Price</th>
                                <th>Max Price</th>
                                {userDetails.access === "Super" && <th>Total</th>}
                            </tr>
                            {stockdata.map((prod, index) => (
                                prod.entity === accesslevels[selectedEntity].entity ? (
                                <tr key={index}>
                                    {userDetails.access === "Super" && <td>{prod.productid}</td>}
                                    <td>
                                        <KuroLink to={`/stock-register/prod/${prod.productid}`}>
                                            {productsData?.[prodColl]?.find(item => item.productid === prod.productid)?.title || 'Unknown' }
                                        </KuroLink>
                                    </td>
                                    <td>{prod.quantity}</td>
                                    <td>{prod.avgprice}</td>
                                    <td>{prod.minprice}</td>
                                    <td>{prod.maxprice}</td>
                                    {userDetails.access === "Super" && <td>{prod.totalprice}</td>}
                                </tr>):(
                                    null
                                )
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    products: state.products,
    user: state.user,
    admin: state.admin,
});

export default connect(mapStateToProps)(StockProd);
