export const mapData = [
    {
        "collections": [
            {
                "name": "food",
                "title": "Food",
                "types": []
            },
            {
                "name": "beverage",
                "title": "Beverage",
                "types": []
            },
            {
                "name": "components",
                "title": "Components",
                "types": [
                    {
                        "name": "cpu",
                        "title": "Processor",
                        "url": "processor"
                    },
                    {
                        "name": "mob",
                        "title": "Motherboard",
                        "url": "motherboard"
                    },
                    {
                        "name": "ram",
                        "title": "RAM (Memory)",
                        "url": "ram"
                    },
                    {
                        "name": "gpu",
                        "title": "Graphics Card",
                        "url": "graphics"
                    },
                    {
                        "name": "psu",
                        "title": "Power Supply",
                        "url": "powersupply"
                    },
                    {
                        "name": "ssd",
                        "title": "Solid State Drive",
                        "url": "ssd"
                    },
                    {
                        "name": "hdd",
                        "title": "Hard Drive",
                        "url": "hdd"
                    },
                    {
                        "name": "tower",
                        "title": "Case",
                        "url": "case"
                    },
                    {
                        "name": "cooler",
                        "title": "CPU Cooler",
                        "url": "cooler"
                    },
                    {
                        "name": "fan",
                        "title": "Fan",
                        "url": "case-fan"
                    }
                ]
            },
            {
                "name": "accessories",
                "title": "Accessories",
                "types": [
                    {
                        "name": "keyboard",
                        "title": "Keyboards",
                        "url": "keyboards",
                        "categories": [
                            {
                                "name": "basic",
                                "title": "Basic Keyboards",
                                "url": "basic"
                            },
                            {
                                "name": "membrane",
                                "title": "Membrane Keyboards",
                                "url": "membrane"
                            },
                            {
                                "name": "mechanical",
                                "title": "Mechanical Keyboards",
                                "url": "mechanical"
                            }
                        ]
                    },
                    {
                        "name": "keyboard-accessories",
                        "title": "Keyboard Accessories",
                        "url": "keyboard-accessories"
                    },
                    {
                        "name": "mouse",
                        "title": "Mouse",
                        "url": "mouse"
                    },
                    {
                        "name": "mouse-accessories",
                        "title": "Mouse Accessories",
                        "url": "mouse-accessories"
                    },
                    {
                        "name": "kbdmouse",
                        "title": "Keyboard and Mouse Combo",
                        "url": "keyboard-mouse-combo"
                    },
                    {
                        "name": "streaming-gear",
                        "title": "Streaming Gear",
                        "url": "streaming-gear",
                        "categories": [
                            {
                                "name": "capture-card",
                                "title": "Capture Card",
                                "url": "capture-card"
                            },
                            {
                                "name": "gaming-chair",
                                "title": "Gaming Chair",
                                "url": "gaming-chair"
                            },
                            {
                                "name": "streaming-mic",
                                "title": "Streaming Mic",
                                "url": "streaming-mic"
                            },
                            {
                                "name": "webcam",
                                "title": "Web Cam",
                                "url": "webcam"
                            },
                            {
                                "name": "gaming-headset",
                                "title": "Gaming Headset",
                                "url": "gaming-headset"
                            },
                            {
                                "name": "gaming-pad",
                                "title": "Gaming Pad",
                                "url": "gaming-pad"
                            }
                        ]
                    },
                    {
                        "name": "miscellaneous",
                        "title": "Other Peripherals",
                        "url": "miscellaneous",
                        "categories": [
                            {
                                "name": "gaming-pad",
                                "title": "Gaming Pad",
                                "url": "gaming-pad"
                            },
                            {
                                "name": "speakers",
                                "title": "Speakers",
                                "url": "speakers"
                            }
                        ]
                    }
                ]
            },
            {
                "name": "monitors",
                "title": "Monitors",
                "types": [
                    {
                        "name": "monitor",
                        "title": "Monitor"
                    }
                ]
            },
            {
                "name": "external",
                "title": "External Devices",
                "types": [
                    {
                        "name": "hdd",
                        "title": "HDD"
                    },
                    {
                        "name": "expansion-card",
                        "title": "Expansion Card"
                    }
                ]
            },
            {
                "name": "networking",
                "title": "Networking Gear",
                "types": [
                    {
                        "name": "wifi-card",
                        "title": "WiFi Card"
                    }
                ]
            },
            {
                "name": "cables",
                "title": "Cables",
                "types": [
                    {
                        "name": "hdmi",
                        "title": "HDMI Cable"
                    },
                    {
                        "name": "dptodp",
                        "title": "DisplayPort to DisplayPort Cable"
                    },
                    {
                        "name": "dptohdmi",
                        "title": "DisplayPort to HDMI Cable"
                    },
                    {
                        "name": "sata",
                        "title": "SATA Cable"
                    }
                ]
            },
            {
                "name": "electricals",
                "title": "Electricals",
                "types": [
                    {
                        "name": "ups",
                        "title": "UPS"
                    },
                    {
                        "name": "powercords",
                        "title": "Power Cords"
                    },
                    {
                        "name": "extensions",
                        "name": "Extension Boxes"
                    }
                ]
            }
        ]
    }
]