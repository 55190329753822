import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import KuroLink from '../components/common/KuroLink'
import ResponseBox from '../components/ResponseBox'
import { getVendors } from '../actions/admin'
import note from '../assets/img/button.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import '../styles/table.css'

const CreateInwardCNote = ({ user: { token }, admin: { vendors, accesslevels, bgDetails }, getVendors }) => {

    const history = useHistory()
    const access = accesslevels.find(item => (item.inward_creditnotes === "edit" || item.inward_creditnotes === "write"))
    const defcnote = { 'vendor': '', 'gstin': '', 'creditnote_no': '', 'creditnote_date': '', 'totalprice': 0, 'cgst': 0, 'sgst': 0, 'igst': 0, 'pay_status': 'Payment Pending', 'settled': 'No', 'entity': accesslevels.find(item => item.inward_creditnotes === "edit" || item.inward_creditnotes === "write").entity }
    const [cndata, setcndata] = useState(defcnote)
    const [submitted, setsubmitted] = useState(false)
    const [resstatus, setresstatus] = useState("")

    useEffect(() => {
        if (!access) {
            history.push("/unauthorized")
            return
        }
        getVendors()
    }, [])

    const updatecreditnote = (key, e) => {
        let tempcreditnote = { ...cndata }
        tempcreditnote[key] = e.target.value
        if (key === "gstin") {
            tempcreditnote.cgst = 0
            tempcreditnote.sgst = 0
            tempcreditnote.igst = 0
            tempcreditnote.totalprice = 0
        }
        setcndata(tempcreditnote)
    }

    const updatecreditnotedate = (date) => {
        let tempcreditnote = { ...cndata }
        tempcreditnote.creditnote_date = date
        setcndata(tempcreditnote)
    }

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateprice = (key, e) => {
        let tempcreditnote = { ...cndata }
        tempcreditnote[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempcreditnote[key])) {
            return tempcreditnote[key]
        }
        if (key === "totalprice") {
            if (tempcreditnote.gstin === "") {
                tempcreditnote.cgst = 0
                tempcreditnote.sgst = 0
                tempcreditnote.igst = 0
            } else if (tempcreditnote.gstin.startsWith("36")) {
                tempcreditnote.cgst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.09, 2)
                tempcreditnote.sgst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.09, 2)
                tempcreditnote.igst = 0
            } else {
                tempcreditnote.cgst = 0
                tempcreditnote.sgst = 0
                tempcreditnote.igst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempcreditnote.sgst = e.target.value
        } else if (key === "igst") {
            tempcreditnote.cgst = 0
            tempcreditnote.sgst = 0
        }
        setcndata(tempcreditnote)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let creditnote = cndata

        creditnote["creditnote_no"] = creditnote["creditnote_no"].trim()

        for (let key of ["totalprice", "cgst", "sgst", "igst"]) {
            creditnote[key] = parseFloat(creditnote[key])
        }

        if (creditnote.creditnote_no !== "" && creditnote.vendor !== "" && creditnote.creditnote_date !== "" && creditnote.totalprice !== 0) {
            let creditnotes = []
            creditnotes.push(creditnote)
            axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes', creditnotes, config)
                .then((res) => {
                    setresstatus(res.status)
                })
            setsubmitted(true)
        }
    }

    const resetHandler = () => {
        setcndata(defcnote)
    }

    const SuccessOkHandler = () => {
        setresstatus("")
        setsubmitted(false)
        history.push("/inward-creditnotes")
    }

    const okHandler = () => {
        setresstatus("")
        setsubmitted(false)
    }

    return (
        <div className="kuro_inventory entry txt-light">
            <h2 className="txt-light-grey">Credit Note</h2>
            <div className='notes'>
                <div className="instructions">
                    <p>Instructions</p>
                    <div className='instr'>
                        <img src={note} alt='instruction' className='note_img' />:
                        <span className="list">
                            <b>Vendor:</b> Name of the vendor issuing the credit note. <br />
                            <b>Entity:</b> Name of the entity receiving the credit note. <br />
                            <b>Vendor GSTIN:</b> GST Identification Number of the vendor. <br />
                            <b>Credit Note No.:</b> Unique number assigned to the credit note. <br />
                            <b>Credit Note Date:</b> Date the credit note was issued. <br />
                            <b>Credit Note Total:</b> Total value of the credit note. <br />
                            <b>CGST/SGST:</b> Central and State GST amounts, if applicable. <br />
                            <b>IGST:</b> Integrated GST amount, if applicable. <br />
                            <b>Payment Status:</b> Indicate whether the payment has been made. <br />
                            <b>Settled:</b> Mark as settled if the credit note has been adjusted. <br />
                            <b>Mandatory Fields:</b> Vendor, Credit Note No, Credit Note Date, Total Price <br />
                        </span>
                    </div>
                </div>
                <div className="note">
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            </div>
            {submitted ? (
                <div>
                    {resstatus === 200 ? (
                        <ResponseBox msg="Credit Note has been Submitted Succesfully." okhandler={SuccessOkHandler} />
                    ) : resstatus === "" ? null : (
                        <ResponseBox msg="Oops something gone wrong!" okhandler={okHandler} />
                    )}
                </div>
            ) : (
                <>
                    {cndata !== null &&
                        <table className="home border" cellSpacing="0" cellPadding="0">
                            <tbody>
                                <tr>
                                    <th>Vendor</th>
                                    <td>
                                        <select value={cndata.vendor} onChange={(e) => updatecreditnote("vendor", e)}>
                                            <option value="">Select Vendor</option>
                                            {vendors !== null &&
                                                vendors.map((vendor, j) =>
                                                    <option key={j} value={vendor.vendor_code}>{vendor.name}</option>
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={cndata.gstin} onChange={(e) => updatecreditnote("gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && cndata.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === cndata.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note No.</th>
                                    <td>
                                        <input value={cndata.creditnote_no} onChange={(e) => updatecreditnote("creditnote_no", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note Date</th>
                                    <td>
                                        <DatePicker className="medium" dateFormat='dd-MMM-yyyy' selected={cndata.creditnote_date} onChange={date => updatecreditnotedate(date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note Total</th>
                                    <td>
                                        <input className="small" value={cndata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {cndata.gstin === "" || cndata.gstin.startsWith("36") ? (
                                            <input className="small" value={cndata.cgst} onChange={(e) => updateprice("cgst", e)} />
                                        ) : (
                                            cndata.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {cndata.gstin === "" || cndata.gstin.startsWith("36") ? (
                                            cndata.igst
                                        ) : (
                                            <input className="small" value={cndata.igst} onChange={(e) => updateprice("igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Entity</th>
                                    <td>
                                        <select className="type" name="entity" value={cndata.entity} onChange={(e) => updatecreditnote("entity", e)} >
                                            {accesslevels.map((item, index) => (item.inward_creditnotes === "edit" || item.inward_creditnotes === "write") ? (<><option key={index} value={item.entity ? item.entity : null}>{bgDetails && bgDetails.entities.map(ent => ent.name === item.entity ? ent.title : null)}</option></>) : (null))}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>Payment Pending
                                    </td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>No </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    <ul className='btns'>
                        <li>
                            <button onClick={resetHandler}>Clear All</button>
                        </li>
                        <li>{(cndata.creditnote_no !== "" && cndata.vendor !== "" && cndata.creditnote_date !== "" && cndata.totalprice !== 0) ? (
                            <button onClick={submitHandler}>Submit</button>
                        ) : (
                            <button style={{ background: "#87858e", color: "#000" }}>Submit</button>
                        )}</li>
                        <li>
                            <KuroLink to="/inward-creditnotes"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                </>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(CreateInwardCNote)