import Hero from '../components/portal/Hero';
import { useState, useEffect, useCallback } from 'react';
import '../styles/portaleditor.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Switch from 'react-switch';
import admin from '../reducers/admin';

const titleOptions = {
  payment_terms: "Payment Terms",
  ds_ot: "Dispatch Schedule & Other Terms",
  po_terms: "Purchase Order Terms"
};

const PortalEditor = ({ user: { token, userDetails }, admin: { accesslevels, bgDetails } }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [admindata, setAdmindata] = useState({});
  const [editfalg, setEditflag] = useState(false);
  const [isEntitySwitchOn, setIsEntitySwitchOn] = useState(false);
  const [selectedentity, setSelectedEntity] = useState(0);
  const [brandData, setBrandData] = useState(null);
  const [editBrand, setEditBrand] = useState(0);

  const history = useHistory();

  const fetchAdminData = useCallback(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    };
    axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/adminportal`, config)
      .then((res) => {
        setAdmindata(res.data[0] || {});
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          history.push("/unauthorized");
        }
      });
  }, [token, history]);

  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
    }; 

    if (tabIndex === 1 && Object.keys(admindata).length === 0) {
      fetchAdminData();
    }
    if (tabIndex === 2) {
      axios.get(`${process.env.REACT_APP_KC_API_URL}kurostaff/brands`, config).then((res) => {
        setBrandData(res.data);
      })
    }
  }, [tabIndex, fetchAdminData]);

  const updatevalue = (key, i, e) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: prev[key].map((item, idx) => idx === i ? e.target.value : item)
    }));
  };

  const removefield = (key, bindex) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: prev[key].filter((_, idx) => idx !== bindex)
    }));
  };

  const addfield = (key, bindex) => {
    setAdmindata(prev => ({
      ...prev,
      [key]: [...prev[key].slice(0, bindex), "", ...prev[key].slice(bindex)]
    }));
  };

  const addingnewbrand = (entity, bindex) => {
    setBrandData(prev => ({
      ...prev,
      [entity]: prev[entity].length === 0
        ? [""]
        : [...prev[entity].slice(0, bindex), "", ...prev[entity].slice(bindex)]
    }));
  };
  
  const handleSwitchChange = (checked) => {
    setIsEntitySwitchOn(checked);
    setSelectedEntity(checked ? 1 : 0);
};

const handleEditBrand = (index, value) => {
  setBrandData((prevData) => {
    const updatedData = { ...prevData }; // Create a new copy of brandData

      updatedData[accesslevels[selectedentity].entity] = [...prevData[accesslevels[selectedentity].entity]]; // Clone array before modifying
      updatedData[accesslevels[selectedentity].entity][index] = value;

    return updatedData;
  });
};


  const handleDeleteBrand = (entity, index) => {
    console.log("entity", entity);
    let tempdata = { ...brandData };
      tempdata[entity].splice(index, 1);
    setBrandData(tempdata);
  };

  const submitdata = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
      };

      const response = await axios.post(`${process.env.REACT_APP_KC_API_URL}kuroadmin/adminportal`, admindata, config);

      if (response.status === 200) {
        alert("Data submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting data", error);
      alert("Failed to submit data");
    }
  };

  const brandsubmitdata = async () => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
      };
      const response = await axios.post(`${process.env.REACT_APP_KC_API_URL}kurostaff/brands`, brandData, config);
      if (response.status === 200) {
        setBrandData(response.data);
      }
    }
    catch (error) {
      console.error("Error submitting data", error);
  }
}

  return (
    <div>
      <h1 className="txt-light">Portal Editor</h1>
      <div className="btns tabs">
        <button
          onClick={() => setTabIndex(0)}
          className={`tab txt-grey${tabIndex === 0 ? " active" : ""}`}>
          Kuro Gaming
        </button>
        <button
          onClick={() => setTabIndex(1)}
          className={`tab txt-grey${tabIndex === 1 ? " active" : ""}`}>
          Admin Portal
        </button>
        <button
          onClick={() => setTabIndex(2)}
          className={`tab txt-grey${tabIndex === 2 ? " active" : ""}`}>
          Brands
        </button>
      </div><br />

      {tabIndex === 0 ? (
        <Hero />
      ) : ( tabIndex === 1 ? (
        <div>
          {editfalg ? (
            <>
              {Object.entries(admindata).map(([key, value]) => (
                <div key={key}>
                  <h4 className="txt-light"><b>{titleOptions[key]}</b></h4>
                  <table className="hero_table border input_mob txt-light" cellPadding="0" cellSpacing="0">
                    <tbody>
                      {Array.isArray(value) && value.map((item, i) => (
                        <tr key={i}>
                          <td>
                            <textarea
                              className="admindata_width"
                              value={item}
                              onChange={e => updatevalue(key, i, e)}
                            />
                          </td>
                          <td>
                              <span className="prod-span" onClick={() => addfield(key, i)}>
                                  <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
                                  </svg>
                              </span>
                              <span className="prod-span" onClick={() => removefield(key, i)}>
                                <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                </svg>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <ul className='btns'>
                <li>
                  <button onClick={submitdata}>Submit</button>
                </li>
                <li>
                  <button onClick={() => setEditflag(false)}>Cancel</button>
                </li>
              </ul>
            </>
          ) : (
            <>
              {Object.entries(admindata).map(([key, value]) => (
                <div key={key}>
                  <h4 className="txt-light"><b>{titleOptions[key]}</b></h4>
                  <table className="hero_table border input_mob txt-light" cellPadding="0" cellSpacing="0">
                    <tbody>
                      {Array.isArray(value) && value.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {item}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <ul className='btns'>
                <li>
                  <button onClick={() => setEditflag(true)}>Edit</button>
                </li>
              </ul>
            </>
          )}
        </div>
      ) : (
     
    <div>
    {brandData && brandData[accesslevels[selectedentity].entity].length === 0 && (
      <span className="prod-span" onClick={() => addingnewbrand(accesslevels[selectedentity].entity)}>
        <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
        </svg>
      </span>
    )}
    <table>
      <tbody>
        <tr>
          <td>{bgDetails.entities.find(obj => obj.name === accesslevels[0].entity)?.title.toUpperCase()}</td>
          <td>
            <Switch
              checked={isEntitySwitchOn}
              onChange={handleSwitchChange}
              onColor="#8cbae8"
              onHandleColor="#1976d2"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={13}
              width={35}
              className="react-switch"
              id="entity-switch"
            />
          </td>
          <td>{bgDetails.entities.find(obj => obj.name === accesslevels[1].entity)?.title.toUpperCase()}</td>
        </tr>
      </tbody>
    </table>
    <table className="table even_odd border">
      <thead>
        <tr>
          <th>Brand Name</th>
          <th>Edit</th>
          { userDetails.access === "Super" && <th>Delete</th> }
          <th>Add</th>
        </tr>
      </thead>
      <tbody>
        { brandData && brandData[accesslevels[selectedentity].entity].map((brand, index) => {
          const isEditing = editBrand === index + 1;
          return (
            <tr key={index}>
              <td style={{ minWidth: 200 }}>
                {isEditing ? (
                  <input
                    type="text"
                    value={brand}
                    onChange={(e) => handleEditBrand(index, e.target.value)}
                  />
                ) : (
                  brand
                )}
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={isEditing}
                  onChange={() => setEditBrand(isEditing ? 0 : index + 1)}
                />
              </td>
              { userDetails.access === "Super" &&
              <td>
                <button  onClick={() => handleDeleteBrand(accesslevels[selectedentity].entity ,index)}>Delete</button>
              </td>}
              <td>
                <span className="prod-span" onClick={() => addingnewbrand(accesslevels[selectedentity].entity, index + 1)}>
                  <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z"></path>
                  </svg>
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
    <ul className='btns'>
      <li>
        <button onClick={brandsubmitdata}>Submit</button>
      </li>
    </ul>
  </div>
      )
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  admin: state.admin
});

export default connect(mapStateToProps)(PortalEditor);
