import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { psspecs } from '../../data/Presets'
import { connect } from 'react-redux'
import Switch from 'react-switch';

const AddPreset = ({ admin: { accesslevels, bgDetails }, user : { token } }) => {

    let history = useHistory()
    const [selectedentity, setSelectedentity] = useState(0)
    const [isEntitySwitchOn, setIsEntitySwitchOn] = useState(false);
    const [kteampresets, setKteamPresets] = useState(null)
    const [pstype, setpstype] = useState("")
    const [presetdata, setpresetdata] = useState(accesslevels[selectedentity].entity === 'kurogaming' ? ({
        "presetid": "",
        "title": "",
        "kuro_title": "",
        "portal_title": "",
        "short_title": "",
        "specs": {},
        "overview": [],
        "images": [],
        "quantity": 1,
        "price": 0,
        "mrp": 0,
        "status": "Currently Unavailable",
     "vendor_price":[]
    }) : (
    {
        "title": "",
        "entity": accesslevels[0].entity,
        "price": 0,
        "add_on": 0,
        "quantity": 1,
        "duration": 1,
        "validity": 1,
        "desc": '',
        "images": [],
    }
    ))
    

    const pstypeHandler = (e) => {
        let temppreset = { ...presetdata }
        let ps_type = e.target.value
        let specs = {}
        let fltpsspecstype = psspecs.filter(ps => ps.type === ps_type)

        if (fltpsspecstype.length > 0) {
            for (let spec of fltpsspecstype[0].specs) {
                if (spec.type === "num" || spec.type === "dec") {
                    specs[spec.id] = 0
                } else if (spec.type === "txt" || spec.type === "select") {
                    specs[spec.id] = ""
                } else if (spec.type === "bool") {
                    specs[spec.id] = false
                }
            }
        }
        temppreset.specs = specs
        setpstype(ps_type)
        setpresetdata(temppreset)
    }
    useEffect(()=>{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/presets', config).then(res => {
            setKteamPresets(res.data)
        })
    }, [token])


    const updatepreset = (key, e, num) => {
        let temppreset = { ...presetdata }
        temppreset[key] = num ? Number(e.target.value) : e.target.value
        setpresetdata(temppreset)
    }

    const updatespecs = (key, value) => {
        let temppreset = { ...presetdata }
        temppreset.specs[key] = value
        setpresetdata(temppreset)
    }
    const updateoverview = (i, key, value) => {
        let temppreset = { ...presetdata }
        if(key==="images"){
            temppreset.overview[i][key][0] = value    
            setpresetdata(temppreset)
        }else{
            temppreset.overview[i][key] = value
            setpresetdata(temppreset)
            }
    }

    const handleSwitchChange = (checked) => {
        setIsEntitySwitchOn(checked);
        let tempchecked = checked ? 1 : 0
        setSelectedentity(tempchecked);
        if(accesslevels[tempchecked].entity !== 'kurogaming'){
            const tempdata = {...presetdata}
            tempdata.entity = accesslevels[tempchecked].entity
            setpresetdata(tempdata)
    }
    };

    const addoverview = () => {
        let temppreset = { ...presetdata }
        temppreset.overview.push({
            "title": "",
            "subtitle": "",
            "cta_button": "",
            "cta_link": "",
            "desc": "",
            "images": []
        })
        setpresetdata(temppreset)
    }
    const removeoverview = (index) => {
        let temppreset = { ...presetdata }
        temppreset.overview = temppreset.overview.filter((_, i) => i !== index)
        setpresetdata(temppreset)
    }
    const updateimages = (key, index, e) => {
        let temppreset = { ...presetdata }
        temppreset[key][index] = e.target.value
        setpresetdata(temppreset)
    }

    const addimage = () => {
        let temppreset = { ...presetdata }
        temppreset.images.push("")
        setpresetdata(temppreset)
    }

    const removeimage = (index) => {
        let temppreset = { ...presetdata }
        temppreset.images = temppreset.images.filter((_, i) => i !== index)
        setpresetdata(temppreset)
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const submitHandler = () => {
        let config = {}
        let url = ""
        let psData = presetdata
        if (psData.entity === "user") {
            for (let key of ["title", "kuro_title", "portal_title", "short_title"]) {
                psData[key] = psData[key].trim()
            }
            for (let spec in psData.specs) {
                let pskey = psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]
                if (pskey) {
                    if (pskey.type === "dec") {
                        psData.specs[spec] = parseFloat(psData.specs[spec])
                    } else if (pskey.type === "txt") {
                        psData.specs[spec] = psData.specs[spec].trim()
                    }
                }
            }
            url = process.env.REACT_APP_KG_API_URL
            }
        else{
            url = process.env.REACT_APP_KC_API_URL
            config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }
        }

        axios.post( url + 'kuroadmin/presets?pstype=' + pstype + '&new=new', psData, config)
            .then((res) => {
                setpresetdata(res.data)
            })
        history.push(`/presets?pstype=${pstype}&presetid=${presetdata.presetid}`)
    }

    const cancelHandler = () => {
        history.push("/presets")
    }

    return (
        <div className='preset_data'>
            <h2 className='txt-light-grey even_odd'>Preset</h2>

            {accesslevels.filter(item => item.offline_orders !== 'NA').length > 1 && (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[0].entity)?.title.toUpperCase()}</td>
                                <td>
                                    <Switch
                                        checked={isEntitySwitchOn}
                                        onChange={handleSwitchChange}
                                        onColor="#8cbae8"
                                        onHandleColor="#1976d2"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={13}
                                        width={35}
                                        className="react-switch"
                                        id="entity-switch"
                                    />
                                </td>
                                <td>{bgDetails.entities.find(obj => obj.name === accesslevels[1].entity)?.title.toUpperCase()}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            )}
            { accesslevels[selectedentity].entity === 'kurogaming' ? (
            <table className='border txt-light'>
                <tbody>
                    <tr>
                        <th>Preset Type</th>
                        <td>
                            <select className='inputsel' value={pstype} onChange={pstypeHandler}>
                                <option value="">Select Type</option>
                                <option value="cpu">Processor</option>
                                <option value="mob">Motherboard</option>
                                <option value="ram">RAM</option>
                                <option value="gpu">Graphics Card</option>
                                <option value="ssd">SSD</option>
                                <option value="hdd">HDD</option>
                                <option value="psu">Power Supply</option>
                                <option value="cooler">Cooler</option>
                                <option value="tower">Case</option>
                                <option value="fans">Fans</option>
                                <option value="os">Operating System</option>
                                <option value="shp_fees">Shipping Fees</option>
                                <option value="build_fees">Build Charges</option>
                                <option value="warranty">Warranty</option>
                                <option value="margin">KM</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Customer Title</th>
                        <td className="large"><textarea value={presetdata.title} onChange={(e) => updatepreset("title", e)} /></td>
                    </tr>
                    <tr>
                        <th>Kuro Title</th>
                        <td className="large"><textarea value={presetdata.kuro_title} onChange={(e) => updatepreset("kuro_title", e)} /></td>
                    </tr>
                    <tr>
                        <th>Portal Title</th>
                        <td className="large"><textarea value={presetdata.portal_title} onChange={(e) => updatepreset("portal_title", e)} /></td>
                    </tr>
                    <tr>
                        <th>Short Title</th>
                        <td className="large"><textarea value={presetdata.short_title} onChange={(e) => updatepreset("short_title", e)} /></td>
                    </tr>
                    {pstype !== "" &&
                        <>
                            <tr>
                                <th colSpan="2">Specs</th>
                            </tr>
                            {Object.keys(presetdata.specs).map((spec, i) =>
                                psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec).map((pskey, j) =>
                                    <tr key={i + "" + j}>
                                        <th>{pskey.text}</th>
                                        <td>
                                            {(pskey.type === "txt" || pskey.type === "dec") &&
                                                <input value={presetdata.specs[spec]} onChange={(e) => updatespecs(spec, e.target.value)} />
                                            }
                                            {pskey.type === "num" &&
                                                <input type="number" value={presetdata.specs[spec]} onChange={(e) => updatespecs(spec, Number(e.target.value))} />
                                            }
                                            {pskey.type === "bool" &&
                                                <div style={{ display: "flex" }}>
                                                    <span>Yes</span><input type="radio" value={true} checked={presetdata.specs[spec] === true} onChange={() => updatespecs(spec, true)} />
                                                    <span>No</span><input type="radio" value={false} checked={presetdata.specs[spec] === false} onChange={() => updatespecs(spec, false)} />
                                                </div>
                                            }
                                            {pskey.type === "select" &&
                                                <select onChange={(e) => updatespecs(spec, e.target.value)} value={presetdata.specs[spec]}>
                                                    <option value="">Select {pskey.text}</option>
                                                    {pskey.options.map((opt, j) =>
                                                        <option value={opt}>{opt}</option>
                                                    )}
                                                </select>
                                            }
                                            {pskey.postfix &&
                                                <spna>&nbsp;&nbsp;{psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0].postfix}</spna>
                                            }
                                        </td>
                                    </tr>
                                )
                            )}
                        </>
                    }

                    <tr>
                        <th style={{ height: '50px' }} colSpan="2">Overview<span className='checkbox' onClick={() => addoverview()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></th>
                    </tr>
                    {
                        presetdata.overview.length > 0 && (
                            presetdata.overview.map((obj, i) => {
                                return (
                                    <>
                                        <th colSpan='2' >Overview {i + 1} <span onClick={() => removeoverview(i)} style={{ marginLeft: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></th>
                                        {Object.keys(obj).map((key, j) => {
                                            return (
                                                <tr key={j}>
                                                    <th style={{ textTransform: 'capitalize' }}>{key.replace(/_/g, ' ')}</th>
                                                    <td>
                                                        <textarea value={obj[key]} onChange={(e) => updateoverview(i, key, e.target.value)} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                );
                            })
                        )
                    }
                    {/* <tr>
                        <th>Description</th>
                        <td className="large"><textarea value={presetdata.desc} onChange={(e) => updatepreset("desc", e)} /></td>
                    </tr> */}
                    <tr>
                        <th colSpan="2">Images <span onClick={() => addimage()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></th>
                    </tr>
                    {presetdata.images.map((img, i) =>
                        <tr>
                            <td key={i} colSpan="2"><input value={img} onChange={(e) => updateimages("images", i, e)} />
                                <span onClick={() => removeimage(i)} style={{ marginLeft: "10px" }}>
                                    <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                <span onClick={() => addimage()} style={{ margin: "10px" }}>
                                    <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>Stock Availability</th>
                        <td className="medium">
                            <select value={presetdata.status} onChange={(e) => updatepreset("status", e)}>
                                <option value="">Select Stock Availability</option>
                                <option value="In Stock">In Stock</option>
                                <option value="Currently Unavailable">Currently Unavailable</option>
                                <option value="Check Availability">Check Availability</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Price</th>
                        <td className="small"><input type="number" onWheel={disableScroll} value={presetdata.price} onChange={(e) => updatepreset("price", e, true)} /></td>
                    </tr>
                    <tr>
                        <th>MRP</th>
                        <td className="small"><input type="number" onWheel={disableScroll} value={presetdata.mrp} onChange={(e) => updatepreset("mrp", e, true)} /></td>
                    </tr>
                </tbody>
            </table>) :(
               <>
               <table className='border'>
                   <tbody>
                    <tr>
                        <th className="large">Type</th>
                        <td>
                            <select value={presetdata.type} onChange={e => updatepreset("type", e) } name="type">
                                <option value="">Select Type</option>
                                { kteampresets && kteampresets.map(
                                    (kteampresets, index) => (
                                        <option key={index} value={kteampresets.type}>{kteampresets.title}</option>
                                    )
                                )
                                }
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>Entity</th>
                        <td>{presetdata.entity}</td>
                    </tr>
                       <tr>
                           <th className="large">Title</th>
                           <td className="large">
                               <textarea value={presetdata.title} onChange={(e) => updatepreset("title", e)} />
                           </td>
                       </tr>
                       <tr>
                       <th className="small">Price</th>
                           <td className="small">
                               <input type="number" onWheel={disableScroll} value={presetdata.price} onChange={(e) => updatepreset("price", e, true)} />
                           </td>
                       </tr>
                           <tr>
                               <th>Add On</th>
                               <td><input type="number" onWheel={disableScroll} value={presetdata.add_on} onChange={(e) => updatepreset("add_on", e, true)} /></td>
                           </tr>
                           <tr>
                               <th>Quantity</th>
                               <td><input type="number" onWheel={disableScroll} value={presetdata.quantity} onChange={(e) => updatepreset("quantity", e, true)} /></td>
                           </tr>
                           <tr>
                               <th>Duration</th>
                               <td><input type="number" onWheel={disableScroll} value={presetdata.duration} onChange={(e) => updatepreset("duration", e, true)} /></td>
                           </tr>
                           <tr>
                               <th>Validity</th>
                               <td><input type="number" onWheel={disableScroll} value={presetdata.validity} onChange={(e) => updatepreset("validity", e, true)} /></td>
                           </tr>
                           <tr>
                               <th>description</th>
                               <td><textarea value={presetdata.title} onChange={(e) => updatepreset("desc", e)} /></td>
                           </tr>
                           <tr>
                               <th>Images</th>
                               {presetdata.images && presetdata.images.map((img, i) =>
                                   <tr>
                                       <td key={i} colSpan="2"><input className='large' value={img} onChange={(e) => updateimages("images", i, e)} />
                                           <span onClick={() => removeimage(i)} style={{ marginLeft: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                           <span onClick={() => addimage()} style={{ margin: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                       </td>
                                   </tr>
                               )}
                               {presetdata.images.length <= 0 && (
                                   <span onClick={() => addimage()} style={{ margin: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                               )}
                                   
                           </tr>
                   </tbody>
               </table>
        </>
            )}
            <ul className='btns'>
                <li><button onClick={submitHandler}>Submit</button></li>
                <li><button onClick={cancelHandler}>Cancel</button></li>
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    products: state.products,
    user: state.user
})

export default connect(mapStateToProps)(AddPreset)