import React, { useState, useEffect } from 'react';
import axios from 'axios';
import KuroLink from '../components/common/KuroLink';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import AddProduct from '../components/AddProduct';
import '../styles/products.css';
import '../styles/tabs.css';
import note from '../assets/img/button.png';

const Products = ({ admin: { accesslevels }, user: { token } }) => {
    const [prodData, setProdData] = useState(null);
    const [kgData, setKgData] = useState(null);
    const [kcData, setKcData] = useState(null);
    const [addprod, setaddprod] = useState(false);
    const history = useHistory();
    const editaccess = accesslevels.find(item => item.products === "edit");
    const access = accesslevels.find(item => item.products === "write" || item.products === "edit");

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            if (access===undefined || access.length <= 0) {
                history.push('/unauthorized');
                return;
            }
            await gettempproducts();
        };

        if (isMounted) {
            fetchData();
        }

        return () => {
            isMounted = false;
        };
    }, [token, editaccess, history]);

    const gettempproducts = async () => {
        const kgconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const kcconfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        };

        try {
            const kgResponse = await axios.get(process.env.REACT_APP_KG_API_URL + 'kuroadmin/tempproducts', kgconfig);
            const kcResponse = await axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/tempproducts', kcconfig);

            setKgData(kgResponse.data);
            setKcData(kcResponse.data);
        } catch (err) {
            if (err.response?.status === 401) {
                history.push("/unauthorized");
            }
        }
    };

    useEffect(() => {
        if (kcData && kgData) {
            setProdData([...kcData, ...kgData]);
        }
    }, [kcData, kgData]);

    const approveHandler = (index) => {
        const kg = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const kc = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        };

        const body = prodData[index];
        let url = (body.collection === 'food' || body.collection === 'beverage') ? process.env.REACT_APP_KC_API_URL : process.env.REACT_APP_KG_API_URL
        let headers = (body.collection === 'food' || body.collection === 'beverage') ? kc : kg

        axios.post( url + 'kuroadmin/addproduct', body, headers)
            .then((res) => {
                const updatedProdData = prodData.filter((_, i) => i !== index);
                setProdData(updatedProdData);
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    history.push("/unauthorized");
                }
            });
    };

    const deleteHandler = (productid, coll) => {
        const kg = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const kc = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        };

        let url = coll === 'products' ? process.env.REACT_APP_KC_API_URL : process.env.REACT_APP_KG_API_URL
        let headers = coll === 'products' ? kc : kg

        axios.delete(url + 'kuroadmin/addproduct?productid=' + productid, headers)
            .then((res) => {
                const updatedProdData = prodData.filter(prod => prod.productid !== productid);
                setProdData(updatedProdData);
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    history.push("/unauthorized");
                }
            });
    };

    const prodHandler = (flag) => {
        setaddprod(flag);
    };

    const removeSplChar = (str) => {
        return str.replace(/[^a-zA-Z0-9-]/g, '');
    };

    return (
        <div className="prod prod-list">
            {addprod ? (
                <AddProduct backHandler={prodHandler} />
            ) : (
                <>
                    <ul className='btns'>
                        <li>{accesslevels.find(acc => acc.products === 'edit' || acc.products === 'write') ? <button onClick={() => prodHandler(true)}>Add Product</button> : null}</li>
                    </ul>
                    <div className='notes'>
                        <div className="instructions">
                            <p>Instructions</p>
                            <div className='instr'>
                                <img src={note} alt='instruction' className='note_img' />:
                                <span className="list">lorem text</span>
                            </div>
                        </div>
                        <div className="note">
                            <p className='txt-right'>Note:lorem text</p><br />
                        </div>
                    </div>
                    {prodData !== null && prodData.length > 0 ? (
                        prodData.map((prod, i) =>
                            <table className='border txt-light' key={prod.productid} cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td>Product Id</td>
                                        <td>Product Url</td>
                                        <td>Title</td>
                                        <td>Collection</td>
                                        <td>Type</td>
                                        <td>Category</td>
                                        <td>Brand</td>
                                        {editaccess ? <td rowSpan="2"><button id="submit" onClick={() => approveHandler(i)}>Approve</button></td> : null}
                                        {editaccess ? <td rowSpan="2"><button id="delete" onClick={() => deleteHandler(prod.productid, prod.collection)}>Delete</button></td> : null}
                                    </tr>
                                    <tr>
                                        <td><KuroLink to={"/product/" + removeSplChar(prod.prod_url)}>{prod.productid}</KuroLink></td>
                                        <td>{prod.prod_url}</td>
                                        <td>{prod.title}</td>
                                        <td>{prod.collection}</td>
                                        <td>{prod.type}</td>
                                        <td>{prod.category}</td>
                                        <td>{prod.maker}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )
                    ) : (
                        <p className='txt-light-grey'>No new products Added</p>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
});

export default connect(mapStateToProps)(Products);