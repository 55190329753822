import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getVendors } from '../actions/admin'
import '../styles/table.css'
import note from '../assets/img/button.png'
import moment from 'moment-timezone'
import KuroLink from '../components/common/KuroLink'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import DeleteBox from '../components/DeleteBox'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

moment.tz.setDefault('Asia/Kolkata')

const InwardCreditNote = ({ match, user: { token, userDetails }, admin: { vendors, accesslevels, bgDetails  }, getVendors }) => {

    const history = useHistory()
    const { params: { creditnoteid } } = match
    const access = accesslevels.find(item => item.inward_creditnotes !== "NA")

    const [cndata, setcndata] = useState(null)
    const [updatedcndata, setupdatedcndata] = useState(null)
    const [editflag, seteditflag] = useState(false)
    const [loaddeletebox, setloaddeletebox] = useState(false)
    const [deleteid, setdeleteid] = useState("")

    useEffect(() => {
        if (access?.inward_creditnotes === undefined || access.length <= 0) {
            history.push("/unauthorized")
            return
        }
    }, [])

    useEffect(() => {
        getVendors()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?creditnoteid=' + creditnoteid, config).then(res => {
            setcndata(res.data[0])
            setupdatedcndata(res.data[0])
        }).catch((err) => {
            if (err.response.status === 401) {
                history.push("/unauthorized")
            }
        })
    }, [creditnoteid, getVendors])

    const editcreditnote = () => {
        setupdatedcndata(cndata)
        seteditflag(true)
    }

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updatecreditnote = (key, e) => {
        let tempcreditnote = { ...cndata }
        tempcreditnote[key] = e.target.value
        if (key === "gstin") {
            tempcreditnote.cgst = 0
            tempcreditnote.sgst = 0
            tempcreditnote.igst = 0
            tempcreditnote.totalprice = 0
        }
        setupdatedcndata(tempcreditnote)
    }

    const updatecreditnotedate = (date) => {
        let tempcreditnote = { ...updatedcndata }
        tempcreditnote.creditnote_date = date
        setupdatedcndata(tempcreditnote)
    }

    const updateprice = (key, e) => {
        let tempcreditnote = { ...updatedcndata }
        tempcreditnote[key] = e.target.value
        let reg = /^[0-9.]*$/
        if (!reg.test(tempcreditnote[key])) {
            return tempcreditnote[key]
        }
        if (key === "totalprice") {
            if (tempcreditnote.gstin === "") {
                tempcreditnote.cgst = 0
                tempcreditnote.sgst = 0
                tempcreditnote.igst = 0
            } else if (tempcreditnote.gstin.startsWith("36")) {
                tempcreditnote.cgst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.09, 2)
                tempcreditnote.sgst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.09, 2)
                tempcreditnote.igst = 0
            } else {
                tempcreditnote.cgst = 0
                tempcreditnote.sgst = 0
                tempcreditnote.igst = round("round", (parseFloat(tempcreditnote.totalprice) / 1.18) * 0.18, 2)
            }
        } else if (key === "cgst") {
            tempcreditnote.sgst = e.target.value
        } else if (key === "igst") {
            tempcreditnote.cgst = 0
            tempcreditnote.sgst = 0
        }
        setupdatedcndata(tempcreditnote)
    }

    const submitcreditnote = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let creditnote = updatedcndata

        creditnote["creditnote_no"] = creditnote["creditnote_no"].trim()

        for (let key of ["totalprice", "cgst", "sgst", "igst"]) {
            creditnote[key] = parseFloat(creditnote[key])
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?update=update&creditnoteid=' + creditnote.creditnoteid + "&fin_year=" + creditnote.fin_year, creditnote, config)
        setcndata(creditnote)
        seteditflag(false)
    }

    const cancelupdates = () => {
        setupdatedcndata(cndata)
        seteditflag(false)
    }
    const deleteHandler = (creditnoteid) => {
        setloaddeletebox(true)
        setdeleteid(creditnoteid)
    }

    const deletecreditnote = (data) => {
        setloaddeletebox(false)
        setdeleteid("")
        history.push('/inward-creditnotes')

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.delete(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardcreditnotes?creditnoteid=' + data.creditnoteid, config)
    }

    const cancelHandler = () => {
        setloaddeletebox(false)
        setdeleteid("")
    }

    return (
        <div className="creditnote txt-light">
            <div className='notes'>
                <div className="instructions">
                    <p>Instructions</p>
                    <div className='instr'>
                        <img src={note} alt='instruction' className='note_img' />:
                        <span className="list">
                            <b>Vendor:</b> Name of the vendor issuing the credit note. <br />
                            <b>Entity:</b> Name of the entity receiving the credit note. <br />
                            <b>Vendor GSTIN:</b> GST Identification Number of the vendor. <br />
                            <b>Credit Note No.:</b> Unique number assigned to the credit note. <br />
                            <b>Credit Note Date:</b> Date the credit note was issued. <br />
                            <b>Credit Note Total:</b> Total value of the credit note. <br />
                            <b>CGST/SGST:</b> Central and State GST amounts, if applicable. <br />
                            <b>IGST:</b> Integrated GST amount, if applicable. <br />
                            <b>Payment Status:</b> Indicate whether the payment has been made. <br />
                            <b>Settled:</b> Mark as settled if the credit note has been adjusted. <br />
                            <b>Mandatory Fields:</b> Vendor, Credit Note No, Credit Note Date, Total Price <br />
                        </span>
                    </div>
                </div>
                <div className="note">
                    <p className='txt-right'>Note:lorem text</p><br />
                </div>
            </div>
            <h2 className="txt-light-grey">Credit Note</h2>
            {editflag ? (
                [updatedcndata !== null &&
                    <div className="edit-creditnote">
                        <table className='border' cellPadding="0" cellSpacing="0">
                            <tbody>
                                {userDetails.access === "Super" &&
                                    <tr>
                                        <th>Credit Note ID</th>
                                        <td>{updatedcndata.creditnoteid}</td>
                                    </tr>
                                }
                                <tr>
                                    <th>Vendor</th>
                                    <td>{vendors !== null && vendors.filter(vendor => vendor.vendor_code === updatedcndata.vendor)[0].name}</td>
                                </tr>
                                <tr>
                            <th>Entity</th>
                            <td>
                                <select className="type" name="entity" value={updatedcndata.entity} onChange={(e) => updatecreditnote("entity", e)} >
                                    {accesslevels.map((item, index) => (item.inward_creditnotes === "edit" || item.inward_creditnotes === "write") ? (<><option key={index} value={item.entity ? item.entity : null}>{bgDetails && bgDetails.entities.map(ent => ent.name === item.entity ? ent.title : null)}</option></>) : (null))}
                                </select>
                            </td>
                                </tr>
                                <tr>
                                    <th>Vendor GSTIN</th>
                                    <td>
                                        <select value={updatedcndata.gstin} onChange={(e) => updatecreditnote("gstin", e)}>
                                            <option value="">Select Vendor GST</option>
                                            {vendors !== null && updatedcndata.vendor !== "" &&
                                                vendors.filter(vendor => vendor.vendor_code === updatedcndata.vendor).map((vendor, j) =>
                                                    vendor.gstdetails.map((item, k) =>
                                                        <option key={j + k} value={item.gst.gstin}>{item.gst.gstin}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note No.</th>
                                    <td>
                                        <input value={updatedcndata.creditnote_no} onChange={(e) => updatecreditnote("creditnote_no", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note Date</th>
                                    <td>
                                        <DatePicker selected={updatedcndata.creditnote_date === "" ? new Date() : new Date(updatedcndata.creditnote_date)} className="large" dateFormat='dd-MMM-yyyy' onChange={date => updatecreditnotedate(date)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Credit Note Total</th>
                                    <td>
                                        <input value={updatedcndata.totalprice} onChange={(e) => updateprice("totalprice", e)} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>CGST/SGST</th>
                                    <td>
                                        {(updatedcndata.gstin.startsWith("36")) ? (
                                            <input value={updatedcndata.cgst} onChange={(e) => updateprice("cgst", e)} />
                                        ) : (
                                            updatedcndata.cgst
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        {updatedcndata.gstin === "" || updatedcndata.gstin.startsWith("36") ? (
                                            updatedcndata.igst
                                        ) : (
                                            <input value={updatedcndata.igst} onChange={(e) => updateprice("igst", e)} />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Payment Status</th>
                                    <td>
                                        <select value={updatedcndata.pay_status} onClick={(e) => updatecreditnote("pay_status", e)}>
                                            <option value="Payment Pending">Payment Pending</option>
                                            <option value="Paid">Paid</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Settled</th>
                                    <td>
                                        <select value={updatedcndata.settled} onChange={(e) => updatecreditnote("settled", e)}>
                                            <option value="Yes">Yes</option>
                                            <option value="No" selected>No</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className="btns">
                            <li><button onClick={submitcreditnote}>Submit</button></li>
                            <li><button onClick={cancelupdates}>Cancel</button></li>
                        </ul>
                    </div>
                ]
            ) : (
                <div className="creditnote">
                    {accesslevels.find(item => item.inward_creditnotes === "edit") && <ul className="btns">
                        <li><button onClick={editcreditnote}>Edit the Credit Note Details</button></li>
                    </ul>}

                    <p>&nbsp;</p>
                    <table cellPadding="0" cellSpacing="0" className="border table_mobi">
                        <tbody>
                            {cndata !== null && (
                                <>
                                    <tr>
                                        <th>Credit Note ID</th>
                                        <td>{cndata.creditnoteid}</td>
                                    </tr>
                                    <tr>
                                        <th>Vendor</th>
                                        <td>{vendors !== null && vendors.filter((vendor) => vendor.vendor_code === cndata.vendor)[0]["name"] + " [" + cndata.gstin + "]"}</td>
                                    </tr>
                                    <tr>
                            <th>entity</th>
                                <td>{bgDetails && bgDetails.entities.find(ent => ent.name===cndata.entity)?.title || null}</td>
                            </tr>
                                    <tr>
                                        <th>Credit Note No.</th>
                                        <td>{cndata.creditnote_no}</td>
                                    </tr>
                                    <tr>
                                        <th>Credit Note Date</th>
                                        <td>{cndata.creditnote_date === "" ? "" : moment(cndata.creditnote_date).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <th>Credit Note Total</th>
                                        <td>{CurrencyFormat(cndata.totalprice)}</td>
                                    </tr>
                                    <tr>
                                        <th>CGST/SGST</th>
                                        <td>{cndata.cgst}</td>
                                    </tr>
                                    <tr>
                                        <th>IGST</th>
                                        <td>{cndata.igst}</td>
                                    </tr>
                                    <tr>
                                        <th>Payment Status</th>
                                        <td>{cndata.pay_status}</td>
                                    </tr>
                                    <tr>
                                        <th>Settled</th>
                                        <td>{cndata.settled}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <ul className='btns'>
                        {userDetails.access?.inward_creditnotes === "Super" && <li><button onClick={(e) => deleteHandler(cndata.creditnoteid)}>Delete Credit Note</button></li>}

                        <li><KuroLink to="/inward-creditnotes"><button>Back</button></KuroLink></li>
                    </ul>
                </div>
            )}
            {loaddeletebox && <DeleteBox msg="Are you sure you want to delete the Credit Note?" okhandler={deletecreditnote} cancelhandler={cancelHandler} data={{ "creditnoteid": deleteid }} />}
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors })(InwardCreditNote)