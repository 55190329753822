import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import note from '../assets/img/button.png'

moment.tz.setDefault('Asia/Kolkata')

const InwardPayment = ({ match, user: { token, userDetails }, admin: { accesslevels } }) => {
    let history = useHistory()
    const { params: { orderid } } = match

    const [paymentData, setPaymentData] = useState(null)
    const [newPayment, setNewPayment] = useState([{ amount: 0, payment_date: new Date(), mode: '', account: '', utr: '', remark: '' }])
    const [editPayment, setEditPayment] = useState(null)
    const [banks, setBanks] = useState(null)
    const [viewFlag, setViewFlag] = useState('default')

    const accounts = {
        "Cashfree Payment": ["KUROINDB0001"],
        "Pay U Payments": ["KUROINDB0001"],
        "Bank Transfer": ["KUROINDB0001", "KUROHDFC0002", "KUROUBIN0003"],
        "Google Pay UPI": ["KUROUBIN0003"],
        "PhonePe UPI": ["KUROUBIN0003"],
        "BharatPe UPI": ["KUROUBIN0003"],
        "BharatPe POS": ["KUROUBIN0003"],
    }

    const account_names = {
        "KUROINDB0001": "CA INDUSIND BANK 0571",
        "KUROHDFC0002": "CA HDFC BANK 8683",
        "KUROUBIN0003": "CA Union Bank of India 0069"
    }

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj))
    }

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/accounts?type=banks`, config).then(
            (response) => {
                setBanks(response.data[0]['content'])
            }
        )

        axios.get(`${process.env.REACT_APP_KC_API_URL}kuroadmin/inwardpayments?orderid=${orderid}`, config)
        .then(res => {
            setPaymentData(res.data[0])
            setEditPayment(deepCopy(res.data[0]))
        })
        .catch(err => {
            if (err.response.status === 401) {
                history.push('/unauthorized')
            }
        })
    }, [orderid, history, token])

    const handleUpdateViewFlag = (flag) => {
        setViewFlag(flag)
        if (flag === 'edit') {
            setEditPayment(deepCopy(paymentData))
        }
    }

    const handleUpdatePayment = (e, key, flag, index) => {
        const value = e.target.value
        // Regex for validating amount
        const reg = /^[0-9]*\.?[0-9]*$/

        if (flag === "add") {
            let tempPayment = [...newPayment]

            // Validate 'amount' before setting state
            if (key === 'amount' && !reg.test(value)) {
                return  // Exit if validation fails
            } else if (key === "mode") {
                if (value !== "" && value !== "Cash" && value !== "TDS") {
                    tempPayment[index]['account'] = accounts[value][0]
                } else {
                    tempPayment[index]['account'] = ""
                }
            }

            tempPayment[index][key] = value
            setNewPayment(tempPayment)

        } else if (flag === "edit") {
            let tempPayment = { ...editPayment }

            // Ensure the payments array exists and is valid
            if (tempPayment.payments && tempPayment.payments[index]) {
                // Validate 'amount' before setting state
                if (key === 'amount' && !reg.test(value)) {
                    return  // Exit if validation fails
                } else if (key === "mode") {
                    if (value !== "" && value !== "Cash" && value !== "TDS") {
                        tempPayment.payments[index]['account'] = accounts[value][0]
                    } else {
                        tempPayment.payments[index]['account'] = ""
                    }
                }

                tempPayment.payments[index][key] = value
                setEditPayment(tempPayment)
            }
        }
    }

    const handleUpdatePaymentDate = (date, flag, index) => {
        if (flag === "add") {
            let tempPayment = [...newPayment]
            tempPayment[index].payment_date = date
            setNewPayment(tempPayment)
        } else if (flag === "edit") {
            let tempPaymentData = { ...editPayment }
            tempPaymentData.payments[index].payment_date = date
            setEditPayment(tempPaymentData)
        }
    }

    const handleAddPayment = () => {
        setNewPayment([{ amount: 0, payment_date: new Date(), mode: '', account: '', utr: '', remark: '' }])
        setViewFlag('add')
    }

    const handleRemovePayment = (index) => {
        let tempPayment = { ...editPayment }
        tempPayment.payments = tempPayment.payments.filter((_, i) => i !== index)
        setEditPayment(tempPayment)
    }

    const addpayments = (flag) => {
        if (flag === "add") {
            let tempPayment = [...newPayment]
            tempPayment.push({ "amount": 0, "payment_date": new Date(), "mode": "", "account": "", "utr": "", "remark": "" })
            setNewPayment(tempPayment)
        }
        let temppayment = { ...editPayment }
        temppayment.payments.unshift({ "amount": 0, "payment_date": new Date(), "mode": "", "account": "", "utr": "", "remark": "" })
        setEditPayment(temppayment)
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleSubmitPayment = (e) => {
        e.preventDefault()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        let payments = newPayment.map(payment => {
            payment.amount = parseFloat(payment.amount)
            return payment
        })
        const updatedPayments = [...payments, ...paymentData.payments]
        axios.post(`${process.env.REACT_APP_KC_API_URL}kuroadmin/inwardpayments?orderid=${paymentData.orderid}`, updatedPayments, config)
        .then(res => {
            setPaymentData(res.data[0])
            setViewFlag('default')
        })
    }

    const handleUpdatePaymentOrder = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let payments = editPayment.payments.map(payment => {
            payment.amount = parseFloat(payment.amount)
            return payment
        })

        axios.post(`${process.env.REACT_APP_KC_API_URL}kuroadmin/inwardpayments?orderid=${editPayment.orderid}`, payments, config)
        .then(res => {
            setPaymentData(res.data[0])
            setViewFlag('default')
        })
    }

    return (
        <div>
            <div className='maincontainer'>
                <h3 className='txt-light'>Inward Payment</h3>
                <div className='notes'>
                    <div className="instructions">
                        <p>Instructions</p>
                        <div className='instr'>
                            <img src={note} alt='instruction' className='note_img' />
                            <span className="list">lorem text</span>
                        </div>
                    </div>
                    <div>
                        <p className='txt-right'>Note: lorem text</p><br />
                    </div>
                </div>
                {viewFlag === 'default' && (
                <div>
                    <table className="border txt-light-grey" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <th>OrderId</th>
                                <th>Name</th>
                                <th>Order Total</th>
                                <th>Amount Paid</th>
                                <th>Amount Due</th>
                            </tr>
                            {paymentData !== null &&
                            <tr>
                                <td>{paymentData.orderid}</td>
                                <td>{paymentData.name}</td>
                                <td>{paymentData.total}</td>
                                <td>{paymentData.amount_paid}</td>
                                <td>{paymentData.amount_due}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                    {paymentData !== null && banks !== null && paymentData.payments.length > 0 ? (
                    <div className='payment-details'>
                        <h3 className='txt-light'>Payments Details</h3>
                        <table className="border txt-light-grey" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>Amount</th>
                                    <th>Payment Date</th>
                                    <th>Mode</th>
                                    <th>Account</th>
                                    <th>Payment UTR</th>
                                    <th>Remarks</th>
                                </tr>
                                {paymentData.payments.map((payment, i) =>
                                <tr key={i}>
                                    <td>{payment.amount}</td>
                                    <td>{moment(payment.payment_date).format('DD-MM-YYYY')}</td>
                                    <td>{payment.mode}</td>
                                    <td>{banks.find(bid => payment.account===bid.bankid)?.name || ""}</td>
                                    <td>{payment.utr}</td>
                                    <td>{payment.remark}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    ) : (
                    <p className='txt-light'>No Payments have been recorded</p>
                    )}
                    <ul className='btns'>
                    {(accesslevels.find(item => item.inward_payments === 'write' || item.inward_payments === 'edit' || item.offline_orders === "edit")) && 
                        <li><button onClick={handleAddPayment}>Add Payment</button></li>
                    }
                    {userDetails.access === "Super" &&
                        <li><button onClick={() => handleUpdateViewFlag('edit')}>Edit Payments</button></li>
                    }
                        <li>
                            <button onClick={handleBack}>Back</button>
                        </li>
                    </ul>
                </div>
                )}
                {viewFlag === 'edit' && (
                <div>
                    {editPayment !== null && editPayment.payments.length > 0 ? (
                    <div>
                        <table className="border txt-light-grey" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th>Amount</th>
                                    <th>Payment Date</th>
                                    <th>Mode</th>
                                    <th>Account</th>
                                    <th>Payment UTR</th>
                                    <th>Remarks</th>
                                    <td>Actions</td>
                                </tr>
                                {editPayment.payments.map((item, i) =>
                                <tr key={i}>
                                    <td><input value={item.amount} onChange={(e) => handleUpdatePayment(e, 'amount', "edit", i)} /></td>
                                    <td>
                                    <DatePicker dateFormat='dd-MMM-yyyy'
                                        maxDate={new Date()}
                                        selected={item.payment_date === "" ? new Date() : new Date(item.payment_date)}
                                        onChange={(date) => handleUpdatePaymentDate(date, "edit", i)}
                                    />
                                    </td>
                                    <td>
                                        <select className='medium' value={item.mode} onChange={(e) => handleUpdatePayment(e, 'mode', "edit", i)}>
                                            <option value="">Select Payment Mode</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Google Pay UPI">Google Pay UPI</option>
                                            <option value="PhonePe UPI">PhonePe UPI</option>
                                            <option value="BharatPe UPI">BharatPe UPI</option>
                                            <option value="BharatPe POS">BharatPe POS</option>
                                            <option value="Cashfree Payment">Cashfree Payment</option>
                                            <option value="Pay U Payments">Pay U Payments</option>
                                            <option value="Bank Transfer">Bank Transfer</option>
                                            <option value="TDS">TDS</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select className='medium' value={item.account} onChange={(e) => handleUpdatePayment(e, 'account', 'edit', i)}>
                                            <option value="">Select Payment Account</option>
                                            {(item.mode !== "" && item.mode !== "Cash")&& accounts[item.mode].map((acid, index) => <option value={acid}>{account_names[acid]}</option>)}
                                        </select>
                                    </td>
                                    <td><input value={item.utr} onChange={(e) => handleUpdatePayment(e, 'utr', "edit", i)} /></td>
                                    <td>
                                        <textarea value={item.remark} onChange={(e) => handleUpdatePayment(e, 'remark', "edit", i)} />
                                    </td>
                                    <td>
                                        <span className="prod-span" onClick={() => addpayments("edit")}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                        <span className="prod-span" onClick={() => handleRemovePayment(i)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    ) : (
                    <p className='txt-light'>No Payments to edit</p>
                    )}
                    <ul className='btns'>
                    <li>
                        <button onClick={() => handleUpdateViewFlag('default')}>Back</button>
                    </li>
                    <li>
                        <button onClick={handleUpdatePaymentOrder}>Update Payments</button>
                    </li>
                    </ul>
                </div>
                )}
                {viewFlag === 'add' && (
                <div>
                    <h3 className='txt-light'>Add Payment Details</h3>
                    <table className="border txt-light-grey" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Payment Date</th>
                                <th>Mode</th>
                                <th>Account</th>
                                <th>Payment UTR</th>
                                <th>Remarks</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {newPayment && newPayment.map((payment, i) => (
                            <tr key={i}>
                                <td><input value={payment.amount} onChange={(e) => handleUpdatePayment(e, 'amount', 'add', i)} /></td>
                                <td>
                                <DatePicker
                                    dateFormat='dd-MMM-yyyy'
                                    selected={payment.payment_date}
                                    maxDate={new Date()}
                                    onChange={(date) => handleUpdatePaymentDate(date, 'add', i)}
                                />
                                </td>
                                <td>
                                    <select className='medium' value={payment.mode} onChange={(e) => handleUpdatePayment(e, 'mode', 'add', i)}>
                                        <option value="">Select Payment Mode</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Google Pay UPI">Google Pay UPI</option>
                                        <option value="PhonePe UPI">PhonePe UPI</option>
                                        <option value="BharatPe UPI">BharatPe UPI</option>
                                        <option value="Cashfree Payment">Cashfree Payment</option>
                                        <option value="Pay U Payments">Pay U Payments</option>
                                        <option value="BharatPe POS">BharatPe POS</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                        <option value="TDS">TDS</option>
                                    </select>
                                </td>
                                <td>
                                    <select className='medium' value={payment.account} onChange={(e) => handleUpdatePayment(e, 'account', 'add', i)}>
                                        <option value="">Select Payment Account</option>
                                        {(payment.mode !== "" && payment.mode !== "Cash")&& accounts[payment.mode].map((acid, index) => <option value={acid}>{account_names[acid]}</option>)}
                                    </select>
                                </td>
                                <td><input value={payment.utr} onChange={(e) => handleUpdatePayment(e, 'utr', 'add', i)} /></td>
                                <td>
                                    <textarea value={payment.remark} onChange={(e) => handleUpdatePayment(e, 'remark', 'add', i)} />
                                </td>
                                <td>
                                    <span className="prod-span" onClick={() => addpayments("add")}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                    <span className="prod-span" onClick={() => handleRemovePayment(i)}>
                                        <svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24">
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" />
                                        </svg>
                                    </span>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                    <ul className='btns'>
                        <li><button onClick={handleSubmitPayment}>Submit</button></li>
                        <li>
                            <button onClick={() => handleUpdateViewFlag('default')}>Back</button>
                        </li>
                    </ul>
                </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
})

export default connect(mapStateToProps)(InwardPayment)