import React, { useEffect, useState } from "react"; 
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Select from 'react-select';
import axios from "axios";
import '../styles/createorder.css';

const CreateOrder = ({ match, user: { token }, admin: { accesslevels, KcProdData } }) => {
    const history = useHistory();
    const temporderDetails = {
        type: "",
        selected_item: "",
        title:"",
        validity:"",
        presetid: "",
        quantity: 1,
        price: 0
    };
    const tempfoodDetails = {
        type: "",
        foodtype:"",
        name: "",
        quantity: 1,
        price: 0
    }
    const { params: { entity } } = match;
    const access = accesslevels.find(item => ((item.offline_orders === "edit" || item.offline_orders === "write") && item.entity === entity));
    const [orderData, setOrderData] = useState({
        user :{
            name: "",
            phone: ""
        },
        entity: entity,
        totalprice: 0,
        service: [],
        food: []
    });

    const [oldUsers, setOldUsers] = useState([]);
    const [invData, setInvData] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false); // Controls dropdown visibility
    const [filteredUsers, setFilteredUsers] = useState([]); // Stores filtered users based on input
    const [packagesData, setPackagesData] = useState(null);

    useEffect(() =>{
        if(access === undefined || access.length<=0){
            history.push("/unauthorized")
        }
    }, [token, orderData])

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/presets', config).then(
        response => {
            setPackagesData(response.data);
            }).catch(error => {
            
                });
        axios.get(process.env.REACT_APP_KC_API_URL + 'rebellion/rebusers', config).then(
                    response => {
                        setOldUsers(response.data);
                    }
                );
        axios.get(process.env.REACT_APP_KC_API_URL + 'kurostaff/inventory?entity=' + entity, config).then(
            response => {
                setInvData(response.data);

                })
    }, [token])

    useEffect(() => {
        const orderItemsTotal = orderData.service.reduce((sum, item) => sum + item.price, 0);
        const foodItemsTotal = orderData.food.reduce((sum, item) => sum + item.price, 0);
        const total = orderItemsTotal + foodItemsTotal;

        setOrderData(prevState => ({
            ...prevState,
            totalprice: total
        }));
    }, [orderData.service, orderData.food]);

    const updateOrderData = (e) => {
        const { name, value } = e.target;

        if (name in orderData.user) {
            setOrderData(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    [name]: value
                }
            }));

            // Filter old users based on input
            if (value.trim() !== "") {
                const filtered = oldUsers.filter(user =>
                    user.name.toLowerCase().includes(value.toLowerCase()) ||
                    user.phone.includes(value)
                );
                setFilteredUsers(filtered);
                setShowDropdown(true);
            } else {
                setShowDropdown(false);
            }
        } else {
            setOrderData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleUserSelect = (user) => {
        setOrderData(prevState => ({
            ...prevState,
            user: {
                name: user.name,
                phone: user.phone
            }
        }));
        setShowDropdown(false); // Hide dropdown after selection
    };


    const addingOrderItem = (index = 0) => {
        setOrderData(prevState => {
            const newOrderItems = [...prevState.service];
            newOrderItems.splice(index + 1, 0, { ...temporderDetails });
            return {
                ...prevState,
                service: newOrderItems
            };
        });
    };

    const addingFoodItem = (index = 0) => {
        setOrderData(prevState => {
            const newFooditems = [...prevState.food];
            newFooditems.splice(index + 1, 0, { ...tempfoodDetails });
            return {
                ...prevState,
                food: newFooditems
            };
        });
    };

    const removingOrderItem = (index) => {
        setOrderData(prevState => {
            const newOrderItems = [...prevState.service];
            newOrderItems.splice(index, 1);
            return {
                ...prevState,
                service: newOrderItems
            };
        });
    };

    const removingFoodItem = (index) => {
        setOrderData(prevState => {
            const newFoodItems = [...prevState.food];
            newFoodItems.splice(index, 1);
            return {
                ...prevState,
                food: newFoodItems
            };
        });
    };

    const updateOrderitems = (e, index) => {
        const { name, value } = e.target;
        setOrderData(prevState => {
            const newOrderItems = [...prevState.service];
            newOrderItems[index][name] = name ==='quantity' ? (isNaN(value) ? 1 : Math.abs(value)) : value;
            let temppackageData = null
            let temptype = ""
            newOrderItems.map((item, i) => {
                if(item.type !== "" && item.presetid !== ""){
                    let tempquantity = item.quantity === "" || item.quantity === 0 ? 1 : Math.abs(item.quantity)
                    tempquantity = isNaN(tempquantity) ? 1 : tempquantity
                    let package_list = packagesData.find(pac => pac.type===item.type)?.list
                    let obj = package_list.find(pre => pre.presetid === (!temppackageData || temptype !== item.type ? item.presetid : temppackageData))
                    let tempprice = 0
                    if(obj){
                        if(!temppackageData && obj.presetid === item.presetid){
                            tempprice += obj.price * Number(tempquantity)
                            temppackageData = item.presetid
                            temptype = item.type
                        }
                        else if(item.type === temptype){
                            let noofhours = packagesData.find(pack => pack.type === item.type)?.list.find(pre => pre.presetid === item.presetid)?.duration
                            if(noofhours){
                            tempprice += obj.add_on * Number(noofhours) * Number(tempquantity)
                        }
                        }
                        else{
                            tempprice += obj.price * Number(tempquantity)
                            temppackageData = item.presetid
                            temptype = item.type
                        }
                        newOrderItems[i].validity = obj.validity
                    }
                        newOrderItems[i].price = tempprice
                        
                    }
                })
            return {
                ...prevState,
                service: newOrderItems
                };
            })
    }

    const updatefoodtype = (index, e) =>{
        const { name, value } = e.target;   
        const tempData = {...orderData}
        tempData.food[index][name] = value
        setOrderData(tempData)
    }

    const updateFoodItems = (selectedOption, index) => {
        setOrderData(prevState => {
            const newFoodItems = [...prevState.food];
            let tempquantity = newFoodItems[index].quantity === "" || newFoodItems[index].quantity === 0 ? 1 : (isNaN(newFoodItems[index].quantity) ? 1 : Math.abs(newFoodItems[index].quantity))
            tempquantity = isNaN(tempquantity) ? 1 : tempquantity
            newFoodItems[index].name = selectedOption.value;
            let price = invData.find(item => item.productid === selectedOption.value)
            newFoodItems[index].price = (Number(price.totalprice)/ Number(price.quantity)) * Number(tempquantity);
            return {
                ...prevState,
                food: newFoodItems
            };
        });
    };


    const updateFoodprice = (e, index) => {
        setOrderData(prevState => {
            const newFoodItems = [...prevState.food];
            const inputValue = e.target.value;
            const tempquantity = inputValue === "" || Number(inputValue) === 0 ? 1 : (isNaN(inputValue) ? 1 : inputValue)
            const parsedQuantity =  tempquantity;
            if(inputValue!=='food' && inputValue!=='beverage' ){
                newFoodItems[index].quantity = isNaN(e.target.value) ? 1 : e.target.value
                let price = invData.find(item => item.productid === newFoodItems[index].name)
                newFoodItems[index].price = Number(parsedQuantity) * (Number(price.totalprice) / Number(price.quantity))
            }
            else{
                newFoodItems[index].type = inputValue
            }
            return {
                ...prevState,
                food: newFoodItems
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        try {
            const response = await axios.post(process.env.REACT_APP_KC_API_URL + 'rebellion/reborders', orderData, config);
            history.push('/offlineorders');
        } catch (error) {
            console.error('Error creating order:', error);
        }
    };

    const foodOptions = (index) => {
        if (!KcProdData) return [];
    
        return invData.filter(item => orderData.food[index].type === item.collection && orderData.food[index].foodtype === item.type)
            .map(item => (
                {
                
                value: item.productid,
                label: KcProdData[orderData.food?.[index]?.type]?.find(prod => prod.productid === item.productid)?.title || 'Unknown'
            }));
    };
    

    return (
        <div className="kuro-po txt-light">
            <h2>Order</h2>
                <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Name</th>
                        <td>
                            <input
                                name="name"
                                value={orderData.user.name}
                                onChange={updateOrderData}
                                placeholder="Enter name"
                            />
                            {showDropdown && (
                                <div className="autocomplete-dropdown">
                                    {filteredUsers.map((user, index) => (
                                        <div
                                            key={index}
                                            className="dropdown-item"
                                            onClick={() => handleUserSelect(user)}
                                        >
                                            {user.name} - {user.phone}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Phone</th>
                        <td>
                            <input
                                name="phone"
                                value={orderData.user.phone}
                                onChange={updateOrderData}
                                placeholder="Enter phone"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Total Price</th>
                        <td>{orderData.totalprice}</td>
                    </tr>
                </thead>
                </table>
                <h2>Order Items <span className="prod-span" onClick={() => addingOrderItem()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></h2>
                {orderData.service.length > 0 && (
                    <>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Package</th>
                                    <th>Validity</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>ADD</th>
                                    <th>REMOVE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.service.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={item.type} onChange={e => updateOrderitems(e, index) } name="type">
                                                <option value="">Select Type</option>
                                                { packagesData && packagesData.map(
                                                    (packageItem, index) => (
                                                        <option key={index} value={packageItem.type}>{packageItem.title}</option>
                                                    )
                                                )
                                                }
                                            </select>
                                        </td>
                                        <td>
                                            {
                                                packagesData && packagesData.map((pac, i) => {
                                                    if (pac.type === item.type) {
                                                        return (
                                                            <select
                                                                key={i}
                                                                value={item.presetid}
                                                                onChange={e => updateOrderitems(e, index)}
                                                                name='presetid'
                                                            >
                                                                <option value="">Select Package</option>
                                                                {
                                                                  pac.list.map((item, index) => (
                                                                    <option key={index} value={item.presetid}>
                                                                        {item.title} 
                                                                    </option>
                                                                ) )}
                                                            </select>
                                                        );
                                                    }
                                                    return null; 
                                                })
                                            }
                                        </td>
                                        <td>{item.validity}</td>
                                        <td><input value={item.quantity} onChange={e => updateOrderitems(e, index)} name='quantity' /></td>
                                        <td>{item.price}</td>
                                        <td><span className="prod-span" onClick={() => addingOrderItem(index)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                        <td><span className="prod-span" onClick={() => removingOrderItem(index)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </>
                        )}
                        <h2>Food Items <span className="prod-span" onClick={() => addingFoodItem()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></h2>
                        {orderData.food.length > 0 && (
                        <>
                        <table className="even_odd border txt-light-grey table_mob" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Food/Beverage</th>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>ADD</th>
                                    <th>REMOVE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.food.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={item.type} onChange={(e) => updateFoodprice(e, index)} name='type'>
                                                <option value="">Select Type</option>
                                                <option value="food">Food</option>
                                                <option value="beverage">Beverage</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={item.foodtype} onChange={(e) => updatefoodtype(index, e)} name='foodtype'>
                                                <option value="">Select Food Type</option>
                                            {
                                                    item.type === "food" && (
                                                        <>
                                                            <option value="French Fries">French Fries</option>
                                                            <option value="Samosa">Samosa</option>
                                                            <option value="Cheesy Shots">Cheesy Shots</option>
                                                            <option value="Potato Shots">Potato Shots</option>
                                                            <option value="Onion Rings">Onion Rings</option>
                                                            <option value="Paneer Roll">Paneer Roll</option>
                                                            <option value="Noodles">Noodles</option>
                                                            <option value="Chips">Chips</option>
                                                            <option value="Potato Wedges">Potato Wedges</option>
                                                            <option value="Nachos">Nachos</option>
                                                            <option value="Chocolates">Chocolates</option></>)}
                                                {
                                                    item.type === "beverage" && (<>
                                                        <option value="Small (Loose)">Small (Loose)</option>
                                                        <option value="Medium">Medium</option>
                                                        <option value="Large">Large</option>
                                                        <option value="Tin">Tin</option>
                                                            </>)

                                                }
                                            </select>
                                        </td>
                                        <td>
                                            <Select 
                                                classNamePrefix="kuro-search-select" 
                                                options={foodOptions(index)} 
                                                value={foodOptions(index).find(option => option.label === item.title)} 
                                                onChange={(selectedOption) => updateFoodItems(selectedOption, index)} 
                                                placeholder="Select Food Item" 
                                                className="react-select-container" 
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={(e) => updateFoodprice(e, index)}
                                            />
                                        </td>
                                        <td>{item.price}</td>
                                        <td><span className="prod-span" onClick={() => addingFoodItem(index)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></td>
                                        <td><span className="prod-span" onClick={() => removingFoodItem(index)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table></> )}
                        <button onClick={handleSubmit}>Create Order</button>
                
        </div>
    );
};
const mapStateToProps = (state) => ({
    user: state.user,
    admin: state.admin
});

export default connect(mapStateToProps)(CreateOrder);