import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import KuroLink from '../components/common/KuroLink'
import moment from 'moment-timezone'
import '../styles/estimate.css'
import '../styles/react-select.css'

moment.tz.setDefault('Asia/Kolkata')

const NPSEstimate = ({ match, user: { token }, products: { compData, monitorData, accessData, networkData, extDeviceData, presetData }, admin: { provinces } }) => {

    const { params: { estimate_no, version } } = match
    const [estimateData, setestimateData] = useState(null)
    const [estimateInfo, setestimateInfo] = useState(null)
    const [viewFlag, setviewFlag] = useState("default")
    const [compMakers, setcompMakers] = useState([])
    const [monitorMakers, setmonitorMakers] = useState([])
    const [accessMakers, setaccessMakers] = useState([])
    const [networkMakers, setnetworkMakers] = useState([])
    const [extMakers, setextMakers] = useState([])
    const [showprice, setshowprice] = useState(true)

    const selectedProd = useRef()

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let url = process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no

        if (version) {
            url += '&version=' + version
        }

        axios(url, config).then(res => {
            setestimateInfo(res.data[0])
            setestimateData(res.data[0])
        })
    }, [estimate_no])

    useEffect(() => {
        if (compData != null) {
            setcompMakers([...new Set(compData.map(item => item.maker))])
        }
        if (monitorData != null) {
            setmonitorMakers([...new Set(monitorData.map(item => item.maker))])
        }
        if (accessData != null) {
            setaccessMakers([...new Set(accessData.map(item => item.maker))])
        }
        if (networkData != null) {
            setnetworkMakers([...new Set(networkData.map(item => item.maker))])
        }
        if (extDeviceData != null) {
            setextMakers([...new Set(extDeviceData.map(item => item.maker))])
        }
    }, [compData, monitorData, accessData, networkData, extDeviceData])

    useEffect(() => {
        if (estimateInfo !== null) {
            let tempestimate = { ...estimateInfo }
            tempestimate = buildTotal(tempestimate)
            setestimateInfo(tempestimate)
        }
    }, [estimateData])

    const round = (type, value, decimals = 0, even = false) => {
        value = value.toString().split('e')[0]
        value *= Math.pow(10, decimals)
        value = Math[type](value)
        if (even && value % 2 === 1) {
            value += 1
        }
        value /= Math.pow(10, decimals)
        return value
    }

    const updateroundoff = (tempestimate) => {
        if (tempestimate.billadd.state === "Telangana") {
            if (Math.abs(tempestimate.total - 2 * tempestimate.cgst - tempestimate.totalpricebgst) >= 0.01) {
                tempestimate.roundoff = round("round", tempestimate.total - 2 * tempestimate.cgst - tempestimate.totalpricebgst, 2)
            } else {
                tempestimate.roundoff = 0
            }
        } else {
            if (Math.abs(tempestimate.total - tempestimate.gst - tempestimate.totalpricebgst) >= 0.01) {
                tempestimate.roundoff = round("round", tempestimate.total - tempestimate.gst - tempestimate.totalpricebgst, 2)
            } else {
                tempestimate.roundoff = 0
            }
        }
        return tempestimate
    }

    const updateTotal = (tempestimate) => {
        let totalprice = 0
        let totalpricebgst = 0
        let gst = 0
        let cgst = 0
        for (let i = 0; i < tempestimate.builds.length; i++) {
            totalprice += tempestimate.builds[i].totalprice
        }
        for (let i = 0; i < tempestimate.products.length; i++) {
            totalprice += tempestimate.products[i].totalprice
        }
        totalpricebgst = round("round", totalprice / 1.18, 2)
        gst = round("round", (totalpricebgst * 0.18), 2)
        cgst = round("round", (totalpricebgst * 0.09), 2)
        tempestimate.total = totalprice
        tempestimate.totalpricebgst = totalpricebgst
        tempestimate.gst = gst
        tempestimate.cgst = cgst

        tempestimate = updateroundoff(tempestimate)
        return tempestimate
    }

    const buildTotal = (tempestimate) => {
        if (tempestimate.builds.length > 0 && tempestimate.builds[0].inv_price) {
            let subtotal = 0
            subtotal = tempestimate.builds[0].inv_price.cpu_price + tempestimate.builds[0].inv_price.mob_price + tempestimate.builds[0].inv_price.gpu_price + tempestimate.builds[0].inv_price.ram_price + tempestimate.builds[0].inv_price.psu_price + tempestimate.builds[0].inv_price.ssd_price + tempestimate.builds[0].inv_price.hdd_price + tempestimate.builds[0].inv_price.cooler_price + tempestimate.builds[0].inv_price.tower_price + tempestimate.builds[0].inv_price.fans_price + tempestimate.builds[0].inv_price.wifi_price + tempestimate.builds[0].inv_price.os_price + tempestimate.builds[0].inv_price.shp_fees + tempestimate.builds[0].inv_price.build_fees
            tempestimate.builds[0].subtotal = subtotal
            let price = tempestimate.builds[0].price
            let tax = round("round", (price / 1.18) * 0.18, 2)
            let quantity = tempestimate.builds[0].quantity
            let totalprice = price * quantity
            let totaltax = round("round", (totalprice / 1.18) * 0.18, 2)
            tempestimate.builds[0].pricebgst = round("round", (price / 1.18), 2)
            tempestimate.builds[0].tax = tax
            tempestimate.builds[0].totalprice = totalprice
            tempestimate.builds[0].totalpricebgst = round("round", (totalprice / 1.18), 2)
            tempestimate.builds[0].totaltax = totaltax
            tempestimate = updateTotal(tempestimate)
        }
        return tempestimate
    }

    const updateviewFlag = (flag) => {
        let tempestimate = { ...estimateData }
        if (flag !== "edit") {
            setestimateInfo(tempestimate)
        }
        setviewFlag(flag)
    }

    const updateaddressflag = (value) => {
        let tempestimate = { ...estimateInfo }
        tempestimate.addressflag = value
        if (value) {
            delete tempestimate["shpadd"]
        } else {
            tempestimate.shpadd = { 'company': '', 'name': '', 'phone': '', 'addressline1': '', 'addressline2': '', 'city': '', 'pincode': '', 'state': '', 'gstin': '', 'pan': '' }
        }
        setestimateInfo(tempestimate)
    }

    const updateaddress = (e, key, subkey, num = false) => {
        let tempestimate = { ...estimateInfo }
        tempestimate[key][subkey] = num ? Number(e.target.value) : e.target.value
        if (key === "state") {
            tempestimate = updateroundoff(tempestimate)
        }
        setestimateInfo(tempestimate)
    }

    const addprod = () => {
        let tempestimate = { ...estimateInfo }
        tempestimate.products.push({ 'collection': '', 'type': '', 'maker': '', 'productid': '', 'title': '', 'quantity': 1, 'price': 0, 'totalpricebgst': 0, 'totaltax': 0, 'totalprice': 0 })
        setestimateInfo(tempestimate)
    }

    const removeprod = (index) => {
        let tempestimate = { ...estimateInfo }
        tempestimate.products = tempestimate.products.filter((_, i) => i !== index)
        tempestimate = updateTotal(tempestimate)
        setestimateInfo(tempestimate)
    }

    const updateitem = (index, key, e, num = false) => {
        let tempestimate = { ...estimateInfo }
        if (key === "collection" || key === "maker" || key === "type") {
            selectedProd.current.setValue('')
        }
        if (key === "price" || key === "quantity") {
            let price = 0
            let quantity = 1
            if (key === "price") {
                price = Number(e.target.value)
                quantity = tempestimate.products[index].quantity
            } else {
                quantity = Number(e.target.value)
                price = tempestimate.products[index].price
            }
            const tax = round("round", (price / 1.18) * 0.18, 2)
            const totalprice = price * quantity
            const totaltax = round("round", (totalprice / 1.18) * 0.18, 2)
            tempestimate.products[index].price = price
            tempestimate.products[index].pricebgst = round("round", price - tax, 2)
            tempestimate.products[index].totalprice = totalprice
            tempestimate.products[index].totaltax = totaltax
            tempestimate.products[index].totalpricebgst = round("round", totalprice - totaltax, 2)
        } else if (key !== "title") {
            tempestimate.products[index].productid = ""
            tempestimate.products[index].title = ""
        }
        tempestimate.products[index][key] = num ? Number(e.target.value) : e.target.value
        tempestimate = updateTotal(tempestimate)
        setestimateInfo(tempestimate)
    }

    const updateprod = (index, e, collection) => {
        const prodid = e.value
        let tempestimate = { ...estimateInfo }
        tempestimate.products[index].productid = prodid
        let collData = compData
        if (collection === "monitors") {
            collData = monitorData
        } else if (collection === "accessories") {
            collData = accessData
        } else if (collection === "networking") {
            collData = networkData
        } else if (collection === "external") {
            collData = extDeviceData
        }
        if (e) {
            tempestimate.products[index].title = (prodid === "other") ? "" : collData.filter(item => item.productid === prodid)[0].title
            const quantity = tempestimate.products[index].quantity
            const price = (prodid === "other") ? 0 : collData.filter(item => item.productid === prodid)[0].price.kuro_on
            const totalprice = price * quantity
            const tax = round("ceil", (price / 1.18) * 0.18, 2)
            const totaltax = round("ceil", (totalprice / 1.18) * 0.18, 2)
            tempestimate.products[index].price = price
            tempestimate.products[index].totalprice = totalprice
            tempestimate.products[index].totaltax = totaltax
            tempestimate.products[index].pricebgst = round("round", price - tax, 2)
            tempestimate.products[index].totalpricebgst = round("round", totalprice - totaltax, 2)
            tempestimate = updateTotal(tempestimate)
        } else {
            tempestimate.products[index].title = ''
            tempestimate.products[index].productid = ''
            tempestimate.products[index].price = 0
            tempestimate.products[index].totalprice = 0
            tempestimate.products[index].pricebgst = 0
            tempestimate.products[index].totalpricebgst = 0
            tempestimate = updateTotal(tempestimate)
        }
        setestimateInfo(tempestimate)
    }

    const updateuser = (e, key) => {
        let tempestimate = { ...estimateInfo }
        let value = e.target.value
        if (key === "phone") {
            value = value.replaceAll("+91", "").replaceAll(/\D/g, "")
        }
        tempestimate.user[key] = value
        setestimateInfo(tempestimate)
    }

    const updatebuild = (e, key, subkey = "", num = false) => {
        let tempestimate = { ...estimateInfo }
        if (key === "inv_price") {
            tempestimate.builds[0][key][subkey] = num ? Number(e.target.value) : e.target.value
        } else {
            tempestimate.builds[0][key] = num ? Number(e.target.value) : e.target.value
        }
        tempestimate = buildTotal(tempestimate)
        if (key === "price") {
            tempestimate = updateTotal(tempestimate)
        }
        setestimateInfo(tempestimate)
    }

    const prodOptions = (prod) => {
        const tempArray = []
        if (prod.collection === 'monitors') {
            monitorData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'components') {
            compData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'accessories') {
            accessData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'networking') {
            networkData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        if (prod.collection === 'external') {
            extDeviceData.filter(item => !prod.maker ? item.type === prod.type : item.type === prod.type && item.maker === prod.maker).map(item => {
                return (
                    tempArray.push({ value: item.productid, label: item.title })
                )
            })
        }
        tempArray.push({ value: 'other', label: 'other' })
        return tempArray
    }

    const updateEstimate = (version) => {
        let estData = estimateInfo
        if (estData.user.state !== "") {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
            }

            if (estData.user.state === "Telangana") {
                estData.roundoff = round("round", estData.total - 2 * estData.cgst - estData.totalpricebgst, 2)
            } else {
                estData.roundoff = round("round", estData.total - estData.gst - estData.totalpricebgst, 2)
            }

            axios.post(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + estimate_no + '&version=' + version, estData, config)
                .then((res) => {
                    setestimateData(res.data[0])
                    updateviewFlag("preview")
                })
        }
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    var fileDownload = require('js-file-download')

    const downloadestimate = () => {

        const est_no = estimateInfo["estimate_no"]
        const version = estimateInfo["version"]

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios(process.env.REACT_APP_KC_API_URL + 'kuroadmin/estimates?estimate_no=' + est_no + "&version=" + version + "&download=true", config)
            .then((res) => {
                fileDownload(res.data, est_no + '.pdf')
            })
    }

    return (
        <div className="estimate txt-light-grey">
            {viewFlag === "default" &&
                <div className="estimate_details">
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr >
                                <td>Estimate No</td>
                                <td>Estimate Date</td>
                                <td>Customer Name</td>
                                <td>Mobile Number</td>
                                <td>Estimate Total</td>
                            </tr>
                            {estimateInfo !== null &&
                                <tr>
                                    <td>{estimateInfo.estimate_no}</td>
                                    <td>{moment(estimateInfo.estimate_date).format('DD-MM-YYYY')}</td>
                                    <td>{estimateInfo.user.name}</td>
                                    <td>{estimateInfo.user.phone}</td>
                                    <td>{estimateInfo.total}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        {estimateInfo !== null &&
                            <tbody>
                                <tr>
                                    <td>Customer</td>
                                    {estimateInfo.notes.customer === "" ? (
                                        <td>No Interaction</td>
                                    ) : (
                                        <td>{estimateInfo.notes.customer}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td>Admin</td>
                                    {estimateInfo.notes.admin === "" ? (
                                        <td>No Interaction</td>
                                    ) : (
                                        <td>{estimateInfo.notes.admin}</td>
                                    )}
                                </tr>
                            </tbody>
                        }
                    </table>
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        {estimateInfo !== null &&
                            <tbody>
                                <tr>
                                    <td>Billing Address</td>
                                </tr>
                                <tr>
                                    <td>
                                        {estimateInfo.billadd.company &&
                                            <>
                                                {estimateInfo.billadd.company}<br />
                                            </>
                                        }
                                        {estimateInfo.billadd.name &&
                                            <>
                                                {estimateInfo.billadd.name}<br />
                                            </>
                                        }
                                        {estimateInfo.billadd.phone &&
                                            <>
                                                {estimateInfo.billadd.phone}<br />
                                            </>}
                                        {estimateInfo.billadd.addressline1 &&
                                            <>
                                                {estimateInfo.billadd.addressline1}, &nbsp;
                                            </>
                                        }
                                        {estimateInfo.billadd.addressline2 &&
                                            <>
                                                {estimateInfo.billadd.addressline2}<br />
                                            </>
                                        }
                                        {estimateInfo.billadd.city &&
                                            <>
                                                {estimateInfo.billadd.city}<br />
                                            </>
                                        }
                                        {estimateInfo.billadd.state &&
                                            <>
                                                {estimateInfo.billadd.state} &nbsp;   &nbsp;
                                            </>
                                        }
                                        {estimateInfo.billadd.pincode &&
                                            <>
                                                {estimateInfo.billadd.pincode}<br />
                                            </>
                                        }
                                        {estimateInfo.billadd.gstin &&
                                            <>
                                                {estimateInfo.billadd.gstin}<br />
                                            </>}
                                        {estimateInfo.billadd.pan &&
                                            <>
                                                {estimateInfo.billadd.pan}<br />
                                            </>
                                        }
                                    </td>
                                </tr>
                                {!estimateInfo.addressflag &&
                                    <>
                                        <th colSpan={2}>Shipping Address</th>
                                        <tr>
                                            <td>Company Name</td>
                                            <td>{estimateInfo.shpadd.company}</td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>{estimateInfo.shpadd.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>{estimateInfo.shpadd.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Address Line1, Address Line2</td>
                                            <td>{estimateInfo.shpadd.addressline1}&nbsp;{estimateInfo.shpadd.addressline2}</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>{estimateInfo.shpadd.city}</td>
                                        </tr>
                                        <tr>
                                            <td>Pincode</td>
                                            <td>{estimateInfo.shpadd.pincode}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>{estimateInfo.shpadd.state}</td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN</td>
                                            <td>{estimateInfo.shpadd.gstin}</td>
                                        </tr>
                                        <tr>
                                            <td>PAN</td>
                                            <td>{estimateInfo.shpadd.pan}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        }
                    </table>
                    {estimateInfo !== null &&
                        <div className='builds'>
                            {estimateInfo.builds.map((build, j) =>
                                <div key={j} className="build-wrap">
                                    <h3>Builds</h3>
                                    <div className='prod-list'>
                                        <table className="border prod_details even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>Kuro Price</td>
                                                    <td>Quantity</td>
                                                    <td>Build Type</td>
                                                </tr>
                                                <tr>
                                                    <td>{build.title}</td>
                                                    <td>{build.price}</td>
                                                    <td>{build.quantity}</td>
                                                    <td>{build.buildType}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {build.inv_price && <table className="inventory border even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="8">Component Prices</td>
                                                </tr>
                                                <tr>
                                                    <td>Processor</td>
                                                    <td>{build.inv_price.cpu_title}</td>
                                                    <td>{build.inv_price && build.inv_price.cpu_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Motherboard</td>
                                                    <td>{build.inv_price.mob_title}</td>
                                                    <td>{build.inv_price && build.inv_price.mob_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>RAM</td>
                                                    <td>{build.inv_price.ram_title}</td>
                                                    <td>{build.inv_price && build.inv_price.ram_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Graphics Card</td>
                                                    <td>{build.inv_price.gpu_title}</td>
                                                    <td>{build.inv_price && build.inv_price.gpu_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>SSD</td>
                                                    <td>{build.inv_price.ssd_title}</td>
                                                    <td>{build.inv_price && build.inv_price.ssd_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>HDD</td>
                                                    <td>{build.inv_price.hdd_title}</td>
                                                    <td>{build.inv_price.hdd_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Power Supply Unit</td>
                                                    <td>{build.inv_price.psu_title}</td>
                                                    <td>{build.inv_price.psu_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cooler</td>
                                                    <td>{build.inv_price.cooler_title}</td>
                                                    <td>{build.inv_price.cooler_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Case</td>
                                                    <td>{build.inv_price.tower_title}</td>
                                                    <td>{build.inv_price.tower_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Fans</td>
                                                    <td>{build.inv_price.fans_title}</td>
                                                    <td>{build.inv_price.fans_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>WiFi</td>
                                                    <td>{build.inv_price.wifi_title}</td>
                                                    <td>{build.inv_price.wifi_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Operating System</td>
                                                    <td>{build.inv_price.os_title}</td>
                                                    <td>{build.inv_price.os_price}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Shipping Charges</td>
                                                    <td>{build.inv_price.shp_fees}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Build Fees</td>
                                                    <td>{build.inv_price.build_fees}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Taxable Amount</td>
                                                    <td>{build.pricebgst}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Total Tax</td>
                                                    <td>{build.totaltax}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Sub Total</td>
                                                    <td>{build.subtotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Kuro Margin(%)</td>
                                                    <td>{((build.price - build.subtotal) * 100 / build.subtotal).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Kuro Margin(Rs)</td>
                                                    <td>{build.price - build.subtotal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Kuro Margin Pre-GST(Rs)</td>
                                                    <td>{((build.price - build.subtotal) / 1.18).toFixed()}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2">Kuro Total</td>
                                                    <td>{build.price}</td>
                                                </tr>
                                            </tbody>
                                        </table>}
                                    </div>
                                </div>
                            )}
                            {estimateInfo.products.length > 0 &&
                                <div className="products">
                                    <h3>Peripherals</h3>
                                    <div className='prod-list'>
                                        <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>Product Id</td>
                                                    <td>Title</td>
                                                    <td>Price</td>
                                                    <td>Quantity</td>
                                                    <td>Total Price</td>
                                                </tr>
                                                {estimateInfo.products.map((prod, j) =>
                                                    <tr key={j}>
                                                        <td>{prod.productid}</td>
                                                        <td>{prod.title}</td>
                                                        <td>{prod.price}</td>
                                                        <td>{prod.quantity}</td>
                                                        <td>{prod.totalprice}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            <table className='border even_odd'>
                                <tbody>
                                    <tr>
                                        <td>Taxable Amount</td>
                                        <td>{estimateInfo.totalpricebgst}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Tax</td>
                                        <td>{estimateInfo.gst}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Price</td>
                                        <td>{estimateInfo.total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    <ul className='btns btn_container'>
                        <li>
                            <button onClick={downloadestimate}>Download Estimate</button>
                        </li>
                        <li>
                            <button onClick={() => updateviewFlag("edit")}>Edit Estimate</button>
                        </li>
                        <li>
                            <button onClick={() => updateviewFlag("preview")}>Preview Estimate</button>
                        </li>
                        <li>
                            <KuroLink to="/estimates"><button>Back</button></KuroLink>
                        </li>
                    </ul>
                </div>
            }
            {viewFlag === "preview" &&
                <div className="estimate_details wide_table">
                    <h2>Preview</h2>
                    <table className="bg_black ph_20" cellPadding="0" cellSpacing="0">
                        <tbody>
                            {estimateInfo !== null &&
                                <tr>
                                    <td className='txt-center'>
                                        <svg className='logo' viewBox="200 250 1300 700"><title>Kuro Gaming</title><polygon fill="#ee2d30" points="850.68 688.03 885.99 611.01 1180.3 611.01 1264.86 556.53 838.61 553.03 850.68 688.03" /><polygon fill="#ee2d30" points="433.12 715.79 274.77 541.21 753.1 54.3 1029.11 54.3 549.72 541.21 706.98 712.95 433.12 715.79" /><polygon fill="#ee2d30" points="809.78 831.25 850.75 879 1099.35 563.66 850.68 563.95 850.68 688.03 756.3 541.21 1426.01 541.21 850.75 1194.56 532.77 830.61 809.78 831.25" /></svg>
                                    </td>
                                    <td>Name: &nbsp;&nbsp;<span className='txt-light'>{estimateInfo.user.name}</span><br />
                                        Mobile: <span className='txt-light'>{estimateInfo.user.phone}</span></td>
                                    <td className='txt-right'>Estimate No: {estimateInfo.estimate_no}<br />
                                        Estimate Date: {moment(estimateInfo.estimate_date).format('DD-MM-YYYY')}<br />
                                        {estimateInfo.validity !== "" &&
                                            <>
                                                Validity Upto: {moment(estimateInfo.validity).format('DD-MM-YYYY')}
                                            </>
                                        }
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <table className="even_odd border" cellSpacing="0" cellPadding="0">
                        <tr>
                            <th colspan="6">Product Description</th>
                            <th>QTY</th>
                            <th colspan="3">Unit Price</th>
                            <th colspan="3">Total Amount</th>
                        </tr>
                        {estimateInfo.builds.map((build, i) =>
                            <tr key={i}>
                                <td colspan="6">
                                    {build.buildType === "custom" ? (
                                        <>
                                            <b>{build.title}</b><br />
                                            <span className='build_comps'>
                                                {build.inv_price.cpu_title !== "" &&
                                                    <>Processor: {build.inv_price.cpu_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.mob_title !== "" &&
                                                    <>Motherboard: {build.inv_price.mob_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.ram_title !== "" &&
                                                    <>RAM: {build.inv_price.ram_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.gpu_title !== "" &&
                                                    <>Graphics Card: {build.inv_price.gpu_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.ssd_title !== "" &&
                                                    <>SSD: {build.inv_price.ssd_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.hdd_title !== "" &&
                                                    <>HDD: {build.inv_price.hdd_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.psu_title !== "" &&
                                                    <>Power Supply: {build.inv_price.psu_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.cooler_title !== "" &&
                                                    <>CPU Cooler: {build.inv_price.cooler_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.tower_title !== "" &&
                                                    <>Case: {build.inv_price.tower_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.fans_title !== "" &&
                                                    <>Fans: {build.inv_price.fans_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.wifi_title !== "" &&
                                                    <>WiFi: {build.inv_price.wifi_title}
                                                        <br />
                                                    </>
                                                }
                                                {build.inv_price.os_title !== "" &&
                                                    <>Operating System: {build.inv_price.os_title}
                                                    </>
                                                }
                                            </span>
                                        </>
                                    ) : (
                                        build.title
                                    )}
                                </td>
                                <td>{build.quantity}</td>
                                <td colspan="3">{build.price}</td>
                                <td className="text_right" colspan="3">{build.totalprice}</td>
                            </tr>
                        )}
                        {estimateInfo.products.map((prod, i) =>
                            <tr key={i}>
                                <td colspan="6">{prod.title}</td>
                                <td>{prod.quantity}</td>
                                <td colspan="3">{prod.price}</td>
                                <td className="text_right" colspan="3">{prod.totalprice}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="text_right" colspan="10"><b>Total</b></td>
                            <td className="text_right" colspan="3"><b>Rs.{estimateInfo.total}</b></td>
                        </tr>
                        <tr>
                            <td className="text_right" colspan="10">
                                Tax Summary<br />
                                {estimateInfo.billadd.state === "Telangana" ? (
                                    <>
                                        CGST @ 9%<br />SGST @ 9%
                                    </>
                                ) : (
                                    "IGST @ 18%"
                                )}
                            </td>
                            <td className="text_right" colspan="3">
                                &nbsp;<br />
                                {estimateInfo.billadd.state === "Telangana" ? (
                                    <>
                                        Rs.{estimateInfo.cgst}<br />
                                        Rs.{estimateInfo.cgst}
                                    </>
                                ) : (
                                    estimateInfo.gst
                                )}
                            </td>
                        </tr>
                    </table>
                    <ul className='btns'>
                        <li>
                            <button onClick={() => updateviewFlag("default")}>Back</button>
                        </li>
                        <li>
                            <button onClick={downloadestimate}>Download</button>
                        </li>
                    </ul>
                </div>
            }
            {viewFlag === "edit" &&
                <div className="edit-estimate">
                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Estimate No</td>
                                <td>Estimate Date</td>
                                <td>Customer Name</td>
                                <td>Mobile Number</td>
                                <td>Estimate Total</td>
                            </tr>
                            {estimateInfo !== null &&
                                <tr>
                                    <td>{estimateInfo.estimate_no}</td>
                                    <td>{moment(estimateInfo.estimate_date).format('DD-MM-YYYY')}</td>
                                    <td><input value={estimateInfo.user.name} onChange={(e) => updateuser(e, "name")} /></td>
                                    <td><input value={estimateInfo.user.phone} onChange={(e) => updateuser(e, "phone")} /></td>
                                    <td>{estimateInfo.total}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div>
                        <table className="border even_odd" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        Shipping Address same as Billing Address
                                        <input type="checkbox" className='checkbox' onChange={(e) => updateaddressflag(!estimateInfo.addressflag)} checked={estimateInfo.addressflag} />
                                    </td>
                                </tr>
                                <th colSpan={2}>Billing Address</th>
                                <tr>
                                    <td >Company</td>
                                    <td><input value={estimateInfo.billadd.company} onChange={(e) => updateaddress(e, "billadd", "company")} /></td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td><input value={estimateInfo.billadd.name} onChange={(e) => updateaddress(e, "billadd", "name")} /></td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td><input value={estimateInfo.billadd.phone} onChange={(e) => updateaddress(e, "billadd", "phone")} /></td>
                                </tr>
                                <tr>
                                    <td>Address Line1</td>
                                    <td><input value={estimateInfo.billadd.addressline1} onChange={(e) => updateaddress(e, "billadd", "addressline1")} /></td>
                                </tr>
                                <tr>
                                    <td>Address Line2</td>
                                    <td><input value={estimateInfo.billadd.addressline2} onChange={(e) => updateaddress(e, "billadd", "addressline2")} /></td>
                                </tr>
                                <tr>
                                    <td>Pincode</td>
                                    <td><input value={estimateInfo.billadd.pincode} onChange={(e) => updateaddress(e, "billadd", "pincode")} /></td>
                                </tr>
                                <tr>
                                    <td>City</td>
                                    <td><input value={estimateInfo.billadd.city} onChange={(e) => updateaddress(e, "billadd", "city")} /></td>
                                </tr>
                                <tr>
                                    <td>State</td>
                                    <td>
                                        <select className="state" value={estimateInfo.billadd.state} onChange={(e) => updateaddress(e, "billadd", "state")}>
                                            <option value="">Select State</option>
                                            {provinces.map((province, i) =>
                                                <option key={i} value={province["state"]}>{province["state"]}</option>
                                            )}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>GSTIN</td>
                                    <td><input value={estimateInfo.billadd.gstin} onChange={(e) => updateaddress(e, "billadd", "gstin")} /></td>
                                </tr>
                                <tr>
                                    <td>PAN</td>
                                    <td><input value={estimateInfo.billadd.pan} onChange={(e) => updateaddress(e, "billadd", "pan")} /></td>
                                </tr>
                                {!estimateInfo.addressflag &&
                                    <>
                                        <th colSpan={2}>Shipping Address</th>
                                        <tr>
                                            <td>Company Name</td>
                                            <td><input value={estimateInfo.shpadd.company} onChange={(e) => updateaddress(e, "shpadd", "company")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td><input value={estimateInfo.shpadd.name} onChange={(e) => updateaddress(e, "shpadd", "name")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td><input value={estimateInfo.shpadd.phone} onChange={(e) => updateaddress(e, "shpadd", "phone")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Address Line1</td>
                                            <td><input value={estimateInfo.shpadd.addressline1} onChange={(e) => updateaddress(e, "shpadd", "addressline1")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Address Line2</td>
                                            <td><input value={estimateInfo.shpadd.addressline2} onChange={(e) => updateaddress(e, "shpadd", "addressline2")} /></td>
                                        </tr>
                                        <tr>
                                            <td>Pincode</td>
                                            <td><input value={estimateInfo.shpadd.pincode} onChange={(e) => updateaddress(e, "shpadd", "pincode")} /></td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td><input value={estimateInfo.shpadd.city} onChange={(e) => updateaddress(e, "shpadd", "city")} /></td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>
                                                <select className="state" value={estimateInfo.billadd.state} onChange={(e) => updateaddress(e, "shpadd", "state")}>
                                                    <option value="">Select State</option>
                                                    {provinces.map((province, i) =>
                                                        <option key={i} value={province["state"]}>{province["state"]}</option>
                                                    )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN</td>
                                            <td><input value={estimateInfo.shpadd.gstin} onChange={(e) => updateaddress(e, "shpadd", "gstin")} /></td>
                                        </tr>
                                        <tr>
                                            <td>PAN</td>
                                            <td><input value={estimateInfo.shpadd.pan} onChange={(e) => updateaddress(e, "shpadd", "pan")} /></td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                    </div>
                    {estimateInfo !== null &&
                        <div className='builds-prod'>
                            {estimateInfo.builds.map((build, j) =>
                                <div key={j} className="build-wrap">
                                    <h3>Builds</h3>
                                    <div className='prod-list'>
                                        <div className='view_price'>
                                            <span style={{ color: "#f00", fontWeight: "400", marginRight: "-25px" }}>Restricted View</span><input type="checkbox" className='checkbox' onClick={() => setshowprice(!showprice)} />
                                            <p>&nbsp;</p>
                                        </div>
                                        <table className="border prod_details even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>Kuro Price</td>
                                                    <td>Quantity</td>
                                                    <td>Build Type</td>
                                                </tr>
                                                <tr>
                                                    <td><textarea value={build.title} onChange={(e) => updatebuild(e, "title")} /></td>
                                                    <td>{build.price}</td>
                                                    <td><input value={build.quantity} onChange={(e) => updatebuild(e, "quantity")} /></td>
                                                    <td rowSpan="2">
                                                        <input type="radio" name="buildtype" onChange={(e) => updatebuild(e, "buildType")} value="prebuilt" checked={build.buildType === "prebuilt" ? true : false} />
                                                        <label>Pre-Built</label><br />
                                                        <input type="radio" name="buildtype" onChange={(e) => updatebuild(e, "buildType")} value="custom" checked={build.buildType === "custom" ? true : false} />
                                                        <label>Custom Build</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className="border inventory even_odd" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6">Components</td>
                                                </tr>
                                                <tr>
                                                    <td>Processor</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "cpu_title")} value={build.inv_price.cpu_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "cpu_price", true)} value={build.inv_price.cpu_price} /></td>}
                                                    <td>Graphics Card</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "gpu_title")} value={build.inv_price.gpu_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "gpu_price", true)} value={build.inv_price.gpu_price} /></td>}
                                                </tr>
                                                <tr>
                                                    <td>Motherboard</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "mob_title")} value={build.inv_price.mob_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "mob_price", true)} value={build.inv_price.mob_price} /></td>}
                                                    <td>SSD</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "ssd_title")} value={build.inv_price.ssd_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "ssd_price", true)} value={build.inv_price.ssd_price} /></td>}
                                                </tr>
                                                <tr>
                                                    <td>RAM</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "ram_title")} value={build.inv_price.ram_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "ram_price", true)} value={build.inv_price.ram_price} /></td>}
                                                    <td>HDD</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "hdd_title")} value={build.inv_price.hdd_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "hdd_price", true)} value={build.inv_price.hdd_price} /></td>}
                                                </tr>
                                                <tr>
                                                    <td>Power Supply Unit</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "psu_title")} value={build.inv_price.psu_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "psu_price", true)} value={build.inv_price.psu_price} /></td>}
                                                    <td>Cooler</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "cooler_title")} value={build.inv_price.cooler_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "cooler_price", true)} value={build.inv_price.cooler_price} /></td>}
                                                </tr>
                                                <tr>
                                                    <td>Case</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "tower_title")} value={build.inv_price.tower_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "tower_price", true)} value={build.inv_price.tower_price} /></td>}
                                                    <td>Fans</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "fans_title")} value={build.inv_price.fans_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "fans_price", true)} value={build.inv_price.fans_price} /></td>}
                                                </tr>
                                                <tr>
                                                    <td>WiFi</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "wifi_title")} value={build.inv_price.wifi_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "wifi_price", true)} value={build.inv_price.wifi_price} /></td>}
                                                    <td>Operating System</td>
                                                    <td><textarea className="title" onChange={(e) => updatebuild(e, "inv_price", "os_title")} value={build.inv_price.os_title} /></td>
                                                    {showprice && <td><input onChange={(e) => updatebuild(e, "inv_price", "os_price", true)} value={build.inv_price.os_price} /></td>}
                                                </tr>
                                                {showprice &&
                                                    <tr>
                                                        <td colSpan="2">Shipping Charges</td>
                                                        <td><input onChange={(e) => updatebuild(e, "inv_price", "shp_fees", true)} value={build.inv_price.shp_fees} /></td>
                                                        <td colSpan="2">Build Fees</td>
                                                        <td><input onChange={(e) => updatebuild(e, "inv_price", "build_fees", true)} value={build.inv_price.build_fees} /></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <table className='border even_odd' cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                {showprice ? (
                                                    <>
                                                        <tr>
                                                            <td>2% margin</td>
                                                            <td>{Math.ceil(build.subtotal * 1.02)}</td>
                                                            <td>Sub Total</td>
                                                            <td>{build.subtotal}</td>
                                                            <td>Taxable Amount</td>
                                                            <td>{build.pricebgst}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5% margin</td>
                                                            <td>{Math.ceil(build.subtotal * 1.05)}</td>
                                                            <td>Kuro Margin(%)</td>
                                                            <td>{((build.price - build.subtotal) * 100 / build.subtotal).toFixed(2)}</td>
                                                            <td>Total Tax</td>
                                                            <td>{build.totaltax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>10% margin</td>
                                                            <td>{Math.ceil(build.subtotal * 1.1)}</td>
                                                            <td>Kuro Margin (Pre-GST)</td>
                                                            <td>{((build.price - build.subtotal) / 1.18).toFixed(2)}</td>
                                                            <td>Kuro Total</td>
                                                            <td><input onChange={(e) => updatebuild(e, "price", "", true)} value={build.price} /></td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td>Taxable Amount</td>
                                                            <td>{build.pricebgst}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Total Tax</td>
                                                            <td>{build.totaltax}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kuro Total</td>
                                                            <td><input onChange={(e) => updatebuild(e, "price", "", true)} value={build.price} /></td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        <p>&nbsp;</p>
                                    </div>
                                </div>
                            )}
                            <div className="products">
                                <h3>Peripherals</h3>
                                <div className='prod-list'>
                                    <table className="border even_odd" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td>Collection</td>
                                                <td>Type</td>
                                                <td>Category</td>
                                                <td>Brand</td>
                                                <td>Model</td>
                                                <td>Title</td>
                                                <td>Price</td>
                                                <td>Quantity</td>
                                                <td>Total Price</td>
                                                <td>
                                                    <span className="prod-span" onClick={() => addprod()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                </td>
                                            </tr>
                                            {estimateInfo.products.map((prod, j) =>
                                                <tr key={j}>
                                                    <td>
                                                        <select value={prod.collection} onChange={(e) => updateitem(j, "collection", e)}>
                                                            <option value="">Select Collection</option>
                                                            <option value="components">Components</option>
                                                            <option value="monitors">Monitors</option>
                                                            <option value="accessories">Accessories</option>
                                                            <option value="networking">Networking</option>
                                                            <option value="external">External Devices</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={prod.type} onChange={(e) => updateitem(j, "type", e)}>
                                                            <option value="">Select Type</option>
                                                            {prod.collection === "components" &&
                                                                <>
                                                                    <option value="cpu">Processor</option>
                                                                    <option value="mob">Motherboard</option>
                                                                    <option value="ram">RAM</option>
                                                                    <option value="gpu">Graphics Card</option>
                                                                    <option value="psu">Power Supply</option>
                                                                    <option value="ssd">SSD</option>
                                                                    <option value="hdd">HDD</option>
                                                                    <option value="cooler">CPU Cooler</option>
                                                                    <option value="tower">Case</option>
                                                                    <option value="fan">Fan</option>
                                                                </>
                                                            }
                                                            {prod.collection === "monitors" && <option value="monitor">Monitor</option>}
                                                            {prod.collection === "accessories" &&
                                                                <>
                                                                    <option value="keyboard">Keyboard</option>
                                                                    <option value="keyboard-accessories">Keyboard Accessories</option>
                                                                    <option value="mouse">Mouse</option>
                                                                    <option value="mouse-accessories">Mouse Accessories</option>
                                                                    <option value="kbdmouse">Keyboard Mouse Combo</option>
                                                                    <option value="streaming-gear">Streaming Gear</option>
                                                                </>
                                                            }
                                                            {prod.collection === "networking" && <option value="wifi-card">WiFi Card</option>}
                                                            {prod.collection === "external" &&
                                                                <>
                                                                    <option value="hdd">HDD</option>
                                                                    <option value="expansion-card">Expansion Card</option>
                                                                </>
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={prod.category} onChange={(e) => updateitem(j, "category", e)}>
                                                            <option value="">Select Category</option>
                                                            {prod.type === "streaming-gear" &&
                                                                <>
                                                                    <option value="capture-card">Capture Card</option>
                                                                    <option value="gaming-chair">Gaming Chair</option>
                                                                    <option value="streaming-mic">Streaming Mic</option>
                                                                    <option value="webcam">Webcam</option>
                                                                    <option value="gaming-headset">Gaming Headset</option>
                                                                </>
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select value={prod.maker} onChange={(e) => updateitem(j, "maker", e)}>
                                                            <option value="">Select Brand</option>
                                                            {prod.collection === "components" && compMakers !== null && compMakers.map((maker, j) =>
                                                                compData.filter(item => item.type === prod.type && item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                            )}
                                                            {prod.collection === "monitors" && monitorMakers !== null && monitorMakers.map((maker, j) =>
                                                                monitorData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                            )}
                                                            {prod.collection === "accessories" && accessMakers !== null && accessMakers.map((maker, j) =>
                                                                accessData.filter(item => (item.type === prod.type && item.type === "streaming-gear" && item.maker === maker && item.category === prod.category) || (item.type === prod.type && item.type !== "streaming-gear" && item.maker === maker)).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                            )}
                                                            {prod.collection === "networking" && networkMakers !== null && networkMakers.map((maker, j) =>
                                                                networkData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                            )}
                                                            {prod.collection === "external" && extMakers !== null && extMakers.map((maker, j) =>
                                                                extDeviceData.filter(item => item.maker === maker).length > 0 && <option key={j} value={maker}>{maker}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <Select classNamePrefix="kuro-search-select" options={prodOptions(prod)} value={{ value: prod.productid, label: prod.title }} onChange={(e) => updateprod(j, e, prod.collection)} placeholder="Select Model" className="react-select-container" ref={selectedProd} />
                                                    </td>
                                                    <td>
                                                        <textarea value={prod.title} type="textarea" className="large" onChange={(e) => updateitem(j, "title", e)}></textarea>
                                                    </td>
                                                    <td>
                                                        <input type="number" className="small" onWheel={disableScroll} value={prod.price} onChange={(e) => updateitem(j, "price", e, true)} />
                                                    </td>
                                                    <td>
                                                        <input type="number" className="small" min="1" max="100" onWheel={disableScroll} value={prod.quantity} onChange={(e) => updateitem(j, "quantity", e, true)} />
                                                    </td>
                                                    <td>
                                                        {prod.totalprice}
                                                    </td>
                                                    <td>
                                                        <span className="prod-span" onClick={() => removeprod(j)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                        <span className="prod-span" onClick={() => addprod()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <table className='border even_odd' cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Taxable Amount</td>
                                            <td>{estimateInfo.totalpricebgst}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Tax</td>
                                            <td>{estimateInfo.gst}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Price</td>
                                            <td>{estimateInfo.total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul className="btns">
                                    <li>
                                        <button onClick={() => updateviewFlag("default")}>Cancel</button>
                                    </li>
                                    <li>
                                        <button onClick={() => updateEstimate("update")}>Update Estimate</button>
                                    </li>
                                    <li>
                                        <button onClick={() => updateEstimate("new")}>Create New Version</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.products,
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps)(NPSEstimate)