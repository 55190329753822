import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import axios from 'axios'
import { connect } from 'react-redux'
import { getVendors, getInwardInvoices } from '../actions/admin'
import { CurrencyFormat } from '../components/common/CurrencyFormat'
import KuroLink from '../components/common/KuroLink'
import ImgArrow from '../assets/img/arrow.png'
import '../styles/table.css'
import '../styles/tabs.css'
import note from '../assets/img/button.png'
import moment from 'moment-timezone'

moment.tz.setDefault('Asia/Kolkata')

const InwardInvoices = ({ match, admin: { vendors, inwardinvoices, accesslevels }, getVendors, getInwardInvoices, user: { token, userDetails } }) => {
    const history = useHistory()
    const { params: { entity } } = match
    const [tabIndex, setTabIndex] = useState(0)
    const [fltInvoices, setfltInvoices] = useState(null)
    const [showAll, setShowAll] = useState(false)
    const [expandedRow, setExpandedRow] = useState("")
    const access = accesslevels.find(item => item.inward_invoices !== "NA")

    useEffect(() => {
        getVendors()
        getInwardInvoices()
    }, [])

    useEffect(() => {
        if (accesslevels.length > 0 && !access) {
            history.push('/unauthorized')
            return
        }
    }, [accesslevels])

    useEffect(() => {
        if (inwardinvoices) {
            filterInvoices()
        }
    }, [inwardinvoices])

    const filterInvoices = () => {
        let tempinvoices = [...inwardinvoices[0].invoices]
        let startmonth = new Date().getMonth()
        let endmonth = new Date().getMonth() + 1
        let startYear = new Date().getFullYear()
        let endYear = new Date().getFullYear()
        if (new Date().getMonth() === 0) {
            startmonth = 12
            startYear = new Date().getFullYear() - 1
        }
        tempinvoices = tempinvoices.filter(inv => ((new Date(inv.invoice_date).getMonth() + 1 === startmonth && new Date(inv.invoice_date).getFullYear() === startYear) || (new Date(inv.invoice_date).getMonth() + 1 === endmonth && new Date(inv.invoice_date).getFullYear() === endYear)))
        setfltInvoices(tempinvoices)
    }

    const showHandler = () => {
        if (showAll) {
            filterInvoices()
        } else {
            setfltInvoices(inwardinvoices[0].invoices)
        }
        setShowAll(!showAll)
    }

    const submitHandler = (invoiceid, key) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        let invoice = [...inwardinvoices[0].invoices].filter(inv => inv.invoiceid === invoiceid)[0]

        if (key === "active") {
            invoice["active"] = true
            invoice["delete_flag"] = false
        } else if (key === "itc_received") {
            invoice["itc_received"] = "Yes"
        } else if (key === "pay_status") {
            invoice["pay_status"] = "Paid"
        } else if (key === "settled") {
            invoice["settled"] = "Yes"
        } else if (key === "verified") {
            invoice["verified"] = true
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/inwardinvoices?update=update&invoiceid=' + invoiceid, invoice, config)
            .then((res) => {
                getInwardInvoices()
            }).catch((err) => {
                if (err.response.status === 401) {
                    history.push("/unauthorized")
                }
            })
    }

    const createPV = (invoice) => {
        history.push({
            pathname: '/create-pv',
            state: {
                invoice: invoice
            },
        })
    }

    const expandtr = (invoiceid) => {
        setExpandedRow(invoiceid === expandedRow ? "" : invoiceid)
    }


    return (
        <div className="txt-m txt-light-grey">
            {userDetails !== null &&
                <>
                    <h2 className="txt-light-grey">Purchase Invoices</h2>
                    <div className='notes'>
                        <div className="instructions">
                            <p>Instructions</p>
                            <div className='instr'>
                                <img src={note} className='note_img' />
                                <span className="list">
                                    Click on the "Add Invoice" Button to add new invoice details.<br/>
                                    All the new invoices yet to be verified will appear under "Pending Authorization".<br/>
                                    Click on the invoice number to see complete invoice details.<br />
                                    Click on the "Create PV" button to create a Payment Voucher for the invoice before making the payment.<br/>
                                    Click on the "Show More" or "Show All" buttons to access the old invoices.<br/>
                                    Click "Mark as Settled" if the invoice has been fully settled.<br />
                                    Click "Mark as Paid" if the invoice has been fully Paid.<br />
                                </span>
                            </div>
                        </div>
                        <div className="note">
                            <p className='txt-right'>Note:lorem text</p><br />
                        </div>
                    </div>
                    {(accesslevels && accesslevels.find(item => item.inward_invoices === "edit" || item.inward_invoices === "write")) ? (
                        <ul className='btns'>
                            <li>
                                <KuroLink to="/create-inward-invoice"><button>Add Invoice</button></KuroLink>
                            </li>
                        </ul>
                    ) : null}
                    <div className="tabs">
                        <button onClick={() => setTabIndex(0)} className={"tab txt-grey" + (tabIndex === 0 ? " active" : "")}>Pending Authorization</button>
                        {(userDetails.access === "Super" || accesslevels.find(item => item.inward_invoices ===  "edit" ))&& <button onClick={() => setTabIndex(1)} className={"tab txt-grey" + (tabIndex === 1 ? " active" : "")}>Pending Payment</button>}
                        {(accesslevels && accesslevels.find(item => item.inward_invoices === "edit")) && <button onClick={() => setTabIndex(2)} className={"tab txt-grey" + (tabIndex === 2 ? " active" : "")}>ITC Pending</button>}
                        {(userDetails.access === "Super") && <button onClick={() => setTabIndex(3)} className={"tab txt-grey" + (tabIndex === 3 ? " active" : "")}>Settlement Pending</button>}
                        <button onClick={() => setTabIndex(4)} className={"tab txt-grey" + (tabIndex === 4 ? " active" : "")}>All Invoices</button>
                    </div>
                    <div className="tab-panels">
                        <div className={"tab-panel" + (tabIndex === 0 ? " active" : "")}>
                            {inwardinvoices && inwardinvoices[0].invoices.filter(invoice => invoice.active === false).length > 0 ? (
                                <table className="border even_odd" width={"100%"} cellSpacing="0" cellPadding="0">
                                    <thead>
                                        <tr>
                                            {userDetails.access === 'Super' ? <th className='show_tab'>Invoice Id</th> : null}
                                            <th>Vendor Name</th>
                                            <th className='show_tab'>Invoice No.</th>
                                            <th className='show_tab'>Invoice Date</th>
                                            <th>Invoice Total</th>
                                            <th className='show_tab'>Invoice Type</th>
                                            <th className='show_tab'>PO Number</th>
                                            <th className='show_tab'>ITC Received</th>
                                            <th className='show_tab'>IT TCS</th>
                                            <th className='show_tab'>CGST/SGST</th>
                                            <th className='show_tab'>IGST</th>
                                            <th className='show_tab'>Due Date</th>
                                            <th className='show_tab'>Description</th>
                                            <th className='show_tab'>Verified</th>
                                            <th className='show_tab' >Payment Status</th>
                                            <th className='show_tab'>Settled</th>
                                            {userDetails && userDetails.access === "Super" ? (<th>Approval</th>) : null}
                                            <th className='hide_tab'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inwardinvoices[0].invoices.filter((invoice) => invoice.active === false).map((invoice, i) => (
                                            <>
                                                <tr key={i}>
                                                    {userDetails.access === 'Super' ? <td className='show_tab'>{invoice.invoiceid}</td> : null}
                                                    <td>
                                                        {vendors && vendors.length > 0
                                                            ? vendors.filter((item) => item.vendor_code === invoice.vendor)[0]?.name || 'Vendor Not Found'
                                                            : 'No Vendors Available'}
                                                        <span className='show_tab'>{invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']'}</span>
                                                    </td>
                                                    <td className='show_tab'><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                    <td className='show_tab'>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                    <td>{CurrencyFormat(invoice.totalprice)}</td>
                                                    <td className='show_tab'>{invoice.invoice_type}</td>
                                                    <td className='show_tab'>{invoice.po_no}</td>
                                                    <td className='show_tab'>{invoice.itc_received === "Yes" ? ("Received") : invoice.itc_received === "No" ? ("Not Received") : ("Not Applicable")}</td>
                                                    <td className='show_tab'>{invoice.ittcs}</td>
                                                    <td className='show_tab'>{invoice.cgst}</td>
                                                    <td className='show_tab'>{invoice.igst}</td>
                                                    <td className='show_tab'>{invoice.due_date === "" ? "" : moment(invoice.due_date).format("DD-MM-YYYY")}</td>
                                                    <td className='show_tab'>{invoice.desc}</td>
                                                    <td className='show_tab'>
                                                    {invoice.verified === true ? ("Verified") : 
                                                        accesslevels.find(acc => acc.inward_invoices === "edit" && acc.entity === invoice.entity) ? (
                                                        <button className="button_mob" onClick={() => submitHandler(invoice.invoiceid, "verified")}>
                                                            <span>Mark as Verified</span>
                                                        </button>
                                                        ) : ("Not Verified")}
                                                    </td>
                                                    <td className='show_tab'>{invoice.pay_status !== "Payment Pending" ? invoice.pay_status : (accesslevels && accesslevels.find(item => item.inward_invoices === "edit") ? <button className="button_mob" onClick={(e) => submitHandler(invoice.invoiceid, "pay_status")}>Mark As Paid</button> : invoice.pay_status)}</td>
                                                    <td className='show_tab'>{invoice.settled === "Yes" ? "Settled" : accesslevels && accesslevels.find(item => item.inward_invoices === "edit") ? <button className="button_mob" onClick={() => submitHandler(invoice.invoiceid, "settled")}>Mark As Settled</button> : "Not Settled"}</td>
                                                    {userDetails && userDetails.access === "Super" && <td>
                                                        <button className="button_mob" onClick={() => submitHandler(invoice.invoiceid, "active")}>
                                                            <span>Approve</span>
                                                        </button>
                                                    </td>}
                                                    <td className='bg_colors_mob hide_tab'><img onClick={() => { expandtr(invoice.invoiceid) }} className={expandedRow === invoice.invoiceid ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} /></td>
                                                </tr>
                                                {expandedRow === invoice.invoiceid && (
                                                    <>
                                                        {userDetails.access === 'Super' ? (
                                                            <tr className='bg_color_mob'>
                                                                <th className='bg_color_mob'>Invoice Id</th>
                                                                <td colSpan="3">{invoice.invoiceid}</td>
                                                            </tr>
                                                        ) : null}
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Invoice Date</th>
                                                            <td colSpan="3">{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")} </td>
                                                        </tr>
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Invoice No.</th>
                                                            <td colSpan="3"><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                        </tr>
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Due Date</th>
                                                            <td colSpan="3">{invoice.due_date === "" ? "" : moment(invoice.due_date).format("DD-MM-YYYY")}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                userDetails.access === "Super" ? <p>No Invoices to Approve</p> : <p>No Invocies in Pending Authorization</p>
                            )}
                        </div>
                        {(userDetails.access === "Super" || accesslevels.find(item => item.inward_invoices === "edit")) &&
                            <div className={"tab-panel" + (tabIndex === 1 ? " active" : "")}>
                                {inwardinvoices &&
                                    <>
                                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr>
                                                    <th>Total Pending Payments</th>
                                                    <td>{inwardinvoices[0]["pendings"]["payments"] && CurrencyFormat(inwardinvoices[0]["pendings"]["payments"].toFixed(2))}</td>
                                                </tr>
                                            </thead>
                                        </table>
                                        {userDetails.access === "Super" && <ul className='btns'>
                                            <li><KuroLink to="/bulk-payments"><button className="button_mob">Bulk Payments</button></KuroLink></li>
                                        </ul>}
                                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <th className='show_tab'>Invoice Id</th>
                                                    <th>Vendor Name</th>
                                                    <th className='show_tab'>Invoice No.</th>
                                                    <th className='show_tab'>Invoice Date</th>
                                                    <th>Invoice Total</th>
                                                    <th className='show_tab'>PO Number</th>
                                                    <th className='show_tab'>Due Date</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                {inwardinvoices[0].pending_payments && inwardinvoices[0].pending_payments.map((invoice, i) => (
                                                    <>
                                                        <tr key={i}>
                                                            <td className='show_tab'>{invoice.invoiceid}</td>
                                                            <td>
                                                                {vendors && vendors.length > 0
                                                                    ? vendors.filter((item) => item.vendor_code === invoice.vendor)[0]?.name || 'Vendor Not Found'
                                                                    : 'No Vendors Available'}
                                                                <span className='show_tab'>{invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']'}</span>
                                                            </td>
                                                            <td className='show_tab'><KuroLink to={'inward-invoices/' + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                            <td className='show_tab'>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format('DD-MM-YYYY')}</td>
                                                            <td>{CurrencyFormat(invoice.totalprice)}</td>
                                                            <td className='show_tab'>{invoice.po_no}</td>
                                                            <td className='show_tab'>{invoice.due_date === "" ? "" : moment(invoice.due_date).format("DD-MM-YYYY")} </td>
                                                            <td><button className="button_mob" onClick={(e) => submitHandler(invoice.invoiceid, "pay_status")}>Mark as Paid</button></td>
                                                            <td className='show_tab'><button className='button_mob' onClick={(e) => createPV(invoice)}>Create PV</button></td>
                                                            <td className='hide_tab'><img onClick={() => expandtr(invoice.invoiceid)} className={expandedRow === invoice.invoiceid ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} /></td>
                                                        </tr>
                                                        {expandedRow === invoice.invoiceid && (
                                                            <>
                                                                <tr className='bg_color_mob'>
                                                                    <th>Invoice Id</th>
                                                                    <td colSpan="3">{invoice.invoiceid}</td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th>Due Date</th>
                                                                    <td colSpan="3">{invoice.due_date === "" ? "" : moment(invoice.due_date).format("DD-MM-YYYY")}</td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th>Invoice No.</th>
                                                                    <td colSpan="3"><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th>Invoice Date</th>
                                                                    <td colSpan="3">{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                }
                            </div>
                        }
                        <div className={"tab-panel" + (tabIndex === 2 ? " active" : "")}>
                            {inwardinvoices && (
                                <>
                                    {userDetails.access === "Super" && <table className="border even_odd" cellSpacing="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <th>Total ITC Pending</th>
                                                <td>{inwardinvoices[0]["pendings"]["itc"] && CurrencyFormat(inwardinvoices[0]["pendings"]["itc"].toFixed(2))}</td>
                                            </tr>
                                        </tbody>
                                    </table>}
                                    {inwardinvoices[0].invoices.filter((invoice) => invoice.itc_received === "No").length > 0 && (
                                        <table className="border even_odd" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    {userDetails.access === 'Super' ? <th className='show_tab'>Invoice Id</th> : null}
                                                    <th>Vendor Name</th>
                                                    <th className='show_tab'>Invoice No.</th>
                                                    <th className='show_tab'>Invoice Date</th>
                                                    <th>Invoice Total</th>
                                                    <th className='show_tab'>PO Number</th>
                                                    <th className='show_tab'>CGST/SGST</th>
                                                    <th className='show_tab'>IGST</th>
                                                    <th></th>
                                                    <th className='hide_tab'></th>
                                                </tr>
                                                {inwardinvoices[0].invoices.filter(invoice => invoice.itc_received === "No").map((invoice, i) => (
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            {userDetails.access === 'Super' ? <td className='show_tab'>{invoice.invoiceid}</td> : null}
                                                            <td>
                                                                {vendors && vendors.length > 0
                                                                    ? vendors.filter((item) => item.vendor_code === invoice.vendor)[0]?.name || 'Vendor Not Found'
                                                                    : 'No Vendors Available'}
                                                                <span className='show_tab'>{invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']'}</span>
                                                            </td>
                                                            <td className='show_tab'><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                            <td className='show_tab'>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                            <td>{CurrencyFormat(invoice.totalprice)}</td>
                                                            <td className='show_tab'>{invoice.po_no}</td>
                                                            <td className='show_tab'>{invoice.cgst}</td>
                                                            <td className='show_tab'>{invoice.igst}</td>
                                                            <td><button className="button_mob" onClick={() => submitHandler(invoice.invoiceid, "itc_received")}>ITC Received</button></td>
                                                            <td className='bg_colors_mob hide_tab'><img onClick={() => expandtr(invoice.invoiceid)} className={expandedRow === invoice.invoiceid ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} /></td>
                                                        </tr>
                                                        {expandedRow === invoice.invoiceid && (
                                                            <>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>Invoice Id</th>
                                                                    <td colSpan="3"><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoiceid}</KuroLink></td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>Invoice No.</th>
                                                                    <td colSpan="3">{invoice.invoice_no}</td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>CGST</th>
                                                                    <td colSpan="3">{invoice.cgst}</td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>IGST</th>
                                                                    <td colSpan="3">{invoice.igst}</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </>
                            )}
                        </div>
                        {userDetails.access === "Super" &&
                            <div className={"tab-panel" + (tabIndex === 3 ? " active" : "")}>
                                {inwardinvoices && inwardinvoices[0].invoices.filter((invoice) => invoice.settled === "No").length > 0 ? (
                                    <>
                                        {userDetails.access === "Super" &&
                                            <table className="border even_odd" cellSpacing="0" cellPadding="0" >
                                                <tbody>
                                                    <tr>
                                                        <th>Total Settlements Pending</th>
                                                        <td>{inwardinvoices[0]["pendings"]["settlements"] && CurrencyFormat(inwardinvoices[0]["pendings"]["settlements"].toFixed(2))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        }
                                        <table className="border even_odd" cellSpacing="0" cellPadding="0" >
                                            <tbody>
                                                <tr>
                                                    {userDetails.access === 'Super' ? <th className='show_tab'>Invoice Id</th> : null}
                                                    <th>Vendor Name</th>
                                                    <th className='show_tab'>Invoice No.</th>
                                                    <th className='show_tab'>Invoice Date</th>
                                                    <th>Invoice Total</th>
                                                    <th className='show_tab'>PO Number</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                {inwardinvoices[0].invoices.filter(invoice => invoice.settled === "No").map((invoice, i) => (
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td className='show_tab'>{invoice.invoiceid}</td>
                                                            <td>
                                                                {vendors && vendors.length > 0
                                                                    ? vendors.filter((item) => item.vendor_code === invoice.vendor)[0]?.name || 'Vendor Not Found'
                                                                    : 'No Vendors Available'}
                                                                <span className='show_tab'>{invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']'}</span>
                                                            </td>
                                                            <td className='show_tab'><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                            <td className='show_tab'>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                            <td>{CurrencyFormat(invoice.totalprice)}</td>
                                                            <td className='show_tab'>{invoice.po_no}</td>
                                                            <td><button className="button_mob" onClick={() => submitHandler(invoice.invoiceid, "settled")}>Mark as Settled</button></td>
                                                            <td className='show_tab'><button className='button_mob' onClick={(e) => createPV(invoice)}>Create PV</button></td>
                                                            <td className='bg_colors_mob hide_tab'><img onClick={() => expandtr(invoice.invoiceid)} className={expandedRow === invoice.invoiceid ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} /></td>
                                                        </tr>
                                                        {expandedRow === invoice.invoiceid && (
                                                            <>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>Invoice Id</th>
                                                                    <td colSpan="3">{invoice.invoiceid}</td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>Invoice No.</th>
                                                                    <td colSpan="3"><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                                </tr>
                                                                <tr className='bg_color_mob'>
                                                                    <th className='bg_color_mob'>Invoice Date</th>
                                                                    <td colSpan="3">{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <p>No Invoices to be Settled</p>
                                )}
                            </div>
                        }
                        <div className={"tab-panel" + (tabIndex === 4 ? " active" : "")}>
                            {fltInvoices !== null && (
                                <table className="border even_odd inwardAll_mob" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                        <tr>
                                            {userDetails.access === 'Super' ? <th className='show_tab'>Invoice Id</th> : null}
                                            <th>Vendor Name</th>
                                            <th className='show_tab'>Invoice No.</th>
                                            <th className='show_tab'>Invoice Date</th>
                                            <th>Invoice Total</th>
                                            <th className='show_tab'>Invoice Type</th>
                                            <th className='show_tab'>PO Number</th>
                                            <th className='show_tab'>ITC Received</th>
                                            <th className='show_tab'>IT TCS</th>
                                            <th className='show_tab'>CGST/SGST</th>
                                            <th className='show_tab'>IGST</th>
                                            <th className='show_tab'>Due Date</th>
                                            <th className='show_tab'>Payment Status</th>
                                            <th className='show_tab'>Settled</th>
                                            <th className='show_tab'>Description</th>
                                            <th className='hide_tab'></th>
                                        </tr>
                                        {fltInvoices.map((invoice, i) =>
                                            <React.Fragment key={i}>
                                                <tr>
                                                    {userDetails.access === 'Super' ? <td className='show_tab'>{invoice.invoiceid}</td> : null}
                                                    <td>
                                                        {vendors !== null && vendors.filter((item) => item.vendor_code === invoice.vendor)[0].name}
                                                        <span className='show_tab'>{invoice.gstin === '' ? '' : ' [' + invoice.gstin + ']'}</span>
                                                    </td>
                                                    <td className='show_tab'><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                    <td className='show_tab'>{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")} </td>
                                                    <td>{invoice.totalprice}</td>
                                                    <td className='show_tab'>{invoice.invoice_type}</td>
                                                    <td className='show_tab'>{invoice.po_no}</td>
                                                    {invoice.itc_received === "Yes" ? <td className='show_tab'>Received</td> : invoice.itc_received === "No" ? <td className='show_tab'>Not Received</td> : <td className='show_tab'>Not Applicable</td>}
                                                    <td className='show_tab'>{invoice.ittcs}</td>
                                                    <td className='show_tab'>{invoice.cgst}</td>
                                                    <td className='show_tab'>{invoice.igst}</td>
                                                    <td className='show_tab'>{invoice.due_date === "" ? "" : moment(invoice.due_date).format("DD-MM-YYYY")}</td>
                                                    <td className='show_tab'>{invoice.pay_status}</td>
                                                    {invoice.settled === "Yes" ? <td className='show_tab'>Settled</td> : <td className='show_tab'>Not Settled</td>}
                                                    <td className='show_tab'>{invoice.desc}</td>
                                                    <td className='bg_colors_mob hide_tab'><img onClick={() => expandtr(invoice.invoiceid)} className={expandedRow === invoice.invoiceid ? 'img_mob' : 'img_mob down'} title="Invoice" alt="Invoice" src={ImgArrow} /></td>
                                                </tr>
                                                {expandedRow === invoice.invoiceid && (
                                                    <>
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Invoice Id</th>
                                                            <td colSpan="2">{invoice.invoiceid}</td>
                                                        </tr>
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Invoice No.</th>
                                                            <td colSpan="2"><KuroLink to={"inward-invoices/" + invoice.invoiceid}>{invoice.invoice_no}</KuroLink></td>
                                                        </tr>
                                                        <tr className='bg_color_mob'>
                                                            <th className='bg_color_mob'>Invoice Date</th>
                                                            <td colSpan="2">{invoice.invoice_date === "" ? "" : moment(invoice.invoice_date).format("DD-MM-YYYY")}</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </tbody>
                                </table>
                            )}
                            <button onClick={showHandler}>
                                {showAll ? "Show Recent" : "Show All"}
                            </button>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                </>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    admin: state.admin,
    user: state.user
})

export default connect(mapStateToProps, { getVendors, getInwardInvoices })(InwardInvoices)